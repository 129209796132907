import DataService from "../../Services/DataService";
import loadAbsences from "./loadAbsences";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {reduxSliceTypeEnum} from "../reduxSliceTypeEnum/reduxSliceTypeEnum";

const cancelAbsence = createAsyncThunk(reduxSliceTypeEnum.CANCEL_ABSENCE, async ( {absenceId, reason} : {absenceId : number, reason ? : string} ,{dispatch} ) => {
    await DataService.cancelAbsence(absenceId, reason);

    dispatch(loadAbsences());
})

export default cancelAbsence;
