import SortOrder from "../../../Types/SortOrder";

export type myProposalTabState = {
    myProposalTabHistorySortOrder : SortOrder,
    myProposalTabHistorySortBy : string,
    myProposalTabSortOrder : SortOrder,
    myProposalTabSortBy : string
}

const myProposalTabInitialState : myProposalTabState = {
    myProposalTabHistorySortOrder : 'desc',
    myProposalTabHistorySortBy : 'absenceFinishDate',
    myProposalTabSortOrder : 'asc',
    myProposalTabSortBy : 'absenceStartDate'
}

export default myProposalTabInitialState