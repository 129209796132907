import React, { useEffect} from "react";
import {CurrentModalNameEnum} from "../../../../Enums/CurrentModalNameEnum";
import {useSelector} from "react-redux";
import RootState from "../../../../Types/states/RootState";
import UseModal from "../../../../Services/CustomHooks/useModal";
import AbsenceTypeEnum from "../../../../Enums/AbsenceTypeEnum";
import {useFormik} from "formik";
import {proposalYupValidation} from "../../../../Shared/Yup/ProposalYupValidation";
import {withFormikDevtools} from "formik-devtools-extension";
import Proposal from "../../../../Types/data/Proposal";
import MobileEditProposalBody from "./MobileEditProposalBody/MobileEditProposalBody";
import { CreateProposalFormik } from "src/Types/states/modal/CreateProposalFormik";
import styles from "../../Modals.module.scss";
import {Modal} from "@devapo/devapo-ui";
import {api} from "../../../../ReduxStore/api/api";
import {RtkQueryHelper} from "../../../../helpers/rtk-query.helper";

export const RenderMobileEditModal = () => {

    const role = useSelector((state:RootState) => state.user.role);
    const {currentProposal,currentOpenModal} = useSelector((state : RootState) => state.modalPopup)
    const {closeAll} = UseModal()
    const loggedInUserID = useSelector((state:RootState) => state.user.loggedInUserID);
    const {employee,requester,absenceType,holidayType,absenceStartDate,absenceFinishDate,description,replacement} =currentProposal
    const [editProposal, result] = api.useEditProposalMutation()

    const formik = useFormik({
        initialValues: {
            employee,
            requester,
            absenceType,
            holidayType,
            absenceStartDate,
            absenceFinishDate,
            description,
            replacement,
            role
        } as CreateProposalFormik & {role : string},
        validationSchema : proposalYupValidation,
        enableReinitialize : true,
        validateOnChange : true,
        onSubmit: values => {
            const {role,...updatedProposal} = values
            if((formik.isValid && formik.dirty)){
                tryEditProposal({
                    ...currentProposal,
                    ...updatedProposal
                })
            }
        },
    });

    useEffect(() => {
        process.env.NODE_ENV !== 'production' && withFormikDevtools(formik);
    },[])

    useEffect(() => {
        if(RtkQueryHelper.checkResultIsFulfilled(result)){
            formik.resetForm()
            closeAll();
        }
    },[result])

    const tryEditProposal = (updatedProposal : Proposal): void => {
        if ((updatedProposal.absenceType === AbsenceTypeEnum.REMOTE)) {
            editProposal({
                ...updatedProposal,
                replacement: undefined
            });
        } else if (loggedInUserID !== updatedProposal.replacement) {
            editProposal(updatedProposal)
        }
    }

    return (
        <Modal
            isOpen={currentOpenModal === CurrentModalNameEnum.MOBILE_EDIT_PROPOSAL}
            onClose={() => closeAll()}
            modalClass={styles.modal}
            backdropClass={styles.backdrop}
        >
            <MobileEditProposalBody  formik={formik} result={result}  />
        </Modal>
    )
}
export default RenderMobileEditModal;
