import AbsenceTypeEnum from "../../../../Enums/AbsenceTypeEnum";
import HolidayTypeEnum from "../../../../Enums/HolidayTypeEnum";
import {ModalHeadersEnum} from "../../../../Enums/ModalHeadersEnum";
import {Dispatch, SetStateAction} from "react";
import Absence from "../../../../Types/data/Absence";
import Proposal from "../../../../Types/data/Proposal";

export namespace DesktopModalHeaderHelper {

    export const getModalProposalHeaderEnum = (setHeader : Dispatch<SetStateAction<ModalHeadersEnum | undefined>>, currentProposal : Proposal) => {
        if(currentProposal.absenceType === AbsenceTypeEnum.HOLIDAY){
            switch(currentProposal.holidayType){
                case HolidayTypeEnum.LEISURE : setHeader(ModalHeadersEnum.HOLIDAY_LEISURE_PROPOSAL_HEADER) ;break;
                case HolidayTypeEnum.AS_REQUESTED : setHeader(ModalHeadersEnum.HOLIDAY_AS_REQUESTED_PROPOSAL_HEADER) ;break;
                case HolidayTypeEnum.OCCASIONAL : setHeader(ModalHeadersEnum.HOLIDAY_OCCASIONAL_PROPOSAL_HEADER) ;break;
                case HolidayTypeEnum.FREE : setHeader(ModalHeadersEnum.HOLIDAY_FREE_PROPOSAL_HEADER) ;break;
                case HolidayTypeEnum.TRAINING : setHeader(ModalHeadersEnum.HOLIDAY_TRAINING_PROPOSAL_HEADER) ;break;
            }
        } else {
            switch(currentProposal.absenceType){
                case AbsenceTypeEnum.REMOTE:
                    setHeader(ModalHeadersEnum.REMOTE_PROPOSAL_HEADER) ; break;
                case AbsenceTypeEnum.SICK_LEAVE:
                    setHeader(ModalHeadersEnum.SICK_LEAVE_PROPOSAL_HEADER) ; break;
            }
        }
    }

    export const getModalAbsenceHeaderEnum = (setHeader : Dispatch<SetStateAction<ModalHeadersEnum | undefined>>, currentAbsence : Absence) => {
        if(currentAbsence.absenceType === AbsenceTypeEnum.HOLIDAY){
            switch(currentAbsence.holidayType){
                case HolidayTypeEnum.LEISURE : setHeader(ModalHeadersEnum.HOLIDAY_LEISURE_ABSENCE_HEADER) ;break;
                case HolidayTypeEnum.AS_REQUESTED : setHeader(ModalHeadersEnum.HOLIDAY_AS_REQUESTED_ABSENCE_HEADER) ;break;
                case HolidayTypeEnum.OCCASIONAL : setHeader(ModalHeadersEnum.HOLIDAY_OCCASIONAL_ABSENCE_HEADER) ;break;
                case HolidayTypeEnum.FREE : setHeader(ModalHeadersEnum.HOLIDAY_FREE_ABSENCE_HEADER) ;break;
                case HolidayTypeEnum.TRAINING : setHeader(ModalHeadersEnum.HOLIDAY_TRAINING_ABSENCE_HEADER) ;break;
                default : setHeader(ModalHeadersEnum.HOLIDAY_ABSENCE_HEADER)
            }
        } else {
            switch(currentAbsence.absenceType){
                case AbsenceTypeEnum.REMOTE:
                    setHeader(ModalHeadersEnum.REMOTE_ABSENCE_HEADER) ; break;
                case AbsenceTypeEnum.SICK_LEAVE:
                    setHeader(ModalHeadersEnum.SICK_LEAVE_ABSENCE_HEADER) ; break;
                default: setHeader(ModalHeadersEnum.ABSENCE_HEADER)
            }
        }
    }

    export const getModalDeleteAbsenceHeaderEnum = (setHeader : Dispatch<SetStateAction<ModalHeadersEnum | undefined>>, currentAbsence : Absence) => {
        if(currentAbsence.absenceType === AbsenceTypeEnum.HOLIDAY){
            switch(currentAbsence.holidayType){
                case HolidayTypeEnum.LEISURE : setHeader(ModalHeadersEnum.DELETE_HOLIDAY_LEISURE_ABSENCE_HEADER) ;break;
                case HolidayTypeEnum.AS_REQUESTED : setHeader(ModalHeadersEnum.DELETE_HOLIDAY_AS_REQUESTED_ABSENCE_HEADER) ;break;
                case HolidayTypeEnum.OCCASIONAL : setHeader(ModalHeadersEnum.DELETE_HOLIDAY_OCCASIONAL_ABSENCE_HEADER) ;break;
                case HolidayTypeEnum.FREE : setHeader(ModalHeadersEnum.DELETE_HOLIDAY_FREE_ABSENCE_HEADER) ;break;
                case HolidayTypeEnum.TRAINING : setHeader(ModalHeadersEnum.DELETE_HOLIDAY_TRAINING_ABSENCE_HEADER) ;break;
                default : setHeader(ModalHeadersEnum.DELETE_HOLIDAY_ABSENCE_HEADER)
            }
        } else {
            switch(currentAbsence.absenceType){
                case AbsenceTypeEnum.REMOTE:
                    setHeader(ModalHeadersEnum.DELETE_REMOTE_ABSENCE_HEADER) ; break;
                case AbsenceTypeEnum.SICK_LEAVE:
                    setHeader(ModalHeadersEnum.DELETE_SICK_LEAVE_ABSENCE_HEADER) ; break;
                default: setHeader(ModalHeadersEnum.DELETE_ABSENCE_HEADER)
            }
        }
    }

    export const getModalCancelProposalHeaderEnum = (setHeader : Dispatch<SetStateAction<ModalHeadersEnum | undefined>>, currentProposal : Proposal) => {
        if(currentProposal.absenceType === AbsenceTypeEnum.HOLIDAY){
            switch(currentProposal.holidayType){
                case HolidayTypeEnum.LEISURE : setHeader(ModalHeadersEnum.CANCEL_HOLIDAY_LEISURE_PROPOSAL_HEADER) ;break;
                case HolidayTypeEnum.AS_REQUESTED : setHeader(ModalHeadersEnum.CANCEL_HOLIDAY_AS_REQUESTED_PROPOSAL_HEADER) ;break;
                case HolidayTypeEnum.OCCASIONAL : setHeader(ModalHeadersEnum.CANCEL_HOLIDAY_OCCASIONAL_PROPOSAL_HEADER) ;break;
                case HolidayTypeEnum.FREE : setHeader(ModalHeadersEnum.CANCEL_HOLIDAY_FREE_PROPOSAL_HEADER) ;break;
                case HolidayTypeEnum.TRAINING : setHeader(ModalHeadersEnum.CANCEL_HOLIDAY_TRAINING_PROPOSAL_HEADER) ;break;
                default : setHeader(ModalHeadersEnum.CANCEL_HOLIDAY_PROPOSAL_HEADER)
            }
        } else {
            switch(currentProposal.absenceType){
                case AbsenceTypeEnum.REMOTE:
                    setHeader(ModalHeadersEnum.CANCEL_REMOTE_PROPOSAL_HEADER) ; break;
                case AbsenceTypeEnum.SICK_LEAVE:
                    setHeader(ModalHeadersEnum.CANCEL_SICK_LEAVE_PROPOSAL_HEADER) ; break;
                default: setHeader(ModalHeadersEnum.CANCEL_PROPOSAL)
            }
        }
    }

    export const getModalDeclineProposalHeaderEnum = (setHeader : Dispatch<SetStateAction<ModalHeadersEnum | undefined>>, currentProposal : Proposal) => {
        if(currentProposal.absenceType === AbsenceTypeEnum.HOLIDAY){
            switch(currentProposal.holidayType){
                case HolidayTypeEnum.LEISURE : setHeader(ModalHeadersEnum.DECLINE_HOLIDAY_LEISURE_PROPOSAL_HEADER) ;break;
                case HolidayTypeEnum.AS_REQUESTED : setHeader(ModalHeadersEnum.DECLINE_HOLIDAY_AS_REQUESTED_PROPOSAL_HEADER) ;break;
                case HolidayTypeEnum.OCCASIONAL : setHeader(ModalHeadersEnum.DECLINE_HOLIDAY_OCCASIONAL_PROPOSAL_HEADER) ;break;
                case HolidayTypeEnum.FREE : setHeader(ModalHeadersEnum.DECLINE_HOLIDAY_FREE_PROPOSAL_HEADER) ;break;
                case HolidayTypeEnum.TRAINING : setHeader(ModalHeadersEnum.DECLINE_HOLIDAY_TRAINING_PROPOSAL_HEADER) ;break;
                default : setHeader(ModalHeadersEnum.DECLINE_HOLIDAY_PROPOSAL_HEADER)
            }
        } else {
            switch(currentProposal.absenceType){
                case AbsenceTypeEnum.REMOTE:
                    setHeader(ModalHeadersEnum.DECLINE_REMOTE_PROPOSAL_HEADER) ; break;
                case AbsenceTypeEnum.SICK_LEAVE:
                    setHeader(ModalHeadersEnum.DECLINE_SICK_LEAVE_PROPOSAL_HEADER) ; break;
                default: setHeader(ModalHeadersEnum.DECLINE_PROPOSAL_HEADER)
            }
        }
    }

    export const getModalAcceptProposalHeaderEnum = (setHeader : Dispatch<SetStateAction<ModalHeadersEnum | undefined>>, currentProposal : Proposal) => {
        if(currentProposal.absenceType === AbsenceTypeEnum.HOLIDAY){
            switch(currentProposal.holidayType){
                case HolidayTypeEnum.LEISURE : setHeader(ModalHeadersEnum.ACCEPT_HOLIDAY_LEISURE_PROPOSAL_HEADER) ;break;
                case HolidayTypeEnum.AS_REQUESTED : setHeader(ModalHeadersEnum.ACCEPT_HOLIDAY_AS_REQUESTED_PROPOSAL_HEADER) ;break;
                case HolidayTypeEnum.OCCASIONAL : setHeader(ModalHeadersEnum.ACCEPT_HOLIDAY_OCCASIONAL_PROPOSAL_HEADER) ;break;
                case HolidayTypeEnum.FREE : setHeader(ModalHeadersEnum.ACCEPT_HOLIDAY_FREE_PROPOSAL_HEADER) ;break;
                case HolidayTypeEnum.TRAINING : setHeader(ModalHeadersEnum.ACCEPT_HOLIDAY_TRAINING_PROPOSAL_HEADER) ;break;
                default : setHeader(ModalHeadersEnum.ACCEPT_HOLIDAY_PROPOSAL_HEADER)
            }
        } else {
            switch(currentProposal.absenceType){
                case AbsenceTypeEnum.REMOTE:
                    setHeader(ModalHeadersEnum.ACCEPT_REMOTE_PROPOSAL_HEADER) ; break;
                case AbsenceTypeEnum.SICK_LEAVE:
                    setHeader(ModalHeadersEnum.ACCEPT_SICK_LEAVE_PROPOSAL_HEADER) ; break;
                default: setHeader(ModalHeadersEnum.ACCEPT_PROPOSAL)
            }
        }
    }
}