import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { KeycloakService } from "../../Layout/Auth/KeycloakService";
import { createApi } from "@reduxjs/toolkit/query/react";
import Employee from "../../Types/data/Employee";
import { Paged } from "../../Types/data/Paged";
import { EmployeeStatusEnum } from "../../Enums/EmployeeStatusEnum";

export const employeeApi = createApi({
  reducerPath: "employeeApi",
  baseQuery : fetchBaseQuery({
    baseUrl : `/api`,
    prepareHeaders : async (headers) => {

      // TODO dodać profile - https://devapo.atlassian.net/jira/software/projects/SR/issues/SR-640
      if( KeycloakService.isTokenExpired() ){
        await KeycloakService.updateToken().then( () => {
          headers.set("Authorization", `Bearer ${KeycloakService.token()}`)
          headers.set("content-type", "application/json")
        })
        return headers;
      } else {
        headers.set("Authorization", `Bearer ${KeycloakService.token()}`)
        headers.set("content-type", "application/json")

        return headers;
      }

    }
  }),
  tagTypes : ['employee'],
  endpoints: (builder) => ({
    getAllActiveEmployees: builder.query<Paged<Employee[]>, void>({
      query: () => {
        return {
          url : "/employees",
          params : {
            size: 2000,
            status : EmployeeStatusEnum.ACTIVE,
          }
        }
      },
      providesTags: ["employee"]
    }),
    getEmployeesByParams: builder.query<Paged<Employee[]>, Record<string, unknown>>({
      query: (params: Record<string,unknown>) => {
        return {
          url : "/employees",
          params : {
            size: 2000,
            status : EmployeeStatusEnum.ACTIVE,
            ...params,
          }
        }
      },
      providesTags: ["employee"]
    }),
    getEmployeeById: builder.query<Employee, number | string>({
      query: (id: number | string) => `/employees/${id}`
    })
  })
})