import Keycloak, {KeycloakError, KeycloakPromise} from "keycloak-js";

export namespace KeycloakService {

    export interface KeycloakIdentity {
        firstName: string,
        lastName: string,
        email: string
    }

    const keycloak = new Keycloak({
        realm : "devapo",
        url : String(process.env.REACT_APP_KEYCLOAK_URI),
        clientId : "smarter-planner",
    });

    export const login = () : KeycloakPromise<boolean, KeycloakError> => {
        return keycloak.init({
            onLoad : "login-required",
            checkLoginIframe: false,
        })
    }

    keycloak.onTokenExpired = () : void => {
        updateToken()
    }

    keycloak.onAuthRefreshError = () : void => {
        loginAgain()
    }

    export const authenticated = () => keycloak.authenticated;
    export const token = () => keycloak.token;
    export const loadUserProfile: () => Promise<KeycloakIdentity> = () => {
        return new Promise((resolve) => {
            resolve({
                firstName: keycloak.tokenParsed?.given_name,
                lastName: keycloak.tokenParsed?.family_name,
                email: email()
            });
        });
    }

    export const email = () => {
        const email = keycloak.tokenParsed?.email;
        return email.replace("@devapo.pl", "@devapo.io");
    }

    export const hasRealmRole = (role: string) => {
        return keycloak.hasRealmRole(role)
    }

    export const loginAgain = () : void => {
        keycloak.clearToken()
        keycloak.logout()
    }

    export const isTokenExpired = () : boolean => {
        return keycloak.isTokenExpired(Number(process.env.REACT_APP_TOKEN_MIN_VALIDITY))
    }

    export const updateToken = () : Keycloak.KeycloakPromise<boolean, boolean> => {
        return keycloak.updateToken(Number(process.env.REACT_APP_TOKEN_MIN_VALIDITY))
    }

    export const checkToken = () : void => {
        if(isTokenExpired()){
            updateToken()
        }
    }

}
