import React from "react";
import MobileReviewCreatedProposalBody from "./MobileReviewCreatedProposalBody/MobileReviewCreatedProposalBody";
import {useSelector} from "react-redux";
import UseModal from "../../../../Services/CustomHooks/useModal";
import RootState from "../../../../Types/states/RootState";
import {CurrentModalNameEnum} from "../../../../Enums/CurrentModalNameEnum";
import styles from "../../Modals.module.scss";
import {Modal} from "@devapo/devapo-ui";

const MobileReviewCreatedProposal = () => {
    const {currentOpenModal}  = useSelector((state : RootState) => state.modalPopup);
    const {closeAll} = UseModal()

    return (
        <>
            <Modal
                isOpen={currentOpenModal === CurrentModalNameEnum.MOBILE_REVIEW_CREATED_PROPOSAL}
                onClose={() => closeAll()}
                modalClass={styles.modal}
                backdropClass={styles.backdrop}
            >
                <MobileReviewCreatedProposalBody/>
            </Modal>
        </>
    )

}
export default MobileReviewCreatedProposal;