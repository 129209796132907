import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import RootState from "../../../../Types/states/RootState";
import UseModal from "../../../../Services/CustomHooks/useModal";
import {CurrentModalNameEnum} from "../../../../Enums/CurrentModalNameEnum";
import CancelProposalModal from "./CancelProposalModal/CancelProposalModal";
import {api} from "../../../../ReduxStore/api/api";
import {RtkQueryHelper} from "../../../../helpers/rtk-query.helper";
import styles from "../../Modals.module.scss";
import {Modal} from "@devapo/devapo-ui"

const CancelProposal = () => {

    const {currentProposal,currentOpenModal} = useSelector((state : RootState) => state.modalPopup)
    const [cancelProposal, result] = api.useCancelProposalMutation()
    const {closeAll} = UseModal()

    const doDeclineProposal = () => {
        cancelProposal(currentProposal.id);
    };

    useEffect(() => {
        if(RtkQueryHelper.checkResultIsFulfilled(result)){
            closeAll();
        }
    },[result])

    return (
        <Modal
            isOpen={currentOpenModal === CurrentModalNameEnum.CANCEL_PROPOSAL}
            onClose={() => closeAll()}
            modalClass={styles.modal}
            backdropClass={styles.backdrop}
        >
            <CancelProposalModal doDeclineProposal={doDeclineProposal} />
        </Modal>
    )
}

export default CancelProposal