import React from "react";
import noAbsencesIcon from "../../../assets/icons/no_absences-icon.svg";
import ProposalsTable from "../../Tables/ProposalsTable";
import ProposalStatusEnum from "../../../Enums/ProposalStatusEnum";
import { api } from "src/ReduxStore/api/api";
import Employee from "../../../Types/data/Employee";
import { useSelector } from "react-redux";
import RootState from "../../../Types/states/RootState";

const ProposalsTab = () => {
    const employees = useSelector((state: RootState) => state.employees);

    const { data, isLoading, isFetching } = api.useGetProposalsWithParamsQuery({
      employee: employees.map((employee : Employee) => employee.id),
      proposalStatus : [ProposalStatusEnum.IN_PROGRESS],
    })

    if(!data || data.length === 0 || isLoading || isFetching) {
        return (
            <div className="text-center mt-3">
                <img className="mt-5 mb-4" src={noAbsencesIcon} alt=""/>
                <h5 className="mt-2 no-absences">Nie ma wniosków do akceptacji</h5>
            </div>
        )
    }

    return (
        <>
            <ProposalsTable data={data} />
        </>
    )
}

export default ProposalsTab