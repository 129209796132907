import React, {useEffect} from "react";
import DeleteAbsenceModal from "./DeleteAbsenceModal/DeleteAbsenceModal";
import {useDispatch, useSelector} from "react-redux";
import {CurrentModalNameEnum} from "../../../../Enums/CurrentModalNameEnum";
import RootState from "../../../../Types/states/RootState";
import UseModal from "../../../../Services/CustomHooks/useModal";
import {useFormik} from "formik";
import {withFormikDevtools} from "formik-devtools-extension";
import {api} from "../../../../ReduxStore/api/api";
import {RtkQueryHelper} from "../../../../helpers/rtk-query.helper";
import {Modal} from "@devapo/devapo-ui"
import styles from "../../Modals.module.scss";

const DeleteAbsence = () => {
    const {currentOpenModal, currentAbsence} = useSelector((state: RootState) => state.modalPopup);
    const [cancelAbsence, result] = api.useCancelAbsenceMutation()
    const {closeAll} = UseModal()
    const dispatch = useDispatch()

    useEffect(() => {
        if (RtkQueryHelper.checkResultIsFulfilled(result)) {
            formik.resetForm()
            closeAll();
        }
    }, [result])

    const formik = useFormik({
        initialValues: {
            canceledReasonText: ""
        },
        onSubmit: values => {
            doCancelAbsence(values)
        }
    })

    useEffect(() => {
        process.env.NODE_ENV !== 'production' && withFormikDevtools(formik);
    }, [])

    useEffect(() => {
        formik.setFieldValue("canceledReasonText", "")
    }, [currentAbsence])

    const doCancelAbsence = (values) => {
        if (values.canceledReasonText) {
            cancelAbsence({id: currentAbsence.id, message: values.canceledReasonText});
        } else {
            dispatch(cancelAbsence({id: currentAbsence.id}));
        }
    };

    return (
        <>
            <Modal
                isOpen={currentOpenModal === CurrentModalNameEnum.DELETE_ABSENCE}
                onClose={() => closeAll()}
                modalClass={styles.modal}
                backdropClass={styles.backdrop}
            >
                <DeleteAbsenceModal formik={formik}/>
            </Modal>
        </>
    )
}
export default DeleteAbsence;