import Absence from "../Types/data/Absence";
import PendingProposal from "../Types/data/PendingProposal";
import Proposal from "../Types/data/Proposal";
import { DateRange } from "../Types/DateRange";
import SortOrder from "../Types/SortOrder";
import DateService from "./DateService";
import Position from "src/Types/data/Position";
import Project from "src/Types/data/Project";
import FetchService from "./FetcherService";
import User from "src/Types/User";

class DataService {
    static async getUser():Promise<User> {
        const response = await FetchService.fetch('user');
        const data = await response.json();
        data.exp = new Date(data.exp);

        return data as User;
    }

    static async getProposal(id:number):Promise<Proposal> {
        const response = await FetchService.fetch(`proposals/${id}`);
        const responseData = await response.json();
        const {absenceStartDate, absenceFinishDate} = responseData;

        const data = {
            ...responseData,
            absenceStartDate: new Date(absenceStartDate),
            absenceFinishDate: new Date(absenceFinishDate)
        };

        return data as Proposal;
    }

    static async getProposals(filters?:any):Promise<Proposal[]> {
        const request = filters ?
            FetchService.fetchWithParams({filters}, 'proposals') :
            FetchService.fetch('proposals');

        const response = await request;
        const responseData = await response.json();

        const data = responseData.content.map((proposal:any) => {
            const {absenceStartDate, absenceFinishDate} = proposal;

            return {
                ...proposal,
                absenceStartDate: new Date(absenceStartDate),
                absenceFinishDate: new Date(absenceFinishDate)
            }
        });

        return data as Proposal[];
    }

    static async postProposal(proposal:PendingProposal):Promise<Response> {
        const {absenceStartDate, absenceFinishDate} = proposal;

        const response = await FetchService.fetch('proposals', {
            method: 'POST',
            body: {
                ...proposal,
                absenceStartDate: DateService.stringifyDate(absenceStartDate,true),
                absenceFinishDate: DateService.stringifyDate(absenceFinishDate, true)
            }
        });

        return response;
    }

    static async updateProposal(proposal:Partial<Proposal>):Promise<Response> {
        let newProposal:any = {...proposal};

        if (proposal.absenceStartDate) {
            newProposal.absenceStartDate = proposal.absenceStartDate;
        }

        if (proposal.absenceFinishDate) {
            newProposal.absenceFinishDate = proposal.absenceFinishDate;
        }

        if (!proposal.replacement) {
            newProposal.replacement = null;
        }

        const response = await FetchService.fetch(`proposals`, {
            method: 'PATCH',
            body: newProposal
        });

        return response;
    }

    static async acceptProposal(proposalId:number):Promise<Response> {
        const response = await FetchService.fetch(`proposals/${proposalId}/accept`, {
            method: 'POST'
        });

        return response;
    }

    static async deleteProposal(proposalId:number):Promise<Response> {
        const response = await FetchService.fetch(`proposals/${proposalId}/cancel`, {
            method: 'DELETE'
        });

        return response;
    }


    static async declineProposal(proposalId:number, message?:string):Promise<Response> {
        let request:Promise<any>|null = null;

        if (message) {
            request = FetchService.fetch(`proposals/${proposalId}/decline`, {
                method: 'post',
                headers: {
                    'content-type': 'text/plain'
                },
                body: message
            });
        } else {
            request = FetchService.fetch(`proposals/${proposalId}/decline`, {
                method: 'POST'
            });
        }

        const response = await request;

        return response;
    }

    static async getAbsence(id:number):Promise<Absence> {
        const response = await FetchService.fetch(`absences/${id}`);
        const responseData = await response.json();
        const {startDate, endDate} = responseData;

        const data = {
            ...responseData,
            startDate: new Date(startDate),
            endDate: new Date(endDate)
        };

        return data as Absence;
    }

    static async getAbsences(filters?:any):Promise<Absence[]> {
        const request = filters ?
            FetchService.fetchWithParams({filters}, 'absences') :
            FetchService.fetch('absences');

        const response = await request;
        const responseData = await response.json();

        const data = responseData.content.map((absence:any) => {
            const {startDate, endDate} = absence;

            return {
                ...absence,
                startDate: new Date(startDate),
                endDate: new Date(endDate)
            };
        });

        return data as Absence[];
    }

    static async cancelAbsence(id:number, message?:string):Promise<Response> {
        let request:Promise<Response>|null = null;

        if (message) {
            request = FetchService.fetch(`absences/${id}/cancel`, {
                method: 'POST',
                body: message
            });
        } else {
            request = FetchService.fetch(`absences/${id}/cancel`, {
                method: 'POST'
            });
        }

        const response = await request;

        return response;
    }

    static async getSortedAndFilteredAbsences(
        sortBy:string,
        sortOrder:SortOrder,
        dateFilter:DateRange
    ):Promise<Absence[]> {
        const dateFrom = dateFilter.dateFrom ? DateService.stringifyDate(dateFilter.dateFrom) : null;
        const dateTo = dateFilter.dateTo ? DateService.stringifyDate(dateFilter.dateTo) : null;

        const response = await FetchService.fetchWithParams('absences', {
            filters: {
                absenceStartDate: dateFrom,
                absenceFinishDate: dateTo
            },
            sortDirection: sortOrder,
            orderProperty: sortBy
        })

        const responseData = await response.json();
        const data = responseData.content;

        return data as Absence[];
    }

    static async getPositions():Promise<Position[]> {
        const response = await FetchService.fetch('positions');
        const responseData = await response.json();
        const data = responseData.content;

        return data as Position[];
    }

    static async getProjects():Promise<Project[]> {
        const response = await FetchService.fetch('projects');
        const responseData = await response.json();
        const data = responseData.content;

        return data as Project[];
    }
}

export default DataService;
