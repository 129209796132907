import React, {FC} from "react";
import Employee from "../../../Types/data/Employee";
import {CurrentModalNameEnum} from "../../../Enums/CurrentModalNameEnum";
import UseModal from "../../../Services/CustomHooks/useModal";
import {useDispatch, useSelector} from "react-redux";
import RootState from "../../../Types/states/RootState";
import CalendarDayCell from "./CalendarDayCell";
import {
    CreateProposalModalContentSetAbsenceEmployee,
    CreateProposalModalContentSetAbsenceEndDate,
    CreateProposalModalContentSetAbsenceStartDate
} from "../../../ReduxStore/reduxSlices/modal/createProposalSlice";
import {RoleEnum} from "../../../Enums/RoleEnum";

interface CalendarDaysRowProps {
    daysMonthArray : Date[],
    employee : Employee,
    row : number
}

const CalendarDaysRow : FC<CalendarDaysRowProps> = (props) => {

    const {daysMonthArray,employee,row} = props;
    const currentDate = new Date();
    const yesterdayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
    const dispatch = useDispatch()
    const role = useSelector((state:RootState) => state.user.role);
    const {changeModalHistory} = UseModal();
    const loggedInUserId = useSelector((state:RootState) => state.user.loggedInUserID);


    const openCreateProposal = (employee:Employee, dateFrom: Date) => {
        if( dateFrom > yesterdayDate && (role === RoleEnum.EMPLOYER || employee.id === loggedInUserId )){
            dispatch(CreateProposalModalContentSetAbsenceStartDate({absenceStartDate :dateFrom }))
            dispatch(CreateProposalModalContentSetAbsenceEndDate({absenceFinishDate :dateFrom }))
            dispatch(CreateProposalModalContentSetAbsenceEmployee({employee :employee.id }))

            changeModalHistory(CurrentModalNameEnum.CREATE_PROPOSAL)
        }
    }

    return (
        <>
            {
                daysMonthArray.map((day : Date, index: number) => (
                    <CalendarDayCell key={`cell-${index}`} day={day} employee={employee} openCreateProposal={openCreateProposal} row={row} />
                ))
            }
        </>
    )
}

export default CalendarDaysRow