import React, {Dispatch, FC, SetStateAction, useState} from "react";
import {ProposalFilteringOptionsEnum} from "../../../../Enums/ProposalFilteringOptionsEnum";
import classNames from "classnames";
import styles from "./MyProposalTopBarView.module.scss"

interface ProposalProps {
    setStatus: Dispatch<SetStateAction<ProposalFilteringOptionsEnum>>
}

const MyProposalTopBarView : FC<ProposalProps>  = (props) => {
    const {setStatus} = props;
    const [activeButton, setActiveButton] = useState<string>(ProposalFilteringOptionsEnum.ALL);

    const handleClick = (type : ProposalFilteringOptionsEnum) => {
        setActiveButton(type);
        setStatus(type);
    };

    return (
        <div className={styles.top_bar} >
            {
                Object.values(ProposalFilteringOptionsEnum).map((type: ProposalFilteringOptionsEnum, index: number) => {
                    return (
                        <button
                                className={classNames({
                                    [styles.proposal_button] : true,
                                    [styles.active] : activeButton === type
                                })}
                                value={type}
                                name={type}
                                key={index}
                                onClick={() => handleClick(type)}
                        >
                            {type}
                        </button>
                    )
                })
            }
        </div>
    )

}
export default MyProposalTopBarView;