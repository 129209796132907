import "./ShowMyProposalsHoverIcons.scss";
import React, { FunctionComponent } from "react";
import { IMyProposalsTableData } from "../MyProposalsTable";
import ProposalStatusEnum from "../../../Enums/ProposalStatusEnum";
import reviewIcon from "../../../assets/icons/review-icon.svg";
import UseModal from "../../../Services/CustomHooks/useModal";
import editIcon from "../../../assets/icons/edit-icon.svg";
import deleteIcon from "../../../assets/icons/delete-icon.svg"

interface IShowMyProposalsHoverIconsProps {
    element: IMyProposalsTableData;
}

const ShowMyProposalsHoverIcons:FunctionComponent<IShowMyProposalsHoverIconsProps> = (props:IShowMyProposalsHoverIconsProps) => {
    const proposal = props.element._proposal;
    const {buttonReviewProposal,buttonEditProposal,buttonCancelProposal} = UseModal();

    return (
        <div className="proposals-hover">
            <button className="review-span btn p-0 mb-0" onClick={ (e) => {
                e.stopPropagation();
                buttonReviewProposal(proposal)
            } }>
                <img src={reviewIcon} alt=""/>
            </button>

            {
                (proposal.proposalStatus === ProposalStatusEnum.IN_PROGRESS) && (
                    <>
                        <button className="btn pr-0 edit-block ml-1" onClick={(e) => {
                            e.stopPropagation();
                            buttonEditProposal(proposal)
                        }} >
                            <img src={editIcon} alt=""/>
                        </button>
                        <button className="btn pb-0 pt-0 pl-2 d-flex" onClick={(e) => {
                            e.stopPropagation();
                            buttonCancelProposal(proposal)
                        }}>
                            <img src={deleteIcon} alt="" className="mt-auto cancel-icon"/>
                        </button>
                    </>
                )
            }
        </div>
    );
};

export default ShowMyProposalsHoverIcons;
