import SortOrder from "../../../Types/SortOrder";

const now = new Date();

export type actionsHistoryTabState = {
    actionsHistoryTabSortOrder : SortOrder,
    actionsHistoryTabSortBy : string,
    absenceTabDateFrom : Date,
    absenceTabDateTo : Date
}

const actionsHistoryTabInitialState : actionsHistoryTabState = {
    actionsHistoryTabSortOrder : 'desc',
    actionsHistoryTabSortBy : 'actionDay',
    absenceTabDateFrom : new Date(),
    absenceTabDateTo : new Date( now.getFullYear(), now.getMonth() + 1, 0)
}

export default actionsHistoryTabInitialState