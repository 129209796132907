import {createSlice} from "@reduxjs/toolkit";
import createProposal from "../../thunks/createProposal";
import createProposalModalContentInitialState from "../../initialStates/modal/createProposalModalContentInitialState";
import AbsenceTypeEnum from "../../../Enums/AbsenceTypeEnum";


const createProposalSlice = createSlice({
    name: 'createProposalModalContent',
    initialState: createProposalModalContentInitialState,
    reducers: {
        CreateProposalModalContentAbsenceResetState(state){
            return state = createProposalModalContentInitialState
        },
        CreateProposalModalContentSetAbsenceEndDate(state,action){
            state.absenceFinishDate = action.payload.absenceFinishDate
        },
        CreateProposalModalContentSetAbsenceStartDate(state,action){
            state.absenceStartDate = action.payload.absenceStartDate
        },
        CreateProposalModalContentSetAbsenceType(state,action){
            state.absenceType = action.payload.absenceType
            if(state.absenceType !== AbsenceTypeEnum.HOLIDAY){
                state.holidayType = null
            }
            return state;
        },
        CreateProposalModalContentSetAbsenceDescription(state,action){
            state.description = action.payload.description
        },
        CreateProposalModalContentSetAbsenceEmployee(state,action){
            state.employee = action.payload.employee
        },
        CreateProposalModalContentSetAbsenceHolidayType(state,action){
            state.holidayType = action.payload.holidayType
        },
        CreateProposalModalContentSetAbsenceReplacement(state,action){
            state.replacement = action.payload.replacement
        },

    },
    extraReducers :  (builder ) => {
        builder.addCase( createProposal.fulfilled, (state) => {});
    }
})

export const {
    CreateProposalModalContentAbsenceResetState,
    CreateProposalModalContentSetAbsenceEndDate,
    CreateProposalModalContentSetAbsenceStartDate,
    CreateProposalModalContentSetAbsenceType,
    CreateProposalModalContentSetAbsenceDescription,
    CreateProposalModalContentSetAbsenceEmployee,
    CreateProposalModalContentSetAbsenceHolidayType,
    CreateProposalModalContentSetAbsenceReplacement
} = createProposalSlice.actions
export default createProposalSlice.reducer

