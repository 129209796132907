import Project from "src/Types/data/Project";

export default class ProjectService {
    static getProjectIdByName(projects:Project[], name:string):number|null {
        const found = projects.find((project:Project) => {
            return project.name === name;
        });

        return found?.id ?? null;
    }
}
