import ProposalStatusEnum from "../../../Enums/ProposalStatusEnum";
import React, {FC} from "react";
import styles from "./DeclinedReason.module.scss"

interface DeclinedReasonProps {
    proposalStatus: ProposalStatusEnum,
    declinedReason: string
}

const DeclinedReason : FC<DeclinedReasonProps> = (props) => {

    const {proposalStatus, declinedReason} = props;

    return (
        <>
            {
                proposalStatus === ProposalStatusEnum.DECLINED && declinedReason && (
                    <div className={styles.declined_reason_container}>
                        <p className={styles.modal_heading}>Powód odrzucenia wniosku</p>
                        <p className={styles.reason}>{declinedReason}</p>
                    </div>
                )
            }
        </>
    )
}

export default DeclinedReason