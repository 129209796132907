import "./UiTableHead.scss";
import { ReactElement } from "react";
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import SortOrder from "../../../Types/SortOrder";
import UiTableHeadIcon from "./UiTableHeadIcon";
import { IUiTableConfig } from "./UiTable";

interface IUiTableHeadProps<T> {
    setSort: any;
    order: SortOrder;
    orderBy: keyof T;
    getPropertyName: any;
    data: T[];
    config?: IUiTableConfig<T>;
}

export default function UiTableHead<T>(props:IUiTableHeadProps<T>):ReactElement<IUiTableHeadProps<T>> {
    const {order, orderBy, setSort, data, getPropertyName, config} = props;

    const renderTableCell = (key:string) => {
        const customColumnWidths = config?.customColumnWidths;
        let style = {};

        if (customColumnWidths && customColumnWidths[key]) {
            style = {width: customColumnWidths[key]};
        }

        if (key.startsWith('_'))  {
            return null;
        }

        return (
            <TableCell
                key={key}
                sortDirection={orderBy === key ? order : false}
                className="ui-table-head-cell"
                style={style}
            >
                <TableSortLabel
                    active={orderBy === key}
                    direction={orderBy === key ? order : 'asc'}
                    onClick={() => setSort(key)}
                    IconComponent={() => null}
                    hideSortIcon={false}
                >
                    {getPropertyName(key)}
                </TableSortLabel>

                {(orderBy === key) ? (
                    UiTableHeadIcon({sortOrder: order})
                ) : (
                    UiTableHeadIcon({sortOrder: 'asc', hidden: true})
                )}
            </TableCell>
        );
    }

    return (
        <TableHead>
            <TableRow>
                {(data.length > 0) && Object.keys(data[0]).map((key:string) => {
                    return renderTableCell(key);
                })}
            </TableRow>
        </TableHead>
    );
};
