import Absence from "../../../../Types/data/Absence";
import {CurrentModalNameEnum} from "../../../../Enums/CurrentModalNameEnum";
import reviewIcon from "../../../../assets/icons/review-icon.svg";
import EmployeeAbsenceStatus from "../../../../Shared/EmployeeAbsenceStatus/EmployeeAbsenceStatus";
import DateFormatterView from "./DateFormater/DateFormatterView";
import React, {FC, useEffect, useState} from "react";
import FilteringEngineService from "../../../../Services/FilteringEngineService";
import {useSelector} from "react-redux";
import RootState from "../../../../Types/states/RootState";
import UseModal from "../../../../Services/CustomHooks/useModal";
import noAbsencesIcon from "../../../../assets/icons/no_absences-icon.svg";
import {Pagination} from "@material-ui/core";
import Proposal from "../../../../Types/data/Proposal";

interface AbsenceEmployeeListProps {
    list ?: Absence[],
    currentView: string
}

const AbsenceEmployeeList : FC<AbsenceEmployeeListProps> = (props) => {

    const {list, currentView} = props;
    const {getEmployeeNameByID} = FilteringEngineService();
    const employees = useSelector((state: RootState) => state.employees);
    const {changeModalHistory, setCurrentAbsence} = UseModal()

    const [currentPage, setCurrentPage] = useState(1)
    const [pageData,setPageData] = useState<Absence[]>([])

    useEffect(() => {
        if(list){
            setPageData(list.slice(0,10))
            setCurrentPage(1)
        }
    },[list])

    const changePage = (page : number) => {
        if(list){
            setCurrentPage(page)
            const start = (page * 10) - 10;
            const end = (page * 10);
            setPageData(list.slice(start,end))
        }
    }

    const handleClick = (e,page) => changePage(page)

    if (!list || list.length === 0) {
        return (
            <div className="text-center mt-3">
                <img className="mt-5 mb-4" src={noAbsencesIcon} alt=""/>
                <h5 className="mt-2 no-absences">Nie ma nieobecności</h5>
            </div>
        )
    }

    return (
        <div className="employee-list">
            {
                pageData && pageData.map((absence: Absence, index: number) => (
                    <div className="absence-list" key={index}
                         onClick={() => {
                             changeModalHistory(CurrentModalNameEnum.MOBILE_REVIEW_ABSENCE)
                             setCurrentAbsence(absence)
                         }}
                    >
                        <div className="employee-record">
                            <div className="d-flex align-items-center">
                                <EmployeeAbsenceStatus itemStatus={absence.absenceType}/>
                                <p className="mb-0 ml-2 employee-name">{getEmployeeNameByID(employees, absence.employee)}</p>
                            </div>
                            <DateFormatterView currentView={currentView} item={absence}/>
                        </div>
                    </div>

                ))
            }

            <Pagination
                className={"mt-2 pb-5"}
                page={currentPage}
                count={Math.ceil(list.length / 10)}
                onChange={handleClick}
            />

        </div>
    )
}

export default AbsenceEmployeeList