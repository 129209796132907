import React, {FC, useEffect, useState} from "react";
import UseModal from "../../../../../Services/CustomHooks/useModal";
import {useSelector} from "react-redux";
import RootState from "../../../../../Types/states/RootState";
import MobileModalHeader from "../../../../Shared/MobileModalHeader/MobileModalHeader";
import {ModalHeadersEnum} from "../../../../../Enums/ModalHeadersEnum";
import {
    DesktopModalHeaderHelper
} from "../../../../../DesktopComponents/Modals/ModalComponents/ModalHeader/DesktopModalHeaderHelper";
import AbsenceType from "../../../../../Shared/Partials/AbsenceType/AbsenceType";
import HolidayAbsenceType from "../../../../../Shared/Partials/HolidayAbsenceType/HolidayAbsenceType";
import EmployeeData from "../../../../../Shared/Partials/EmployeeData/EmployeeData";
import ReplacementPersonView from "../../../../../Shared/Partials/ReplacementPersonView/ReplacementPersonView";
import AbsenceDateRange from "../../../../../Shared/Partials/AbsenceDateRange/AbsenceDateRange";
import Description from "../../../../../Shared/Partials/Description/Description";
import EmployeeProjects from "../../../../../Shared/Partials/EmployeeProjects/EmployeeProjects";
import {Button} from "@devapo/devapo-ui";
import styles from "./MobileAcceptProposalModal.module.scss"

interface MobileAcceptProposalModalProps {
    acceptProposal: () => void
}

const MobileAcceptProposalModal: FC<MobileAcceptProposalModalProps> = (props) => {
    const {acceptProposal} = props;
    const {currentProposal} = useSelector((state: RootState) => state.modalPopup)
    const {backToPrevModal} = UseModal()
    const [header, setHeader] = useState<ModalHeadersEnum | undefined>()

    useEffect(() => {
        DesktopModalHeaderHelper.getModalAcceptProposalHeaderEnum(setHeader, currentProposal)
    }, [currentProposal])

    return (
        <div className={styles.modal_body} >

            <MobileModalHeader
                header={header}
                startDate={currentProposal.absenceStartDate}
                endDate={currentProposal.absenceFinishDate}
            />

            <div>
                <EmployeeData employee={currentProposal.employee}/>
                <AbsenceType type={currentProposal.absenceType}/>
                <HolidayAbsenceType type={currentProposal.holidayType} absence={currentProposal.absenceType}/>
                <AbsenceDateRange
                    startDate={currentProposal.absenceStartDate}
                    endDate={currentProposal.absenceFinishDate}
                />
                <Description description={currentProposal.description}/>
                <EmployeeProjects proposal={currentProposal}/>
                <ReplacementPersonView replacement={currentProposal.replacement}/>
            </div>

            <div className={styles.footer}>
                <Button
                    text={"Anuluj"}
                    onClick={backToPrevModal}
                    type={"button"}
                    color={"normal"}
                />

                <Button
                    text={"Akceptuj"}
                    type={"button"}
                    onClick={acceptProposal}
                    color={"success"}
                    width={150}
                />
            </div>

        </div>
    )
}

export default MobileAcceptProposalModal