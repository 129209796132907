
export namespace EmailService {

    export const remove1BeforeAt = (email : string) : string  => {
        const modifiedEmail = email.split("@");
        const emailNameIndex = 0;

        if(modifiedEmail[emailNameIndex].split("")[modifiedEmail.length - 1] === "1"){
            modifiedEmail[emailNameIndex] = modifiedEmail[emailNameIndex].substring(0,modifiedEmail[emailNameIndex].length - 1)
        }

        return modifiedEmail.join("@");
    }

    export const compare = (first ?: string, second ?: string) : boolean => {
        return first?.trim().toLowerCase() === second?.toLowerCase().trim()
    }

}