import {modalPopupState} from "../../Types/states/modalPopupState";

const modalPopupInitialState : modalPopupState = {
    modalHistory : [],
    currentProposal: [],
    currentAbsence : [],
    currentOpenModal : null ,
    isHistoryBlock : false
}

export default modalPopupInitialState