import Employee from "src/Types/data/Employee";
import ProposalStatusEnum from "../Enums/ProposalStatusEnum";
import Proposal from "../Types/data/Proposal";
import EmployeeService from "./EmployeeService";

class FilteringService {
    static getEmployeeById:any;
    
    constructor() {
        FilteringService.getEmployeeById = EmployeeService().getEmployeeById;
    }

    static getNow() {
        const now = new Date();

        return {
            date: now,
            hours: now.getHours(),
            minutes: now.getMinutes(),
            seconds: now.getSeconds(),
            milliseconds: now.getMilliseconds()
        };
    }

    static filterProposalsByProposalStatus(proposals:Proposal[], proposalStatus:ProposalStatusEnum) {
        return proposals.filter((proposal:Proposal) => {
            return proposal.proposalStatus === proposalStatus;
        }) as Proposal[];
    }

    static filterProposalsByEmployeeId(proposals:Proposal[], employeeId:number) {
        return proposals.filter((proposal:Proposal) => {
            return proposal.employee === employeeId;
        }) as Proposal[];
    }
    
    static getPastProposals(proposals:Proposal[]) {
        const now = this.getNow();

        return proposals.filter((proposal:Proposal) => {
            return new Date(new Date(proposal.absenceFinishDate.toString())
                .setHours(now.hours, now.minutes, now.seconds, now.milliseconds)) < now.date
        }) as Proposal[];
    }

    static getCurrentProposals(proposals:Proposal[]) {
        const now = this.getNow();

        return proposals.filter((proposal:Proposal) => {
            return new Date(new Date(proposal.absenceFinishDate.toString())
                .setHours(now.hours, now.minutes, now.seconds, now.milliseconds)) >= now.date
        });
    }

    static removeRepeatedElemsFromArray<T>(array:T[]) {
        const newArray:T[] = [];

        array.forEach((elem:T) => {
            const isElemFound = !!newArray.find((newElem:T) => {
                return JSON.stringify(elem) === JSON.stringify(newElem)
            });

            if (!isElemFound) {
                newArray.push(elem);
            }
        });

        return newArray;
    }

    static filterEmployeeIdsByAll(
        allEmployees:Employee[],
        employeeIds:number[],
        positionIds:number[],
        projectIds:number[]
    ):number[] {
        const employeeIdsByPosition:number[] = []

        positionIds.forEach((positionId:number) => {
            allEmployees.forEach((employee:Employee) => {
                if (employee.positions.includes(positionId)) {
                    employeeIdsByPosition.push(employee.id);
                }
            });
        });

        const employeeIdsByProject:number[] = []

        projectIds.forEach((projectId:number) => {
            allEmployees.forEach((employee:Employee) => {
                if (employee.activeProjects.includes(projectId)) {
                    employeeIdsByProject.push(employee.id);
                }
            });
        });

        return FilteringService.removeRepeatedElemsFromArray([
            ...employeeIds,
            ...employeeIdsByPosition,
            ...employeeIdsByProject
        ]);
    }
}

export default FilteringService;
