import React, {FC, ReactElement, useEffect, useState} from "react";
import {KeycloakService} from "./KeycloakService";
import UserProfile from "./UserProfile";

interface AuthProps{
    children?: React.ReactNode;
}

const Auth : FC<AuthProps> = (props) : ReactElement | null => {

    const {children} = props;
    const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {
        KeycloakService.login().then(isAuthenticated => {
            if(isAuthenticated){
                setIsAuth(isAuthenticated)
            }
        }).catch(() => KeycloakService.loginAgain())
    },[])

    if(!isAuth){
        return null
    }

    return (
        <>
            <UserProfile>
                {children}
            </UserProfile>
        </>
    )
}

export default Auth