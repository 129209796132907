import PendingProposal from "../../Types/data/PendingProposal";
import DataService from "../../Services/DataService";
import loadProposals from "./loadProposals";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {reduxSliceTypeEnum} from "../reduxSliceTypeEnum/reduxSliceTypeEnum";

const updateProposal = createAsyncThunk(reduxSliceTypeEnum.UPDATE_PROPOSAL, async (proposal:Partial<PendingProposal> , {dispatch}) => {
    await DataService.updateProposal(proposal);
    dispatch(loadProposals());
})

export default updateProposal;
