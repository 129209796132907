import Employee from "../../../../Types/data/Employee";
import EmployeeService from "../../../../Services/EmployeeService";

export namespace filterHelper {

    export const filterListByValue = (list : Employee[],value : string) => {
        return list.filter(item => {
            const fullName = EmployeeService().getEmployeeName(item).toLowerCase()
            const fullNameReverse = EmployeeService().getEmployeeName(item).split(" ").reverse().join(" ").toLowerCase();

            if(fullName.includes(value.toLowerCase())
                || fullNameReverse.includes(value.toLowerCase())
                || fullName === value.toLowerCase()
                || fullNameReverse.toLowerCase() === value.toLowerCase()
            ){
                return item
            }
        })
    }

    export const filterStringListByValue = (list : string[], value : string) => {
        return list.filter(item => {

            if(
                item.trim().toLowerCase().includes(value.trim().toLowerCase() ) ||
                item.trim().toLowerCase() === value.trim().toLowerCase()
            ){
                return item;
            }
        })
    }

    export const setBackgroundValueByName = (list : Employee[] ,value : string) : string => {

        if(value.length > 0 && list){
            let bgNames : string[] = [];

            list.filter(employee => {

                const firstName = employee.firstName.trim()
                const lastName = employee.lastName.trim()
                const fullNameReverse= [firstName,lastName].join(" ");

                const fullName = EmployeeService().getEmployeeName(employee)

                if(fullNameReverse.toLowerCase().startsWith(value.toLowerCase().trim() ) ){
                    bgNames.push(`${firstName.trim()} ${lastName.trim()}`)
                    return employee;
                }

                if(fullName.toLowerCase().startsWith(value.toLowerCase().trim() ) ){
                    bgNames.push(`${lastName.trim()} ${firstName.trim()}`)
                    return employee
                }

            })

            const firstChild = bgNames[0];

            if(firstChild?.length > 0){
                return firstChild
            }
        }

        return ""
    }

    export const getFilter = (backgroundType : string ,positionFilter : string[],projectFilter : string[],employeesFilter : Employee[], backgroundValue : string) => {

        const {getEmployeeName,getEmployeeByName} = EmployeeService()

        if(backgroundType === "Pracownicy"){

            const name = getEmployeeByName(employeesFilter,backgroundValue) || getEmployeeByName(employeesFilter,backgroundValue.split(" ").reverse().join(""))
            if(name){
                return getEmployeeName(name)
            }

        }
        if(backgroundType === "Role"){
            return positionFilter[0]
        }
        if(backgroundType === "Projekty"){
            return projectFilter[0]
        }

        return ""
    }

    export const setBackgroundValueFromStringList = (list : string[], value : string) : string => {

        if(value.length > 0 && list){
            let bgNames : string[] = [];

            list.filter(item => {

                const listValue = item.trim();

                if(listValue.toLowerCase().startsWith(value.toLowerCase().trim() ) ){
                    bgNames.push(listValue.trim())
                    return item
                }

            })

            const firstChild = bgNames[0];

            if(firstChild?.length > 0){
                return firstChild
            }
        }

        return "";
    }

}