import {createSlice} from "@reduxjs/toolkit";
import proposalTabInitialState from "../../initialStates/table/proposalTabInitialState";

const proposalTabSlice = createSlice({
    name : "proposalTabSlice",
    initialState : proposalTabInitialState,
    reducers : {
        selectProposalTabSortOrder(state,action){
            state.proposalTabSortOrder = action.payload.proposalTabSortOrder
        },
        setProposalTabSortBy(state,action){
            state.proposalTabSortBy = action.payload.proposalTabSortBy
        },
    }
})

export const {
    selectProposalTabSortOrder,
    setProposalTabSortBy
} = proposalTabSlice.actions

export default proposalTabSlice.reducer