import React, {useEffect, useState} from "react";
import ActionsHistoryTable from "../../Tables/ActionsHistoryTable";
import ProposalStatusEnum from "../../../Enums/ProposalStatusEnum";
import {useDispatch, useSelector} from "react-redux";
import RootState from "../../../Types/states/RootState";
import noAbsencesIcon from "../../../assets/icons/no_absences-icon.svg";
import Datepicker from "../../../Shared/Datepicker/Datepicker";
import DateFormatService from "../../../Services/DateFormatService";
import {useFormik} from "formik";
import DateService from "../../../Services/DateService";
import {
    setActionsHistoryTabDateFrom,
    setActionsHistoryTabDateTo
} from "../../../ReduxStore/reduxSlices/table/actionsHistoryTabSlice";
import Proposal from "../../../Types/data/Proposal";
import ActionsHistoryTableFilterButtons
    from "../../Tables/ActionsHistoryTableFilterButtons/ActionsHistoryTableFilterButtons";
import { api } from "src/ReduxStore/api/api";
import Employee from "../../../Types/data/Employee";

const ActionsHistoryTab = () => {

    const absenceTabDateFrom = useSelector((state:RootState) => state.tables.actionsHistoryTabSlice.absenceTabDateFrom);
    const absenceTabDateTo = useSelector((state:RootState) => state.tables.actionsHistoryTabSlice.absenceTabDateTo);
    const employees = useSelector((state: RootState) => state.employees);

    const { data, isLoading, isFetching } = api.useGetProposalsWithParamsQuery({
        employee: employees.map((employee : Employee) => employee.id),
        proposalStatus : [ProposalStatusEnum.ACCEPTED,ProposalStatusEnum.DECLINED],
        absenceStartDate : DateService.stringifyDate(absenceTabDateFrom,true),
        absenceFinishDate : DateService.stringifyDate(absenceTabDateTo,true)
    })

    const [proposalList, setProposal] = useState<Proposal[]>([]);

    const dispatch = useDispatch();
    const {toMediumDate} = DateFormatService();

    const formik = useFormik({
        initialValues: {
            absenceStartDate: absenceTabDateFrom,
            absenceFinishDate: absenceTabDateTo
        },
        onSubmit: values => {
        }
    })

    useEffect(() => {
        data && setProposal(data)
    },[data])

    useEffect(() => {
        setDateTo(formik.values.absenceFinishDate)
        setDateFrom(formik.values.absenceStartDate)
    }, [formik.values.absenceFinishDate, formik.values.absenceStartDate])

    const setDateTo = (date: Date): void => {
        dispatch(setActionsHistoryTabDateTo({absenceTabDateTo: date}))
    };

    const setDateFrom = (date: Date): void => {
        dispatch(setActionsHistoryTabDateFrom({absenceTabDateFrom: date}))
    };


    return (

        <>
            <div className={"history-filter-bar"} >

                <div className="chooseDate">
                    <p className="mr-2 mb-4">Zakres dat</p>
                    <Datepicker formik={formik} disablePast={false}/>
                </div>

                <ActionsHistoryTableFilterButtons setProposal={setProposal} proposalList={data} />

            </div>

            { (!data || data.length === 0 || isLoading || isFetching) ? (
                <div className="text-center">
                    <img className="mt-4 mb-4" src={noAbsencesIcon} alt=""/>
                    <h5 className="mt-2 no-absences">Nie ma nieobecności w dniach</h5>
                    <p>
                        {absenceTabDateFrom && toMediumDate(absenceTabDateFrom)}-
                        {absenceTabDateTo && toMediumDate(absenceTabDateTo)}
                    </p>
                </div>
            ) : (
                <ActionsHistoryTable proposalList={proposalList} />
            )}

        </>


    )
}

export default ActionsHistoryTab