import React from "react";
import noAbsencesIcon from "../../../assets/icons/no_absences-icon.svg";
import MyProposalsTable from "../../Tables/MyProposalsTable";
import MyProposalsHistoryTable from "../../Tables/MyProposalsHistoryTable";
import FilteringService from "../../../Services/FilteringService";
import {useSelector} from "react-redux";
import RootState from "../../../Types/states/RootState";
import { api } from "src/ReduxStore/api/api";

const MyProposalsTab = () => {

    const loggedInUserID = useSelector((state: RootState) => state.user.loggedInUserID);

    const { data, isLoading, isFetching } = api.useGetProposalsWithParamsQuery({
        employee : [loggedInUserID]
    })

    const myPastProposals = data ? FilteringService.getPastProposals(data) : [];
    const myCurrentProposals = data ? FilteringService.getCurrentProposals(data) : [];


    if(!data || data.length === 0 || isLoading || isFetching) {
        return (
            <div className="text-center mt-3">
                <img className="mt-5 mb-4" src={noAbsencesIcon} alt=""/>
                <h5 className="mt-2 no-absences">Nie ma aktywnych wniosków</h5>
            </div>
        )
    }

    return (
        <>
            {
                myCurrentProposals.length > 0 && (
                    <>
                        <h5 className="mt-5 pl-1">Zaplanowane</h5>
                        <MyProposalsTable data={myCurrentProposals} />
                    </>
                )
            }

            {
                myPastProposals.length > 0 && (
                    <>
                        <h5 className="mt-5 pl-1">Historia</h5>
                        <MyProposalsHistoryTable data={myPastProposals} />
                    </>
                )
            }
        </>
    )
}

export default MyProposalsTab