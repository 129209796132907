import "./../ShowMyProposalsHoverIcons/ShowMyProposalsHoverIcons.scss";
import React, {FunctionComponent} from "react";
import { IProposalsTableData } from "../ProposalsTable";
import UiAcceptedIcon from '../../../assets/icons/accepted-icon.svg';
import UseModal from "../../../Services/CustomHooks/useModal";
import cancelledIcon from "../../../assets/icons/cancelled-icon.svg";

interface IShowProposalsHoverIconsProps {
    element: IProposalsTableData;
}

const ShowProposalsHoverIcons:FunctionComponent<IShowProposalsHoverIconsProps> = (props:IShowProposalsHoverIconsProps) => {
    const proposal = props.element._proposal;
    const {buttonOpenDeclineReceivedProposalModal,buttonOpenAcceptReceivedProposalModal} = UseModal()

    const accept = (event:React.MouseEvent) => {
        event.stopPropagation();
        buttonOpenAcceptReceivedProposalModal(proposal)
    };

    const cancel = (event: React.MouseEvent) => {
        event.stopPropagation();
        buttonOpenDeclineReceivedProposalModal(proposal)
    };

    return (
        <div className="proposals-hover">
            <button className="btn" onClick={cancel}>
                <img src={cancelledIcon} alt="cancel icon" className="cancel-icon"/>
            </button>
            <button className="btn" onClick={accept}>
                <img src={UiAcceptedIcon} alt="accept icon" className="proposals-hover-accepted-icon" />
            </button>
        </div>
    );
};

export default ShowProposalsHoverIcons;
