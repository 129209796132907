import { combineReducers } from "redux";
import absenceTabReducer from "./../../reduxSlices/table/absenceTabSlice"
import myProposalTabReducer from "./../../reduxSlices/table/myProposalTabSlice"
import actionsHistoryReducer from "./../../reduxSlices/table/actionsHistoryTabSlice"
import proposalTabReducer from "./../../reduxSlices/table/proposalTabSlice"

const tableReducer = combineReducers({
    absenceTabSlice: absenceTabReducer,
    myProposalTabSlice: myProposalTabReducer,
    actionsHistoryTabSlice: actionsHistoryReducer,
    proposalTabSlice: proposalTabReducer,
});

export default tableReducer;
