import React, {FC} from "react";
import { useDispatch, useSelector } from "react-redux";
import checkAbsenceTypeEnum from "../../helpers/checkAbsenceTypeEnum";
import Proposal from "../../Types/data/Proposal";
import SortOrder from "../../Types/SortOrder";
import RootState from "../../Types/states/RootState";
import UiTable from "../Shared/Table/UiTable";
import ShowProposalStatus from "./ShowProposalStatus/ShowProposalStatus";
import ShowMyProposalsHoverIcons from "./ShowMyProposalsHoverIcons/ShowMyProposalsHoverIcons";
import ProposalStatusEnum from "../../Enums/ProposalStatusEnum";
import AbsenceTypeEnum from "../../Enums/AbsenceTypeEnum";
import checkHolidayTypeEnum from "../../helpers/checkHolidayTypeEnum";
import generateCustomDateComparator from "src/helpers/customDateComparator";
import UseModal from "../../Services/CustomHooks/useModal";
import {
    selectMyProposalTabSortOrder,
    setMyProposalTabSortBy
} from "../../ReduxStore/reduxSlices/table/myProposalTabSlice";
import DateService from "../../Services/DateService";

export interface IMyProposalsTableData {
    proposalStatus: string
    absenceStartDate: Date;
    absenceFinishDate: Date;
    absenceType: string;
    description: string;
    '_proposal': Proposal;
}

interface MyProposalsTableProps {
    data : Proposal[]
}

const MyProposalsTable: FC<MyProposalsTableProps> = (props) => {

    const { data } = props;


    const sortBy = useSelector((state:RootState) => state.tables.myProposalTabSlice.myProposalTabSortBy);
    const sortOrder = useSelector((state:RootState) => state.tables.myProposalTabSlice.myProposalTabSortOrder);
    const dispatch = useDispatch();
    const {buttonReviewProposal} = UseModal();

    const tableData = data.map((proposal:Proposal) => {
        const {proposalStatus, absenceFinishDate, absenceStartDate, absenceType, description,declinedReason, holidayType} = proposal;

        return {
            proposalStatus,
            absenceType:  absenceType === AbsenceTypeEnum.HOLIDAY && holidayType ? `${checkAbsenceTypeEnum(absenceType)} ${checkHolidayTypeEnum(holidayType).toLowerCase()}` : checkAbsenceTypeEnum(absenceType) ,
            absenceStartDate: absenceStartDate,
            absenceFinishDate: absenceFinishDate,
            description : proposalStatus === ProposalStatusEnum.DECLINED && declinedReason ? `Powód odrzucenia : ${declinedReason}` : description,
            '_proposal': proposal
        };
    }) as IMyProposalsTableData[];

    const getPropertyName = (property:string):string => {
        switch(property) {
            case 'absenceStartDate':
                return 'Data od';
            case 'absenceFinishDate':
                return 'Data do';
            case 'absenceType':
                return 'Typ wniosku';
            case 'description':
                return 'Opis';
            case 'proposalStatus':
                return 'Status';
            default:
                return '';
        }
    };

    const setSort = (newSortBy:string) => {
        if (newSortBy === sortBy) {
            const newSortOrder:SortOrder = sortOrder === 'asc' ? 'desc' : 'asc';

            dispatch(selectMyProposalTabSortOrder({myProposalTabSortOrder : newSortOrder }))

        } else {
            dispatch(selectMyProposalTabSortOrder({myProposalTabSortOrder : "asc" }))
            dispatch(setMyProposalTabSortBy({myProposalTabSortBy : newSortBy }))
        }
    };

    const onElementClick = (element:IMyProposalsTableData) => {
        const proposal = element._proposal;
        buttonReviewProposal(proposal)
    }

    return (
        <div className="proposals-table">
            <UiTable<IMyProposalsTableData>
                data={tableData}
                sortBy={sortBy as keyof IMyProposalsTableData}
                sortOrder={sortOrder}
                getPropertyName={getPropertyName}
                setSort={setSort}
                config={{
                    customColumnWidths: {
                        'description': '500px'
                    },
                    customBodyComponents: {
                        'proposalStatus': (text:string, element:IMyProposalsTableData) => <ShowProposalStatus text={text} element={element}/>,
                        absenceStartDate : ( date : Date , element:IMyProposalsTableData) => <span className={"span-date"} >{DateService.stringifyDate(date)}</span>,
                        absenceFinishDate : ( date : Date , element:IMyProposalsTableData) => <span className={"span-date"} >{DateService.stringifyDate(date)}</span>
                    },
                    customComparators: {
                        absenceStartDate: generateCustomDateComparator<IMyProposalsTableData>('absenceStartDate'),
                        absenceFinishDate: generateCustomDateComparator<IMyProposalsTableData>('absenceFinishDate')
                    },
                    onElementClick,
                    onHoverComponent: (element:IMyProposalsTableData) => <ShowMyProposalsHoverIcons element={element}/>
                }}
            />
        </div>
    );
}

export default MyProposalsTable;
