import MyProposalTopBarView from "./MyProposalTopBarView/MyProposalTopBarView";
import React, {useEffect, useState} from "react";
import Proposal from "../../../Types/data/Proposal";
import FilteringService from "../../../Services/FilteringService";
import {useSelector} from "react-redux";
import RootState from "../../../Types/states/RootState";
import MyProposalEmployeeList from "./MyProposalEmployeeList/MyProposalEmployeeList";
import {api} from "../../../ReduxStore/api/api";
import {ProposalFilteringOptionsEnum} from "../../../Enums/ProposalFilteringOptionsEnum";
import noAbsencesIcon from "../../../assets/icons/no_absences-icon.svg";

const MobileMyProposalBlockTab = () => {
    const loggedInUserID = useSelector((state: RootState) => state.user.loggedInUserID);
    const [status, setStatus] = useState(ProposalFilteringOptionsEnum.ALL);

    const {data, isLoading, isFetching} = api.useGetProposalsWithParamsQuery({
        employee: [loggedInUserID]
    })

    const [currentProposals, setCurrentProposals] = useState<Proposal[]>([]);
    const [pastProposals, setPastFilteredProposalListByStatus] = useState<Proposal[]>([]);


    useEffect(() => {
        filterList()
    }, [data,status]);

    const filterList = () => {

        if (!data) return;

        const proposalStatus = Object.keys(ProposalFilteringOptionsEnum).find(key => ProposalFilteringOptionsEnum[key] === status);

        switch (status) {
            case ProposalFilteringOptionsEnum.ACCEPTED:
            case ProposalFilteringOptionsEnum.DECLINED:
            case ProposalFilteringOptionsEnum.IN_PROGRESS:
                if (data) {
                    const current = FilteringService.getCurrentProposals(data)
                        .filter(x => Object(x).proposalStatus === proposalStatus)
                        .sort((a, b) => Object(a).absenceStartDate >= Object(b).absenceStartDate ? 1 : -1)
                    const past = FilteringService.getPastProposals(data)
                        .filter(x => Object(x).proposalStatus === proposalStatus)
                        .sort((a, b) => Object(a).absenceFinishDate <= Object(b).absenceFinishDate ? 1 : -1)
                    setCurrentProposals(current)
                    setPastFilteredProposalListByStatus(past)
                }
                break;
            default:
                if (data) {
                    const current = FilteringService.getCurrentProposals(data)
                        .sort((a, b) => Object(a).absenceStartDate >= Object(b).absenceStartDate ? 1 : -1)
                    const past = FilteringService.getPastProposals(data)
                        .sort((a, b) => Object(a).absenceFinishDate <= Object(b).absenceFinishDate ? 1 : -1)
                    setCurrentProposals(current)
                    setPastFilteredProposalListByStatus(past)
                }
        }

    }

    if (!data || data.length === 0 || isLoading || isFetching) {
        return (
            <div className="text-center mt-3">
                <img className="mt-5 mb-4" src={noAbsencesIcon} alt=""/>
                <h5 className="mt-2 no-absences">Nie ma aktywnych wniosków</h5>
            </div>
        )
    }

    return (
        <>
            <MyProposalTopBarView setStatus={setStatus} />
            <div className="mt-2" key={1} >
                <h6 className="ml-4 mb-3">Zaplanowane</h6>
                <MyProposalEmployeeList list={currentProposals} isHistoryBlock={false}/>
            </div>
            <div className="mt-4 history-block" key={2} >
                <h6 className="ml-4 mb-3">Historia</h6>
                <MyProposalEmployeeList list={pastProposals} isHistoryBlock={true}/>
            </div>
        </>
    )
}

export default MobileMyProposalBlockTab