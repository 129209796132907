import UserState from "../../Types/states/UserState";

const userInitialState:UserState = {
    role: 'employee',
    loggedInUser: '',
    loggedInUserID: -1,
    email : "",
    family_name : "",
    given_name : ""
};

export default userInitialState;
