import React, {FunctionComponent} from "react";
import { useDispatch } from "react-redux";
import UseModal from "../../../Services/CustomHooks/useModal";
import {IAbsencesFilteredTableData} from "../AbsencesFilteredTable";
import reviewIcon from "../../../assets/icons/review-icon.svg";
import {CurrentModalNameEnum} from "../../../Enums/CurrentModalNameEnum";
import {setCurrentOpenAbsence} from "../../../ReduxStore/reduxSlices/ModalPopupSlice";

interface IShowAbsencesHoverIconsProps {
    element: IAbsencesFilteredTableData;
}

const ShowAbsencesHoverIcons:FunctionComponent<IShowAbsencesHoverIconsProps> = (props:IShowAbsencesHoverIconsProps) => {
    const absence = props.element._absence;
    const dispatch = useDispatch();
    const {changeModalHistory} = UseModal()

    return (
        <div className="proposals-hover">
            <button className="review-span btn p-0 mb-0" onClick={ (e) => {
                changeModalHistory(CurrentModalNameEnum.REVIEW_ABSENCE);
                dispatch(setCurrentOpenAbsence(absence))
            }}>
                <img src={reviewIcon} alt=""/>
            </button>
        </div>
    );
};

export default ShowAbsencesHoverIcons;
