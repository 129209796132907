import React, {FunctionComponent} from "react";
import AbsenceTypeEnum from "../../../Enums/AbsenceTypeEnum";
import AbsenceTypeFormatterService from "../../../Services/AbsenceTypeFormatterService";
import styles from "./AbsenceType.module.scss"

interface IAbsenceTypeProps {
    type: AbsenceTypeEnum
}


const AbsenceType: FunctionComponent<IAbsenceTypeProps> = (props: IAbsenceTypeProps) => {
    const {showStatus} = AbsenceTypeFormatterService();

    return (
        <div className={styles.absence_type}>
            <p className={styles.modal_heading}>Rodzaj nieobecności:</p>
            <p className={styles.type}>{showStatus(props.type)}</p>
        </div>
    )
}
export default AbsenceType;