import React, {FC, useEffect} from 'react';
import AbsenceTypeEnum from '../../../../../../../Enums/AbsenceTypeEnum';
import {FormikProps} from "formik";
import ErrorAlert from "../ErrorMessage/ErrorAlert";
import RenderAbsenceTypes from "./RenderAbsenceTypes/RenderAbsenceTypes";
import RenderHolidayTypes from "./RenderHolidayTypes/RenderHolidayTypes";
import { CreateProposalFormik } from 'src/Types/states/modal/CreateProposalFormik';
import styles from "./AbsenceStatus.module.scss"
import classNames from "classnames";

interface AbsenceStatusProps {
    formik : FormikProps<CreateProposalFormik & { role: string }>,
    isMobile : boolean,
}

const AbsenceStatus : FC<AbsenceStatusProps> = (props) => {

    const {formik,isMobile} = props;

    useEffect(() => {
        if (formik.values.absenceType !== AbsenceTypeEnum.HOLIDAY && formik.values.holidayType) {
            formik.setFieldValue("holidayType", null)
        }
    }, [formik.values.absenceType])

    return (
        <>
            <div className={classNames({
                [styles.absence_types] : !isMobile,
                [styles.absence_types_mobile] : isMobile
            })}
            >
                <RenderAbsenceTypes  formik={formik} isMobile={isMobile} />
            </div>
            <div className={styles.absence_error} >
                {(formik.errors.absenceType && formik.touched.absenceType) ?
                    <ErrorAlert>{formik.errors.absenceType}</ErrorAlert> : null}
            </div>
            <RenderHolidayTypes formik={formik} isMobile={isMobile} />
        </>
    )
}

export default AbsenceStatus;
