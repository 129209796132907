import {MonthsNameEnum} from "../../Enums/MonthsNameEnum";
import {Dispatch, FC, SetStateAction, useEffect} from "react";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import "./MonthChanger.scss";
import {MonthYearDate} from "../../Types/MonthYearDate";
import {useDispatch, useSelector} from "react-redux";
import RootState from "src/Types/states/RootState";
import classNames from 'classnames';
import {ContentResetSelectedMonthAndYear} from "../../ReduxStore/reduxSlices/contentSlice";

interface MonthChangerProps {
    currentDate: MonthYearDate,
    changeToPrevMonth: (e: any) => void,
    changeToNextMonth: (e: any) => void
}

const MonthChanger : FC<MonthChangerProps> = (props) => {
    const {
        currentDate,
        changeToPrevMonth,
        changeToNextMonth
    } = props;
    const dispatch = useDispatch();
    const selectedMonth = useSelector((state: RootState) => state.content.selectedMonth);
    const selectedYear = useSelector((state: RootState) => state.content.selectedYear);

    const getMonthName = (): MonthsNameEnum => Object.values(MonthsNameEnum)[currentDate.month - 1];

    const displayCurrentMonth = (): void => {
        if (!isCurrentMonth) {
            dispatch(ContentResetSelectedMonthAndYear())
        }
    }

    const isCurrentMonth = (new Date()).getMonth() + 1 === selectedMonth && (new Date()).getFullYear() === selectedYear;

    return (
        <div className="d-flex month-changer">
            <button
                className={classNames({
                    ['today-button']: true,
                    ['black-button']: isCurrentMonth
                })}
                onClick={displayCurrentMonth}
            >
                <p>Dziś</p>
            </button>

            <div className="current-month">
                <button className="prev" onClick={changeToPrevMonth}>
                    <NavigateBeforeIcon className="font-weight-bold"/>
                </button>
                <div className="w-100 month-name d-flex justify-content-center">
                    <p className="mb-0">{getMonthName()}</p> &nbsp;
                    <p className="mb-0 current-year">{currentDate.year}</p>
                </div>
                <button className="next" onClick={changeToNextMonth}>
                    <NavigateNextIcon className="font-weight-bold"/>
                </button>
            </div>
        </div>
    )
}
export default MonthChanger;