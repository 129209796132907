import {createSlice} from "@reduxjs/toolkit";
import { RoleEnum } from "src/Enums/RoleEnum";
import userInitialState from "../initialStates/userInitialState";

const userSlice = createSlice({
    name: 'user',
    initialState: userInitialState,
    reducers: {
        changeUserRole(state) {
            if (process.env.NODE_ENV !== 'production') {
                state.role = state.role === RoleEnum.EMPLOYEE ? RoleEnum.EMPLOYER : RoleEnum.EMPLOYEE
            }
        },
        setUserRole(state,action){
            if(action.payload.role === RoleEnum.EMPLOYEE || action.payload.role === RoleEnum.EMPLOYER){
                state.role = action.payload.role
            }
        },
        setLoggedUser(state, action) {
            state.loggedInUser = action.payload.user
        },
        setLoggedIDUser(state, action) {
            state.loggedInUserID = action.payload.id
        },
        setUserEmail(state, action) {
            state.email = action.payload.email;
        },
        setUserFamilyName(state, action) {
            state.family_name = action.payload.family_name;
        },
        setUserGivenName(state, action) {
            state.given_name = action.payload.given_name;
        }
    },
})


export const {
    changeUserRole,
    setUserRole,
    setLoggedUser,
    setLoggedIDUser,
    setUserEmail,
    setUserFamilyName,
    setUserGivenName
} = userSlice.actions

export default userSlice.reducer