import DesktopModalHeader from "../../../ModalComponents/ModalHeader/DesktopModalHeader";
import {ModalHeadersEnum} from "../../../../../Enums/ModalHeadersEnum";
import ProposalStatus from "../../../../../Shared/ProposalStatus/ProposalStatus";
import AbsenceType from "../../../../../Shared/Partials/AbsenceType/AbsenceType";
import HolidayAbsenceType from "../../../../../Shared/Partials/HolidayAbsenceType/HolidayAbsenceType";
import ReplacementPersonView from "../../../../../Shared/Partials/ReplacementPersonView/ReplacementPersonView";
import Description from "../../../../../Shared/Partials/Description/Description";
import ProposalStatusEnum from "../../../../../Enums/ProposalStatusEnum";
import {CurrentModalNameEnum} from "../../../../../Enums/CurrentModalNameEnum";
import deleteIcon from "../../../../../assets/icons/delete-red-icon.svg";
import editIcon from "../../../../../assets/icons/edit-icon.svg";
import React, {useEffect, useState} from "react";
import UseModal from "../../../../../Services/CustomHooks/useModal";
import {useSelector} from "react-redux";
import RootState from "../../../../../Types/states/RootState";
import {DesktopModalHeaderHelper} from "../../../ModalComponents/ModalHeader/DesktopModalHeaderHelper";
import styles from "./ReviewCreatedProposalModal.module.scss"
import EmployeeData from "../../../../../Shared/Partials/EmployeeData/EmployeeData";
import AbsenceDateRange from "../../../../../Shared/Partials/AbsenceDateRange/AbsenceDateRange";
import EmployeeProjects from "../../../../../Shared/Partials/EmployeeProjects/EmployeeProjects";
import {Button} from "@devapo/devapo-ui";
import ActionMadeBy from "../../../../../Shared/Partials/ActionMadeBy/ActionMadeBy";
import DeclinedReason from "../../../../../Shared/Partials/DeclinedReason/DeclinedReason";

const ReviewCreatedProposalModal = () => {
    const {backToPrevModal, changeModalHistory} = UseModal();
    const {currentProposal, isHistoryBlock} = useSelector((state: RootState) => state.modalPopup)
    const [header, setHeader] = useState<ModalHeadersEnum | undefined>()

    useEffect(() => {
        DesktopModalHeaderHelper.getModalProposalHeaderEnum(setHeader, currentProposal)
    }, [currentProposal])

    return (
        <div className={styles.modal_block}>

            <DesktopModalHeader
                header={header}
                startDate={currentProposal.absenceStartDate}
                endDate={currentProposal.absenceFinishDate}
                absenceType={currentProposal.absenceType}
            >
                <ProposalStatus proposalStatus={currentProposal.proposalStatus}/>
            </DesktopModalHeader>

            <form>
                {
                    currentProposal.proposalStatus === ProposalStatusEnum.DECLINED && (
                        <>
                            <ActionMadeBy
                                proposalStatus={currentProposal.proposalStatus}
                                madeBy={currentProposal.approver}
                                actionDate={currentProposal.decisionDate}
                            />
                            <DeclinedReason proposalStatus={currentProposal.proposalStatus}
                                            declinedReason={currentProposal.declinedReason}/>
                            <hr/>
                        </>
                    )
                }
                <EmployeeData employee={currentProposal.employee}/>
                <AbsenceType type={currentProposal.absenceType}/>
                <HolidayAbsenceType type={currentProposal.holidayType} absence={currentProposal.absenceType}/>
                <AbsenceDateRange
                    startDate={currentProposal.absenceStartDate}
                    endDate={currentProposal.absenceFinishDate}
                />
                <Description description={currentProposal.description}/>
                <EmployeeProjects proposal={currentProposal}/>
                <ReplacementPersonView replacement={currentProposal.replacement}/>
            </form>

            <div className={styles.footer}>
                {
                    (!isHistoryBlock && currentProposal.proposalStatus === ProposalStatusEnum.IN_PROGRESS) && (
                        <>
                            <Button
                                text={"Odwołaj"}
                                icon={<img src={deleteIcon} alt=""/>}
                                onClick={() => changeModalHistory(CurrentModalNameEnum.CANCEL_PROPOSAL)}
                                type={"button"}
                                color={"warning"}
                                className={styles.cancel_button}
                                withBackground={false}
                                withBorder={true}
                            />
                            <Button
                                text={"Edytuj"}
                                icon={<img src={editIcon} alt=""/>}
                                onClick={() => changeModalHistory(CurrentModalNameEnum.EDIT_PROPOSAL)}
                                type={"button"}
                                color={"normal"}
                            />
                        </>
                    )
                }
                <Button
                    text={"Zamknij"}
                    onClick={() => backToPrevModal()}
                    type={"button"}
                    color={"success"}
                    width={150}
                />
            </div>

        </div>
    )
}

export default ReviewCreatedProposalModal