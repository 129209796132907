import "./ShowProposalStatus.scss";
import { FunctionComponent } from "react";
import ProposalStatusEnum from "../../../Enums/ProposalStatusEnum";
import { IMyProposalsTableData } from "../MyProposalsTable";
import { ReactComponent as UiAcceptedIcon } from '../../../assets/icons/accepted-icon.svg';
import { ReactComponent as UiInProgressIcon } from '../../../assets/icons/in-progress-icon.svg';
import { ReactComponent as UiCancelledIcon } from '../../../assets/icons/cancelled-icon.svg';

interface IShowProposalStatusProps {
    text: string;
    element: IMyProposalsTableData;
}

const ShowProposalStatus:FunctionComponent<IShowProposalStatusProps> = (props:IShowProposalStatusProps) => {
    const status = props.element._proposal.proposalStatus;

    return (
        <div className="show-proposal-status">
            {status === ProposalStatusEnum.ACCEPTED && <UiAcceptedIcon/>}
            {status === ProposalStatusEnum.IN_PROGRESS && <UiInProgressIcon/>}
            {status === ProposalStatusEnum.DECLINED && <UiCancelledIcon/>}
        </div>
    );
};

export default ShowProposalStatus;
