import EmployeeService from "../../../../Services/EmployeeService";
import {CurrentModalNameEnum} from "../../../../Enums/CurrentModalNameEnum";
import deleteIcon from "../../../../assets/icons/delete-red-icon.svg";
import React, {useEffect, useState} from "react";
import EmployeeData from "../../../../Shared/Partials/EmployeeData/EmployeeData";
import AbsenceType from "../../../../Shared/Partials/AbsenceType/AbsenceType";
import HolidayAbsenceType from "../../../../Shared/Partials/HolidayAbsenceType/HolidayAbsenceType";
import AbsenceDateRange from "../../../../Shared/Partials/AbsenceDateRange/AbsenceDateRange";
import Description from "../../../../Shared/Partials/Description/Description";
import EmployeeProjects from "../../../../Shared/Partials/EmployeeProjects/EmployeeProjects";
import ReplacementPersonView from "../../../../Shared/Partials/ReplacementPersonView/ReplacementPersonView";
import {useSelector} from "react-redux";
import RootState from "../../../../Types/states/RootState";
import UseModal from "../../../../Services/CustomHooks/useModal";
import {ModalHeadersEnum} from "../../../../Enums/ModalHeadersEnum";
import {
    DesktopModalHeaderHelper
} from "../../../../DesktopComponents/Modals/ModalComponents/ModalHeader/DesktopModalHeaderHelper";
import MobileModalHeader from "../../../Shared/MobileModalHeader/MobileModalHeader";
import styles from "./MobileReviewAbsenceModal.module.scss"
import {Button} from "@devapo/devapo-ui";
import {RoleEnum} from "../../../../Enums/RoleEnum";

const MobileReviewAbsenceModal = () => {

    const role = useSelector((state: RootState) => state.user.role);
    const {currentAbsence} = useSelector((state: RootState) => state.modalPopup);
    const {backToPrevModal, changeModalHistory} = UseModal();
    const [header, setHeader] = useState<ModalHeadersEnum | undefined>()

    useEffect(() => {
        DesktopModalHeaderHelper.getModalAbsenceHeaderEnum(setHeader, currentAbsence)
    }, [currentAbsence])


    return (
        <div className={styles.modal_body}>

            <MobileModalHeader
                header={header}
                startDate={currentAbsence.startDate}
                endDate={currentAbsence.endDate}
            />

            <div>
                <EmployeeData employee={currentAbsence.employee}/>
                <AbsenceType type={currentAbsence.absenceType}/>
                <HolidayAbsenceType type={currentAbsence.holidayType} absence={currentAbsence.absenceType}/>
                <AbsenceDateRange
                    startDate={currentAbsence.startDate}
                    endDate={currentAbsence.endDate}
                />
                <Description description={currentAbsence.description}/>
                <EmployeeProjects proposal={currentAbsence}/>
                <ReplacementPersonView replacement={currentAbsence.replacement}/>
            </div>

            <div className={styles.footer}>
                {role === RoleEnum.EMPLOYER && (
                    <Button
                        text={"Odwołaj"}
                        icon={<img src={deleteIcon} alt=""/>}
                        onClick={() => changeModalHistory(CurrentModalNameEnum.MOBILE_DELETE_ABSENCE)}
                        type={"button"}
                        color={"warning"}
                        withBorder={true}
                        withBackground={false}
                    />
                )}

                <Button
                    text={"Zamknij"}
                    type={"button"}
                    onClick={backToPrevModal}
                    color={"success"}
                    width={150}
                />
            </div>

        </div>
    )
}

export default MobileReviewAbsenceModal