import React, {FunctionComponent} from "react";
import styles from "./Description.module.scss"

interface IDescriptionProps {
    description: string | undefined;
}

const Description: FunctionComponent<IDescriptionProps> = (props: IDescriptionProps) => {

    if (props.description) {
        return (
            <div className={styles.description_container}>
                <p className={styles.modal_heading} >Opis</p>
                <p className={styles.desc} >{props.description}</p>
            </div>
        )
    }

    return <></>
}
export default Description;