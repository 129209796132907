import {FunctionComponent} from "react";
import { useSelector } from "react-redux";
import PositionService from "src/Services/PositionService.ts";
import RootState from "src/Types/states/RootState";
import FilteringEngineService from "../../../Services/FilteringEngineService";
import styles from "./EmployeeData.module.scss"

interface IEmployeeDataProps {
    employee: number;
}

const EmployeeData: FunctionComponent<IEmployeeDataProps> = (props: IEmployeeDataProps) => {
    const employees = useSelector((state:RootState) => state.employees);
    const positions = useSelector((state:RootState) => state.positions);
    const {getEmployeeNameByID} = FilteringEngineService();
    const role = PositionService.getPositionById(positions, props.employee)?.name;

    if(props.employee){
        return (
            <div className={styles.employee_data}>
                <p className={styles.modal_heading} >Dane pracownika</p>
                <div>
                    <p className={styles.employee_name}>{getEmployeeNameByID(employees,props.employee)}</p>
                    <p className={styles.data_position}>{role}</p>
                </div>
            </div>
        )
    }

    return <></>
}
export default EmployeeData;