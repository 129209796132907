import AbsenceBlockTabEnum from "../../../Enums/AbsenceBlockTabEnum";
import {useSelector} from "react-redux";
import RootState from "../../../Types/states/RootState";
import React, {FC} from "react";
import FilteringService from "../../../Services/FilteringService";
import { api } from "src/ReduxStore/api/api";
import styles from "./bar.module.scss";
import classNames from "classnames";

interface MyProposalsBarProps {
    onClick : (absenceBlockTab:AbsenceBlockTabEnum) => void
}

const MyProposalsBar : FC<MyProposalsBarProps> = (props) => {

    const{onClick} = props
    const loggedInUserID = useSelector((state: RootState) => state.user.loggedInUserID);
    const selectedAbsenceBlockTab = useSelector((state:RootState) => state.content.selectedBlockTab);

    const { data : myProposals } = api.useGetProposalsWithParamsQuery({
        employee : [loggedInUserID],
    })

    const myCurrentProposals = myProposals ? FilteringService.getCurrentProposals(myProposals) : [];

    const handleClick = () => onClick(AbsenceBlockTabEnum.MY_PROPOSALS)

    return (
        <div className={styles.bar_option} >
            <button
                className={classNames({
                    [styles.option] : true,
                    [styles.selected] : selectedAbsenceBlockTab === AbsenceBlockTabEnum.MY_PROPOSALS
                })}
                onClick={handleClick} >
                Moje wnioski
            </button>
            <div className={styles.number} >{myCurrentProposals ? myCurrentProposals.length : 0}</div>
        </div>
    )
}

export default MyProposalsBar