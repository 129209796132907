import React, {useEffect, useState} from "react";
import noAbsencesIcon from "../../../assets/icons/no_absences-icon.svg";
import MobileActionsHistoryTable from "./MobileActionsHistoryTable/MobileActionsHistoryTable";
import Proposal from "../../../Types/data/Proposal";
import ProposalStatusEnum from "../../../Enums/ProposalStatusEnum";
import MobileActionsHistoryTopBar from "./MobileActionsHistoryTopBar/MobileActionsHistoryTopBar";
import {api} from "../../../ReduxStore/api/api";
import {ProposalFilteringOptionsEnum} from "../../../Enums/ProposalFilteringOptionsEnum";
import Employee from "../../../Types/data/Employee";
import { useSelector } from "react-redux";
import RootState from "../../../Types/states/RootState";

const MobileActionsHistoryBlockTab = () => {

    const [actionsHistoryList, setActionsHistory] = useState<Proposal[]>([])
    const [status, setStatus] = useState(ProposalFilteringOptionsEnum.ALL);
    const employees = useSelector((state: RootState) => state.employees);

    const { data, isLoading, isFetching } = api.useGetProposalsWithParamsQuery({
        proposalStatus : [ProposalStatusEnum.ACCEPTED,ProposalStatusEnum.DECLINED],
        employee: employees.map((employee : Employee) => employee.id),
    })

    useEffect(() => {
        setFilteredActionsHistoryList()
    },[status,data])

    const setFilteredActionsHistoryList = () => {

        if(!data || data.length === 0) return;

        switch(status) {
            case ProposalFilteringOptionsEnum.ALL :
                const list = [...data]
                    .sort((a,b) => Object(a).decisionDate <= Object(b).decisionDate ? 1 : -1)
                setActionsHistory(list);
                return;
            case ProposalFilteringOptionsEnum.ACCEPTED :
                const acceptedStatusList = data
                    .filter(proposal => proposal.proposalStatus === ProposalStatusEnum.ACCEPTED)
                    .sort((a,b) => Object(a).decisionDate <= Object(b).decisionDate ? 1 : -1)
                setActionsHistory(acceptedStatusList);
                return;
            case ProposalFilteringOptionsEnum.DECLINED :
                const declinedStatusList = data
                    .filter(proposal => proposal.proposalStatus === ProposalStatusEnum.DECLINED)
                    .sort((a,b) => Object(a).decisionDate <= Object(b).decisionDate ? 1 : -1)
                setActionsHistory(declinedStatusList);
                return;
        }
    }

    return (
        <>
            <MobileActionsHistoryTopBar setStatus={setStatus} status={status} />
            { !data || isLoading || isFetching ? (
                <div className="text-center mt-3">
                    <img className="mt-5 mb-4" src={noAbsencesIcon} alt=""/>
                    <h5 className="mt-2 no-absences">Nie ma nieobecności</h5>
                </div>
            ) : (
                <>
                    <MobileActionsHistoryTable list={actionsHistoryList}/>
                </>

            )}
        </>
)
}

export default MobileActionsHistoryBlockTab