import Position from "src/Types/data/Position";
import Project from "src/Types/data/Project";
import SearchFilter from "src/Types/SearchFilter";
import {FilteringEngineEnum} from "../Enums/FilteringEngineEnum";
import Employee from "../Types/data/Employee";
import EmployeeService from "./EmployeeService";
import PositionService from "./PositionService.ts";
import ProjectService from "./ProjectService";

const FilteringEngineService = () => {
    const {getEmployeeByName} = EmployeeService();

    const checkIfInputEqualItem = (inputValue: string, item: string): boolean => {
        return item.toLowerCase() === inputValue.toLowerCase();
    }

    const checkIfListIncludesItem = (list: string[], item: string): boolean => {
        return list.includes(item)
    }

    const getFirstItemFromLists = (employees: string[], positions: string[], projects: string[], option: string): string | false => {
        switch (option) {
            case FilteringEngineEnum.ALL :
                if (employees.length > 0) {
                    return employees[0];
                }
                if (positions.length > 0) {
                    return positions[0];
                }
                if (projects.length > 0) {
                    return projects[0];
                }
                return false;
            case FilteringEngineEnum.EMPLOYEES :
                return employees.length > 0 ? employees[0] : false;
            case FilteringEngineEnum.POSITIONS:
                return positions.length > 0 ? positions[0] : false
            case FilteringEngineEnum.PROJECTS :
                return projects.length > 0 ? projects[0] : false
            default :
                return false;
        }
    }
    const checkIfListIsEmpty = (employees: Employee[], position: string[], project: string[], option: string): boolean => {
        switch (option) {
            case FilteringEngineEnum.EMPLOYEES :
                return employees.length > 0
            case FilteringEngineEnum.POSITIONS:
                return position.length > 0
            case FilteringEngineEnum.PROJECTS :
                return project.length > 0
        }
        return false;
    }

    const getItemName = (firstItem: string | false): string => {
        if (typeof firstItem === "string") {
            return firstItem;
        }
        return "";
    }

    const getFirstItemName = (employees: string[], positions: string[], projects: string[], option: string): string => {
        let firstItem = getFirstItemFromLists(
            employees,
            positions,
            projects,
            option
        )
        return getItemName(firstItem)
    }

    const filterListByRegExp = (list: string[], regExp): string[] => {
        return list.filter((item: string) => {
            const name = getItemName(item).toLowerCase()
            if (name.match(regExp)) {
                return item;
            }
        })
    }

    const getEmployeeNameByID = (employeeList:Employee[], listID: number | null): string => {
        let employeeName: string = "";
        employeeList.forEach((employee: Employee) => {
            if (employee.id === listID) {
                employeeName = [employee.lastName,employee.firstName].join(" ");
            }
        })
        return employeeName;
    }

    const getEmployeeIdsFromSearchFilterArray = (employees:Employee[], array:SearchFilter[]):number[] => {
        const employeeIds:number[] = [];

        array.forEach((filter:SearchFilter) => {
            if (filter.categoryName === 'Pracownicy') {
                const id = getEmployeeByName(employees, filter.selectedOption)?.id;

                if (id) {
                    employeeIds.push(id)
                }
            }
        });

        return employeeIds;
    };

    const getProjectIdsFromSearchFilterArray = (projects:Project[], array:SearchFilter[]):number[] => {
        const projectIds:number[] = [];

        array.forEach((filter:SearchFilter) => {
            if (filter.categoryName === 'Projekty') {
                const id = ProjectService.getProjectIdByName(projects, filter.selectedOption);

                if (id) {
                    projectIds.push(id)
                }
            }
        });

        return projectIds;
    };

    const getEmployeeProjects = (projectIds : number[], projects : Project[]) : Project[] => {

        return projects.filter(pro => {
            return projectIds.includes(pro.id)
        })

    }

    const getEmployeePositions = (positionsIds : number[], positions : Position[]) : Position[] => {

        return positions.filter(pos => {
            return positionsIds.includes(pos.id)
        })

    }

    const getPositionIdsFromSearchFilterArray = (positions:Position[], array:SearchFilter[]):number[] => {
        const positionIds:number[] = [];

        array.forEach((filter:SearchFilter) => {
            if (filter.categoryName === 'Role') {
                const id = PositionService.getPositionIdByName(positions, filter.selectedOption);

                if (id) {
                    positionIds.push(id)
                }
            }
        });

        return positionIds;
    };

    return {
        checkIfInputEqualItem,
        checkIfListIncludesItem,
        getFirstItemFromLists,
        getItemName,
        filterListByRegExp,
        getFirstItemName,
        checkIfListIsEmpty,
        getEmployeeNameByID,
        getEmployeeIdsFromSearchFilterArray,
        getProjectIdsFromSearchFilterArray,
        getPositionIdsFromSearchFilterArray,
        getEmployeeProjects,
        getEmployeePositions
    }

}


export default FilteringEngineService;