import noResultIcon from "../../assets/icons/no_result_icon.svg";
import Employee from "../../Types/data/Employee";
import {useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import ClearIcon from "@material-ui/icons/Clear";
import CalendarDays from "./CalendarComponents/CalendarDays";
import {calendarActions} from "./CalendarActions/calendarActions";
import FilteringEngineService from "../../Services/FilteringEngineService";
import CalendarUsers from "./CalendarComponents/CalendarUsers";
import RootState from "../../Types/states/RootState";
import "./Calendar.scss";
import AbsenceTypes from "./AbsenceTypes/AbsenceTypes";
import CalendarAbsencesRow from './CalendarComponents/CalendarAbsencesRow'

const Calendar = () => {

    const {
        getEmployeeIdsFromSearchFilterArray,
        getProjectIdsFromSearchFilterArray,
        getPositionIdsFromSearchFilterArray
    } = FilteringEngineService();

    const [daysMonthArray, setDaysMonthArray] = useState<Date[]>([]);

    const employeeList = useSelector((state:RootState) => state.employees);
    const [employees, setEmployees] = useState<Employee[]>(employeeList);

    const loggedInUser = useSelector((state:RootState) => state.user.loggedInUser);

    const positions = useSelector((state:RootState) => state.positions);
    const projects = useSelector((state:RootState) => state.projects);

    const chosenOptions = useSelector((state:RootState) => state.filteringEngine.selectedFilters);

    const selectedMonth = useSelector((state:RootState) => state.content.selectedMonth);
    const selectedYear = useSelector((state:RootState) => state.content.selectedYear);
    const loggedInUserId = useSelector((state:RootState) => state.user.loggedInUserID);

    useEffect(() => {
        setEmployees(calendarActions.firstInTableUser(employees,loggedInUser))
    }, [loggedInUser]);

    useEffect(() => {
        const chosenEmployeeIds = getEmployeeIdsFromSearchFilterArray(employeeList, chosenOptions);
        const chosenPositionIds = getPositionIdsFromSearchFilterArray(positions, chosenOptions);
        const chosenProjectIds = getProjectIdsFromSearchFilterArray(projects, chosenOptions);
        const newEmployeeList = calendarActions.filterEmployeeListByChosenOptions(
            chosenOptions, employeeList, chosenEmployeeIds, chosenPositionIds, chosenProjectIds,loggedInUserId
        )
        setEmployees(newEmployeeList)
    }, [employeeList, chosenOptions, positions, projects, selectedMonth, selectedYear, loggedInUserId]);


    useEffect(() => {
        setDaysMonthArray(calendarActions.getDaysMonth(selectedMonth,selectedYear))
    },[selectedYear,selectedMonth])

    if(employees.length === 0){
        return (
            <div className="text-center">
                <img src={noResultIcon} alt="no_result" className="mt-4"/>
                <h5 className="mt-2">Brak wyników</h5>
                <div className="clear-filters">
                    <ClearIcon className="mt-1 mr-2"/>
                    <p className="clear mr-2">wyczyść filtry</p>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="calendar-container">
                <div
                    className="calendar-box"
                    onScroll={() => calendarActions.deleteGreenColumn()}
                >
                    <div className="render-calendar">
                        <CalendarUsers  employees={employees} />
                        <div className='calendar-month' >
                            <CalendarDays position={'head'}  daysMonthArray={daysMonthArray}  />
                            <div className='calendar-proposals' >
                                <CalendarAbsencesRow employees={employees} daysMonthArray={daysMonthArray} />
                            </div>
                            <CalendarDays position={'foot'}  daysMonthArray={daysMonthArray}  />
                        </div>
                        <div
                            className="calendar-greenColumn-vertical"
                        />
                    </div>
                </div>
            </div>

            <AbsenceTypes/>
        </>

    )
}

export default Calendar;