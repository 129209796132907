import "./UiTableBody.scss";
import React, { FunctionComponent, ReactElement } from "react";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { IUiTableConfig } from "./UiTable";

interface IUiTableBodyProps<T> {
    data: T[];
    config?: IUiTableConfig<T>;
}

export default function UiTableBody<T>(props:IUiTableBodyProps<T>):ReactElement<IUiTableBodyProps<T>> {
    const {data, config} = props;

    const renderTableRow = (element:T, index:number) => {
        const hoverElement = config?.onHoverComponent;
        const objKeys = Object.keys(element);
        const hasPrivateField = !!objKeys.find((key:string) => key.startsWith('_'));
        const onTableRowClick = config?.onElementClick ? config?.onElementClick : () => {};

        return (
            <TableRow
                key={JSON.stringify(element) + index}
                className="ui-table-body-row"
                onClick={() => onTableRowClick(element)}
            >
                {objKeys.map((key:string) => {
                    return renderTableCell(element, index, objKeys, key, hoverElement, hasPrivateField);
                })}
            </TableRow>
        );
    }

    const renderTableCell = (
        element:T,
        index:number,
        objKeys:string[],
        key:string,
        hoverElement,
        hasPrivateField:boolean
    ) => {
        const customBodyComponents = config?.customBodyComponents;
        let customElement:FunctionComponent<any>|null = null;

        if (customBodyComponents && customBodyComponents[key]) {
            customElement = customBodyComponents[key];
        }

        if (key.startsWith('_'))  {
            return null;
        }

        const renderHoverElement = (
            !hasPrivateField && hoverElement && key === objKeys[objKeys.length - 1] ||
            hasPrivateField && hoverElement && key === objKeys[objKeys.length - 2]
        );

        return (
            <>
                <TableCell
                    key={JSON.stringify(element) + index + key}
                    className={customElement ? 'ui-table-body-custom-element' : 'ui-table-body-cell'}
                >
                    {
                        key === "description" && typeof key === "string" ? (
                            <p className="description"  >
                                {customElement ? customElement(
                                    element[key],
                                    element
                                ) : element[key]}
                            </p>
                        ) : (
                            <>
                                {customElement ? customElement(
                                    element[key],
                                    element
                                ) : element[key]}
                            </>
                        )
                    }
                </TableCell>
                {renderHoverElement &&
                <div
                    className={'ui-table-row-hover-container'}
                >
                    <div className={'ui-table-row-hover-component-container'}>
                        {hoverElement(element)}
                    </div>
                </div>
                }
            </>

        );
    }

    return (
        <TableBody>
            {data.map((element, index) => {
                return renderTableRow(element, index);
            })}
        </TableBody>
    );
};