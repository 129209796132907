import {createSlice, isPending} from "@reduxjs/toolkit";
import contentInitialState from "../initialStates/contentInitialState";

const contentSlice = createSlice({
    name: 'content',
    initialState: contentInitialState,
    reducers: {
        ContentSelectAbsenceBlockTab(state,action){
            state.selectedBlockTab = action.payload.selectedBlockTab
        },

        ContentResetSelectedMonthAndYear(state){
            const {selectedMonth, selectedYear} = contentInitialState;
            state.selectedMonth = selectedMonth;
            state.selectedYear = selectedYear;
        },
        ContentSetSelectedMonth(state,action){
            state.selectedMonth = action.payload.selectedMonth
        },

        ContentSetSelectedYear(state,action){
            state.selectedYear = action.payload.selectedYear
        },
    },
    extraReducers :  (builder ) => {

        builder.addMatcher( isPending, (state) => {} )

    }
})

export const {
    ContentResetSelectedMonthAndYear,
    ContentSelectAbsenceBlockTab,
    ContentSetSelectedMonth,
    ContentSetSelectedYear,
} = contentSlice.actions
export default contentSlice.reducer