import ProposalStatusEnum from "../../Enums/ProposalStatusEnum";
import React from "react";

const DeclinedMessage = ({proposalStatus, declinedReason}: {
    proposalStatus?: ProposalStatusEnum, declinedReason?: string
}) => {

    if (proposalStatus === ProposalStatusEnum.DECLINED && declinedReason) {
        return (<>
            <p className="declined-message">Odpowiedź: {declinedReason}</p>
        </>)
    }
    return <></>
}

export default DeclinedMessage