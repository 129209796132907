import { ReactElement } from "react";
import styles from "./TopBar.module.scss";
import { ReactComponent as Logo } from 'src/assets/images/logo-devapo-horizontal.svg';
import {useDispatch, useSelector} from "react-redux";
import RootState from "../../Types/states/RootState";
import {changeUserRole} from "../../ReduxStore/reduxSlices/userSlice";
import {RoleEnum} from "../../Enums/RoleEnum";

export default function TopBar():ReactElement {
    const dispatch = useDispatch()
    const role = useSelector((state:RootState) => state.user.role);

    return <>
        <div className={styles.topBar}>
            <Logo/>
            {
                process.env.NODE_ENV !== 'production' && (
                    <div>
                        <button className={'devapo-button' + ' ' + styles.devapoButton} onClick={() => dispatch(changeUserRole() )}>Zostań {role !== RoleEnum.EMPLOYER ? "pracodawcą" : "pracownikiem"}</button>
                    </div>
                )
            }

        </div>
    </>;
}
