import React from 'react'
import {useSelector} from "react-redux";
import RootState from "../../../../Types/states/RootState";
import UseModal from "../../../../Services/CustomHooks/useModal";
import {CurrentModalNameEnum} from "../../../../Enums/CurrentModalNameEnum";
import ReviewCreatedProposalModal from "./ReviewCreatedProposalModal/ReviewCreatedProposalModal";
import {Modal} from "@devapo/devapo-ui"
import styles from "../../Modals.module.scss";


const ReviewCreatedProposal = () => {

    const {currentOpenModal}  = useSelector((state : RootState) => state.modalPopup)
    const {closeAll} = UseModal();

    return (
        <Modal
            isOpen={currentOpenModal === CurrentModalNameEnum.REVIEW_CREATED_PROPOSAL}
            onClose={() => closeAll()}
            modalClass={styles.modal}
            backdropClass={styles.backdrop}
        >
            <ReviewCreatedProposalModal />
        </Modal>
    )
}

export default ReviewCreatedProposal