import HolidayTypeEnum from "../../../Enums/HolidayTypeEnum";
import checkHolidayTypeEnum from "../../../helpers/checkHolidayTypeEnum";
import AbsenceTypeEnum from "../../../Enums/AbsenceTypeEnum";
import styles from "./HolidayAbsenceType.module.scss"

const HolidayAbsenceType = ({type, absence} : { type ?: HolidayTypeEnum, absence : AbsenceTypeEnum }) => {
    if(type && absence === AbsenceTypeEnum.HOLIDAY){
        return (
            <div className={styles.holiday_type}>
                <p className={styles.modal_heading}>Typ:</p>
                <p className={styles.type}>{checkHolidayTypeEnum(type)}</p>
            </div>
        )
    }
    return <></>
};

export default HolidayAbsenceType;