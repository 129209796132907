
export enum reduxSliceTypeEnum  {
    LOAD_ABSENCE = "absences/loadAbsence",
    ACCEPT_PROPOSAL = "proposals/acceptProposal",
    CANCEL_ABSENCE = "absences/cancelAbsence",
    CREATE_PROPOSAL = "proposals/createProposal",
    DECLINE_PROPOSAL = "proposals/declineProposal",
    GET_USER = "user/getUser",
    LOAD_CALENDAR_SORTED_AND_FILTERED_ABSENCES = "absences/loadCalendarSortedAndFilteredAbsences",
    LOAD_CALENDAR_SORTED_AND_FILTERED_PROPOSALS = "proposals/loadCalendarSortedAndFilteredProposals",
    LOAD_EMPLOYEES = "employees/loadEmployees",
    LOAD_FILTERED_CALENDAR_DATA = "content/loadFilteredCalendarData",
    LOAD_POSITIONS = "positions/loadPositions",
    LOAD_PROJECTS = "projects/loadProjects",
    LOAD_PROPOSALS = "proposals/loadProposals",
    LOAD_SORTED_AND_FILTERED_ABSENCES = "absences/loadSortedAndFilteredAbsences",
    UPDATE_PROPOSAL = "proposals/updateProposal",
    DELETE_PROPOSAL = "proposals/deleteProposal",
}