import * as Yup from "yup";
import AbsenceTypeEnum from "../../Enums/AbsenceTypeEnum";
import HolidayTypeEnum from "../../Enums/HolidayTypeEnum";
import {RoleEnum} from "../../Enums/RoleEnum";

const now = new Date();
export const proposalYupValidation = Yup.object({
    role : Yup.string().notRequired(),
    requester : Yup.number().nullable(true).required(),
    employee : Yup.number().required().typeError("Wybierz pracownika"),
    replacement : Yup.number().when(["role","absenceType"],(role,absenceType) => {

        if(absenceType === AbsenceTypeEnum.REMOTE){
            return Yup.number().nullable(true).notRequired()
        }

        if(absenceType === AbsenceTypeEnum.SICK_LEAVE && role !== RoleEnum.EMPLOYEE){
            return Yup.number().required().typeError("Wybierz pracownika na zastępstwo")
        }

        if(absenceType === AbsenceTypeEnum.SICK_LEAVE && role === RoleEnum.EMPLOYEE){
            return Yup.number().nullable(true).notRequired()
        }

        return Yup.number().required().typeError("Wybierz pracownika na zastępstwo")
    }),
    absenceType : Yup.string().oneOf(Object.values(AbsenceTypeEnum)).required().typeError("Wybierz rodzaj wniosku"),
    holidayType : Yup.string().oneOf(Object.values(HolidayTypeEnum)).when("absenceType", (absenceType) => {
        if(absenceType === AbsenceTypeEnum.HOLIDAY){
            return Yup.string().oneOf(Object.values(HolidayTypeEnum)).required().typeError("Wybierz typ urlopu")
        }

        return Yup.number().nullable(true).default(() => null).notRequired()
    }).typeError("wer"),
    absenceStartDate : Yup.date().min(new Date( now.getFullYear(), now.getMonth(), now.getDate() - 1 ),"Data nie może być przeszłą").required().typeError("Wybierz Daty"),
    absenceFinishDate : Yup.date().required().typeError("Wybierz Daty"),
    description : Yup.string().nullable(true).notRequired()

})