import React, {FC} from "react";
import UseModal from "../../../../../Services/CustomHooks/useModal";
import {FormikProps} from "formik";
import CreateProposalBody from "./CreateProposalBody/CreateProposalBody";
import {CreateProposalFormik} from "src/Types/states/modal/CreateProposalFormik";
import styles from "./CreateProposalModal.module.scss"
import classNames from "classnames";
import {Button} from "@devapo/devapo-ui";

interface CreateProposalModalProps {
    formik: FormikProps<CreateProposalFormik & { role: string }>,
    result?: unknown
}

const CreateProposalModal: FC<CreateProposalModalProps> = (props) => {

    const {formik, result} = props;
    const {backToPrevModal} = UseModal()

    return (
        <form className={styles.modal_block} onSubmit={formik.handleSubmit}>

            <h4 className={styles.header}>Dodawanie nieobecności</h4>

            <CreateProposalBody formik={formik} result={result}/>

            <div className={styles.footer}>
                <Button
                    text={"Anuluj"}
                    onClick={() => backToPrevModal()}
                    type={"button"}
                    color={"normal"}
                />
                <Button
                    text={"Dodaj nieobecność"}
                    type={"submit"}
                    color={"success"}
                    className={classNames({
                        [styles.disabled_submit]: !(formik.isValid && formik.dirty)
                    })}
                />
            </div>

        </form>
    )
}

export default CreateProposalModal