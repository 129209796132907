import Datepicker from "../../../Shared/Datepicker/Datepicker";
import noAbsencesIcon from "../../../assets/icons/no_absences-icon.svg";
import AbsencesFilteredTable from "../../Tables/AbsencesFilteredTable";
import React, {useEffect} from "react";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import RootState from "../../../Types/states/RootState";
import DateService from "../../../Services/DateService";
import DateFormatService from "../../../Services/DateFormatService";
import {setAbsenceTabDateFrom, setAbsenceTabDateTo} from "../../../ReduxStore/reduxSlices/table/absenceTabSlice";
import { api } from "src/ReduxStore/api/api";
import Employee from "../../../Types/data/Employee";

const AbsencesTab = () => {

    const absenceTabDateFrom = useSelector((state: RootState) => state.tables.absenceTabSlice.absenceTabDateFrom);
    const absenceTabDateTo = useSelector((state: RootState) => state.tables.absenceTabSlice.absenceTabDateTo);
    const employees = useSelector((state: RootState) => state.employees);

    const { data  } = api.useGetAbsencesWithParamsQuery({
        employee: employees.map((employee : Employee) => employee.id),
        startDate : DateService.stringifyDate(absenceTabDateFrom,true),
        endDate : DateService.stringifyDate(absenceTabDateTo,true)
    });

    const dispatch = useDispatch();
    const {toMediumDate} = DateFormatService();

    const formik = useFormik({
        initialValues: {
            absenceStartDate: absenceTabDateFrom,
            absenceFinishDate: absenceTabDateTo
        },
        onSubmit: values => {
        }
    })

    useEffect(() => {
        setDateTo(formik.values.absenceFinishDate)
        setDateFrom(formik.values.absenceStartDate)
    }, [formik.values.absenceFinishDate, formik.values.absenceStartDate])

    const setDateTo = (date: Date): void => {
        dispatch(setAbsenceTabDateTo({absenceTabDateTo: date}))
    };

    const setDateFrom = (date: Date): void => {
        dispatch(setAbsenceTabDateFrom({absenceTabDateFrom: date}))
    };

    return (
        <>
            <div className="chooseDate mb-4">
                <p className="mr-2 mb-4">Zakres dat</p>
                <Datepicker formik={formik} disablePast={false}/>
            </div>

            {
                !data || data.length === 0 ? (
                    <div className="text-center">
                        <img className="mt-4 mb-4" src={noAbsencesIcon} alt=""/>
                        <h5 className="mt-2 no-absences">Nie ma nieobecności w dniach</h5>
                        <p>
                            {absenceTabDateFrom && toMediumDate(absenceTabDateFrom)}-
                            {absenceTabDateTo && toMediumDate(absenceTabDateTo)}
                        </p>
                    </div>
                ) : (
                    <AbsencesFilteredTable absences={data} />
                )
            }

        </>
    )
}

export default AbsencesTab