import {createAsyncThunk} from "@reduxjs/toolkit";
import Proposal from "../../Types/data/Proposal";
import {reduxSliceTypeEnum} from "../reduxSliceTypeEnum/reduxSliceTypeEnum";

const loadCalendarSortedAndFilteredProposals = createAsyncThunk(reduxSliceTypeEnum.LOAD_CALENDAR_SORTED_AND_FILTERED_PROPOSALS, async ({proposals} : { proposals: Proposal[] }) => {
    const loadCalendarSortedAndFilteredProposals: Proposal[] = proposals;

    return {loadCalendarSortedAndFilteredProposals}
})

export default loadCalendarSortedAndFilteredProposals