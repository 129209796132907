import DataService from "../../Services/DataService";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {reduxSliceTypeEnum} from "../reduxSliceTypeEnum/reduxSliceTypeEnum";

const loadProjects = createAsyncThunk(reduxSliceTypeEnum.LOAD_PROJECTS, async () => {
    const projects = await DataService.getProjects();
    return {projects}
})

export default loadProjects;
