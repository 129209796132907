import React, {FC} from "react";
import { useDispatch, useSelector } from "react-redux";
import AbsenceBlockTabEnum from "../../Enums/AbsenceBlockTabEnum";
import RootState from "../../Types/states/RootState";
import {ContentSelectAbsenceBlockTab} from "../../ReduxStore/reduxSlices/contentSlice";
import AbsenceBar from "./Bars/AbsenceBar";
import InProgressProposalsBar from "./Bars/InProgressProposalsBar";
import MyProposalsBar from "./Bars/MyProposalsBar";
import ActionsHistoryBar from "./Bars/ActionsHistoryBar";
import styles from "./AbsenceNavigationBar.module.scss"
import {RoleEnum} from "../../Enums/RoleEnum";

interface AbsenceNavigationBarProps {
    isMobile : boolean
}

const AbsenceNavigationBar : FC<AbsenceNavigationBarProps> = ( props ) => {
    const {isMobile} = props;
    const role = useSelector((state:RootState) => state.user.role);
    const dispatch = useDispatch();

    const setCurrentView = (absenceBlockTab:AbsenceBlockTabEnum) => {
        dispatch(ContentSelectAbsenceBlockTab({selectedBlockTab : absenceBlockTab }))
    };

    return (
        <div className={styles.bar_options} >
            <AbsenceBar onClick={setCurrentView} isMobile={isMobile} />
            <div className={styles.vertical_divider} />

                {role === RoleEnum.EMPLOYER ? (
                    <>
                        <InProgressProposalsBar onClick={setCurrentView} isMobile={isMobile} />
                        <div className={styles.vertical_divider} />
                        <MyProposalsBar onClick={setCurrentView} />
                        <div className={styles.vertical_divider} />
                        <ActionsHistoryBar onClick={setCurrentView} />
                    </>
                ) : (
                    <MyProposalsBar onClick={setCurrentView} />
                )}

        </div>

    )
}
export default AbsenceNavigationBar;