import {CurrentModalNameEnum} from "../../../../Enums/CurrentModalNameEnum";
import {useSelector} from "react-redux";
import RootState from "../../../../Types/states/RootState";
import UseModal from "../../../../Services/CustomHooks/useModal";
import MobileActionsHistoryReviewProposalModal
    from "./MobileActionsHistoryReviewProposalModal/MobileActionsHistoryReviewProposalModal";
import styles from "../../Modals.module.scss";
import {Modal} from "@devapo/devapo-ui";

const MobileActionsHistoryReviewProposal = () => {
    const {currentOpenModal}  = useSelector((state : RootState) => state.modalPopup)
    const {closeAll} = UseModal();

    return (
        <Modal
            isOpen={currentOpenModal === CurrentModalNameEnum.MOBILE_ACTIONS_HISTORY_REVIEW_PROPOSAL}
            onClose={() => closeAll()}
            modalClass={styles.modal}
            backdropClass={styles.backdrop}
        >
            <MobileActionsHistoryReviewProposalModal />
        </Modal>
    )
}

export default MobileActionsHistoryReviewProposal