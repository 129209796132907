import React, {useEffect, useRef} from "react";
import './FilteringEngine.scss';
import enterArrow from '../../../assets/icons/enter-arrow-icon.svg';
import FilterInput from "./FilterInput/FilterInput";
import {batch, useDispatch, useSelector} from "react-redux";
import RootState from "../../../Types/states/RootState";
import SearchFilter from "../../../Types/SearchFilter";
import Position from "src/Types/data/Position";
import Project from "src/Types/data/Project";
import OptionsDropDown from "./OptionsDropDown/OptionsDropDown";
import FilterDropdownMenu from "./RenderFilterDropdownMenu/RenderFilterDropdownMenu";
import AppliedFilters from "./AppliedFilters/AppliedFilters";
import {filterHelper} from "./FilterHelper/FilterHelper";
import ClearTags from "./ClearTags/ClearTags";
import {
    addSelectedFilter,
    setBackgroundInputValue,
    setEmployeesFilter, setInputOpen, setInputValue,
    setPositionFilter,
    setProjectFilter
} from "../../../ReduxStore/reduxSlices/filteringEngineSlice";

export interface FilterRequest {
    categoryName: string;
    selectedOption: string;
}

const FilteringEngine = () => {

    const employees = useSelector((state: RootState) => state.employees);
    const positions = useSelector((state: RootState) => state.positions);
    const projects = useSelector((state: RootState) => state.projects);

    const selectedOption = useSelector((state: RootState) => state.filteringEngine.selectedOption);
    const backgroundType = useSelector((state: RootState) => state.filteringEngine.backgroundType);
    const backgroundInputValue = useSelector((state: RootState) => state.filteringEngine.backgroundInputValue);
    const positionFilter = useSelector((state: RootState) => state.filteringEngine.positionFilter);
    const projectFilter = useSelector((state: RootState) => state.filteringEngine.projectFilter);
    const employeesFilter = useSelector((state: RootState) => state.filteringEngine.employeesFilter);
    const positionList = positions.map((position: Position): string => position.name);
    const projectList = projects.map((project: Project): string => project.name);
    const selectedFilters = useSelector((state: RootState) => state.filteringEngine.selectedFilters);
    const chosenFilterOptionList: string[] = selectedFilters.map((option: SearchFilter) => option.selectedOption);

    const dispatch = useDispatch();
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        batch(() => {
            dispatch(setEmployeesFilter({employeesFilter : employees }))
            dispatch(setPositionFilter({ positionFilter : positionList }))
            dispatch(setProjectFilter({ projectFilter : projectList }))
        })
    },[employees,positions,projects])

    useEffect(() => {
        dispatch(setInputValue({ inputValue : "" }))
    }, [selectedOption])

    const setChosenFilterOptionState = (item ? : string): void => {

        dispatch(addSelectedFilter({
            selectedFilters: {
                selectedOption: getFilter(),
                categoryName: item ? item : backgroundType
            }
        }))

    }

    const applyFilter = (): void => {

        if (backgroundInputValue && !chosenFilterOptionList.includes(getFilter())) {
            setChosenFilterOptionState()
            batch(() => {
                dispatch(setInputValue({inputValue : ""}))
                dispatch(setInputOpen({inputOpen : false}))
                dispatch(setEmployeesFilter({employeesFilter : employees}))
                dispatch(setPositionFilter({positionFilter : positionList}))
                dispatch(setProjectFilter({projectFilter : projectList}))
                dispatch(setBackgroundInputValue({backgroundInputValue : ""}))
            })
        }
    }

    const getFilter = () => filterHelper.getFilter(backgroundType, positionFilter, projectFilter, employeesFilter, backgroundInputValue)

    return (
        <div className="filtering-engine">
            <div className="filtering-engine-area">
                <div className="d-flex">

                    <OptionsDropDown/>

                    <div className="input-form">
                        <FilterInput inputRef={inputRef} applyFilter={applyFilter}  />
                        <FilterDropdownMenu inputRef={inputRef} />
                    </div>

                    <button className="enter-arrow btn" onClick={() => applyFilter()}>
                        <img className="pr-1 pb-1" src={enterArrow} alt=""/>
                    </button>

                    <ClearTags/>

                </div>
            </div>
            {
                chosenFilterOptionList.length > 0 && <AppliedFilters/>
            }
        </div>
    )
}
export default FilteringEngine;
