import "./ShowAbsenceType.scss";
import { ReactElement } from "react";

interface IShowAbsenceTypeProps<T> {
    text: string;
    element: T;
}

export default function ShowAbsenceType<T>(props:IShowAbsenceTypeProps<T>):ReactElement<IShowAbsenceTypeProps<T>> {
    const {text, element} = props;

    let statusName = '';

    switch ((element as any).absenceType) {
        case 'Urlop':
            statusName = 'holiday';
            break;
        case 'Praca zdalna':
            statusName = 'remote';
            break;
        case 'L4':
            statusName = 'sick-leave';
            break;
    }

    return (
        <div className="show-absence-type">
            <div className={'show-absence-type-status' + ' show-absence-type-status-' + statusName}>
                {text}
            </div>
        </div>
    );
};
