import DataService from "../../Services/DataService";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {reduxSliceTypeEnum} from "../reduxSliceTypeEnum/reduxSliceTypeEnum";

const loadProposals = createAsyncThunk(reduxSliceTypeEnum.LOAD_PROPOSALS, async () => {
    const proposals = await DataService.getProposals();

    return {proposals}
})

export default loadProposals;
