import React, {FC, ReactElement} from "react";
import {ModalHeadersEnum} from "../../../../Enums/ModalHeadersEnum";
import DateFormatService from "../../../../Services/DateFormatService";
import AbsenceTypeEnum from "../../../../Enums/AbsenceTypeEnum";
import styles from "./DesktopModalHeader.module.scss"
import classNames from "classnames";

interface DesktopModalHeaderProps{
    header ?: ModalHeadersEnum,
    startDate : Date ,
    endDate :  Date,
    absenceType: AbsenceTypeEnum,
    children?: ReactElement
}

const DesktopModalHeader : FC<DesktopModalHeaderProps> = (props) => {

    const {header,startDate,endDate,absenceType,children} = props;
    const {toMediumDate} = DateFormatService()

    return (
        <div className={styles.header}>
            <div
                className={classNames({
                    [styles.vertical_line] : true,
                    [styles.HOLIDAY] : absenceType === AbsenceTypeEnum.HOLIDAY,
                    [styles.REMOTE] : absenceType === AbsenceTypeEnum.REMOTE,
                    [styles.SICK_LEAVE] : absenceType === AbsenceTypeEnum.SICK_LEAVE,
                })}
            />
            <div className={styles.header_container}>
                <h4 className={styles.tittle}>{header || "Podgląd"}</h4>
                <h6 className={styles.date}>
                    {toMediumDate(startDate)}
                    &nbsp;-&nbsp;
                    {toMediumDate(endDate)}
                </h6>
                {children}
            </div>
        </div>
    )
}

export default DesktopModalHeader