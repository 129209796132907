import DataService from "../../Services/DataService";
import loadAbsences from "./loadAbsences";
import loadProposals from "./loadProposals";
import { batch } from "react-redux";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {reduxSliceTypeEnum} from "../reduxSliceTypeEnum/reduxSliceTypeEnum";

const deleteProposal = createAsyncThunk(reduxSliceTypeEnum.DELETE_PROPOSAL, async ({proposalId} : {proposalId : number} , {dispatch}) => {
    await DataService.deleteProposal(proposalId);

    batch(() => {
        dispatch(loadProposals());
        dispatch(loadAbsences());
    });
})

export default deleteProposal;
