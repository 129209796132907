const BackgroundValue = (
    {inputValue, employeeBackgroundValue}:
        { inputValue: string | undefined, employeeBackgroundValue: string | null }
) => {


    return (
        <div className="input-form_div-flex">
            <div className="background-input">
                {employeeBackgroundValue && inputValue && employeeBackgroundValue.split("").map((item, index) => {
                    return (
                        <span
                            key={index}
                            className={`${index < inputValue.length ? "disappear" : "visibleItem"}`}
                        >
                            {index < inputValue.length ? inputValue.split("")[index] : item}
                        </span>
                    )
                })}
            </div>
        </div>
    )
}

export default BackgroundValue