import mobileContentInitialState from "../initialStates/mobileContentInitialState";
import {createSlice} from "@reduxjs/toolkit";

const mobileContentSlice = createSlice({
    name: "mobileContent",
    initialState: mobileContentInitialState,
    reducers: {
        MobileContentSelectAbsenceTopBarTab(state,action){
            state.absenceTopBarTab = action.payload.absenceTopBarTab
        },
        MobileContentSelectAbsenceTopBarMonth(state,action){
            state.absenceTopBarMonth = action.payload.absenceTopBarMonth
        },
        MobileContentSelectAbsenceTopBarYear(state,action){
            state.absenceTopBarYear = action.payload.absenceTopBarYear
        },
    }
})

export const {
    MobileContentSelectAbsenceTopBarTab,
    MobileContentSelectAbsenceTopBarMonth,
    MobileContentSelectAbsenceTopBarYear
} = mobileContentSlice.actions

export default mobileContentSlice.reducer;
