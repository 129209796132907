import React, {useEffect} from "react";
import {CurrentModalNameEnum} from "../../../../Enums/CurrentModalNameEnum";
import {useSelector} from "react-redux";
import RootState from "../../../../Types/states/RootState";
import UseModal from "../../../../Services/CustomHooks/useModal";
import {useFormik} from "formik";
import {withFormikDevtools} from "formik-devtools-extension";
import toast from "react-hot-toast";
import FilteringEngineService from "../../../../Services/FilteringEngineService";
import DateFormatService from "../../../../Services/DateFormatService";
import MobileDeclineProposalModal from "./MobileDeclineProposalModal/MobileDeclineProposalModal";
import {api} from "../../../../ReduxStore/api/api";
import {RtkQueryHelper} from "../../../../helpers/rtk-query.helper";
import styles from "../../Modals.module.scss";
import {Modal} from "@devapo/devapo-ui";

export const RenderMobileDeclineModal = () => {
    const {currentOpenModal,currentProposal} = useSelector((state : RootState) => state.modalPopup)
    const [declineProposal, result] = api.useDeclineProposalMutation()
    const employees = useSelector((state:RootState) => state.employees);
    const {closeAll} = UseModal()
    const {getEmployeeNameByID} = FilteringEngineService();
    const {toShortDate} = DateFormatService();

    const formik = useFormik({
        initialValues: {
            declinedReason : ""
        },
        onSubmit : values => {
            doDeclineProposal(values)
        }
    })

    useEffect(() => {
        process.env.NODE_ENV !== 'production' && withFormikDevtools(formik);
    },[])

    useEffect(() => {
        if(RtkQueryHelper.checkResultIsFulfilled(result)){
            closeAll();
            formik.resetForm()
            showErrorToast()
        }
    },[result])

    const showErrorToast = () => {
        toast.error(`Nieobecność dla ${getEmployeeNameByID(employees, currentProposal.employee)} w dn. 
        ${toShortDate(currentProposal.absenceStartDate).props.children.join('')} -
        ${toShortDate(currentProposal.absenceFinishDate).props.children.join('')} została odrzucona`);
    }

    const doDeclineProposal = (values) => {
        if(values.declinedReason.length !== 0){
            declineProposal({id : currentProposal.id, message : values.declinedReason});
        } else {
            declineProposal({id : currentProposal.id});
        }
    }

    return(
        <Modal
            isOpen={currentOpenModal === CurrentModalNameEnum.MOBILE_DECLINE_RECEIVED_PROPOSAL}
            onClose={() => closeAll()}
            modalClass={styles.modal}
            backdropClass={styles.backdrop}
        >
            <MobileDeclineProposalModal formik={formik} />
        </Modal>
    )
}