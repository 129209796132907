import AbsenceTypeEnum from "../Enums/AbsenceTypeEnum";

const AbsenceTypeFormatterService = () =>{

    const showStatus = (statusIndex: AbsenceTypeEnum):string => {
        switch (statusIndex) {
            case AbsenceTypeEnum.HOLIDAY:
                return 'Urlop';
            case AbsenceTypeEnum.REMOTE:
                return 'Praca zdalna';
            case AbsenceTypeEnum.SICK_LEAVE:
                return 'L4';
        }
    }

    const defineColorOfAbsenceStatus = (statusClass: AbsenceTypeEnum):string => {
        switch (statusClass) {
            case AbsenceTypeEnum.HOLIDAY:
                return 'vacation-status';
            case AbsenceTypeEnum.REMOTE:
                return 'remote-work-status';
            case AbsenceTypeEnum.SICK_LEAVE:
                return 'sick-leave-status';
        }
    }

    return {
        showStatus,
        defineColorOfAbsenceStatus
    }
}
export default AbsenceTypeFormatterService;