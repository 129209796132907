import './MobileAbsenceBlock.scss';
import "./MobileEmployeeList.scss"
import React from "react";
import AbsenceNavigationBar from "../../Shared/AbsenceNavigationBar/AbsenceNavigationBar";
import {useSelector} from 'react-redux';
import RootState from '../../Types/states/RootState';
import AbsenceBlockTabEnum from '../../Enums/AbsenceBlockTabEnum';
import MobileAbsenceBlockTab from "./MobileAbsenceBlockTab/MobileAbsenceBlockTab";
import MobileMyProposalBlockTab from "./MobileMyProposalBlockTab/MobileMyProposalBlockTab";
import MobileActionsHistoryBlockTab from "./MobileActionsHistoryBlockTab/MobileActionsHistoryBlockTab";
import MobileProposalBlockTab from "./MobileProposalBlockTab/MobileProposalBlockTab";
import styles from "./MobileAbsenceBlock.module.scss"
import {RoleEnum} from "../../Enums/RoleEnum";

const MobileAbsenceBlock = () => {
    const selectedAbsenceBlockTab = useSelector((state: RootState) => state.content.selectedBlockTab);
    const role = useSelector((state: RootState) => state.user.role);

    return (
        <div className="mobile-view">
            <div className="mobile-header">
                <h4 className="text-center mt-4 mb-4">Planer nieobecności</h4>
                <div className={styles.top_bar} >
                    <AbsenceNavigationBar isMobile={true}/>
                </div>
            </div>

            {
                selectedAbsenceBlockTab === AbsenceBlockTabEnum.ABSENCES ? (
                    <MobileAbsenceBlockTab />
                ) : selectedAbsenceBlockTab === AbsenceBlockTabEnum.MY_PROPOSALS ? (
                    <MobileMyProposalBlockTab />
                ) : selectedAbsenceBlockTab === AbsenceBlockTabEnum.ACTIONS_HISTORY && role !== RoleEnum.EMPLOYEE ? (
                    <MobileActionsHistoryBlockTab />
                ) : selectedAbsenceBlockTab === AbsenceBlockTabEnum.PROPOSALS && role !== RoleEnum.EMPLOYEE && (
                    <MobileProposalBlockTab />
                )
            }

        </div>
    )
}
export default MobileAbsenceBlock;