import 'date-fns';
import React, {FC} from 'react';
import {FormikProps} from "formik";
import ErrorAlert
    from "../../DesktopComponents/Modals/EmployeeModals/CreateProposal/CreateProposalModal/CreateProposalBodyComponents/ErrorMessage/ErrorAlert";
import {DesktopDateRangePicker, LocalizationProvider, MobileDateRangePicker} from "@material-ui/lab";
import {Box, TextField} from "@material-ui/core";
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import plLocale from "date-fns/locale/pl"
import {CreateProposalFormik} from "src/Types/states/modal/CreateProposalFormik";
import {makeStyles} from "@material-ui/styles";
import calendarIcon from "./../../assets/icons/calendar-icon.svg"
import styles from "./Datepicker.module.scss"

interface DatepickerProps {
    disablePast: boolean,
    formik: FormikProps<CreateProposalFormik & { role: string }> | FormikProps<{ absenceStartDate: Date, absenceFinishDate: Date }>,
    isMobile ?: boolean
}

const mobileStyles = makeStyles({
    container : {
        display : "flex",
        flexDirection : "column"
    },
    input: {
        position: "relative",
        background: "#FFFFFF",
        border: "1px solid #CCD5DB",
        borderRadius: "20px",
        height: "41px",
        width: "200px",
        marginBottom: "24px",

        "&:hover": {
            border: "1px solid #6D6D6D"
        },

        "& img": {
            position: "relative",
            top: "-2px",
            pointerEvents: "none"
        },

        "& input": {
            padding: "9px 11px",
        },

        "& label, & legend, & fieldset": {
            display: "none",
        }
    },
    separator: {
        margin: "0 8px",
        width: "10px",
        display: "flex",
        alignItems: "center",

        "& div": {
            position: "relative",
            top: "-5px",
            width: "100%",
            height: "1px",
            backgroundColor: "#464646"
        }
    },
})

const desktopStyles = makeStyles({
    root: {},
    input: {
        position: "relative",
        background: "#FFFFFF",
        border: "1px solid #CCD5DB",
        borderRadius: "20px",
        height: "41px",
        width: "180px",
        marginBottom: "24px",

        "&:hover": {
            border: "1px solid #6D6D6D"
        },

        "& img": {
            position: "relative",
            top: "-2px",
            pointerEvents: "none"
        },

        "& input": {
            padding: "9px 11px",
        },

        "& label, & legend, & fieldset": {
            display: "none",
        }
    },
    separator: {
        margin: "0 8px",
        width: "10px",
        display: "flex",
        alignItems: "center",

        "& div": {
            position: "relative",
            top: "-5px",
            width: "100%",
            height: "1px",
            backgroundColor: "#464646"
        }
    },
    tooltip: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(16, 16, 16, 0.5)",
        transform: "translate(0,0) !important",
    }
})


const Datepicker: FC<DatepickerProps> = (props) => {

    const {disablePast, formik,isMobile = false} = props;

    const startDate = formik.values.absenceStartDate ? formik.values.absenceStartDate : null
    const finishDate = formik.values.absenceFinishDate ? formik.values.absenceFinishDate : null
    const desktopClasses = desktopStyles();
    const mobileClasses = mobileStyles();

    return (
        <div>
            <div>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={plLocale}>
                    {
                        isMobile ? (
                            <MobileDateRangePicker
                                disablePast={disablePast}
                                startText="Data od"
                                endText="Data do"
                                mask={"__.__.____"}
                                orientation="landscape"
                                cancelText={"anuluj"}
                                showToolbar={false}
                                value={[startDate, finishDate]}
                                onChange={(newValue) => {
                                    newValue[0] ? formik.setFieldValue("absenceStartDate", newValue[0]) : formik.setFieldValue("absenceStartDate", null)
                                    newValue[1] ? formik.setFieldValue("absenceFinishDate", newValue[1]) : formik.setFieldValue("absenceFinishDate", null)
                                }}
                                renderInput={(startProps, endProps) => (
                                    <div className={mobileClasses.container} >
                                        <TextField
                                            {...startProps}
                                            className={mobileClasses.input}
                                            placeholder={"Data od"}
                                            InputProps={{
                                                endAdornment: (
                                                    <img src={calendarIcon}/>
                                                ),
                                            }}
                                        />
                                        <TextField
                                            {...endProps}
                                            className={mobileClasses.input}
                                            placeholder={"Data do"}
                                            InputProps={{
                                                endAdornment: (
                                                    <img src={calendarIcon}/>
                                                ),
                                            }}
                                        />
                                    </div>
                                )}
                            />
                        ) : (
                            <DesktopDateRangePicker
                                disablePast={disablePast}
                                startText={"Data od"}
                                endText={"Data do"}
                                showToolbar={false}
                                PopperProps={{
                                    className: [`${desktopClasses.tooltip}`,`${styles.desktop_tooltip}`].join(" "),
                                }}
                                orientation="landscape"
                                className={desktopClasses.root}
                                mask={"__.__.____"}
                                value={[startDate, finishDate]}
                                onChange={(newValue) => {
                                    newValue[0] ? formik.setFieldValue("absenceStartDate", newValue[0]) : formik.setFieldValue("absenceStartDate", null)
                                    newValue[1] ? formik.setFieldValue("absenceFinishDate", newValue[1]) : formik.setFieldValue("absenceFinishDate", null)
                                }}
                                renderInput={(startProps, endProps) => (
                                    <>
                                        <TextField {...startProps} className={desktopClasses.input}
                                                   placeholder={"Data od"}
                                                   InputProps={{
                                                       endAdornment: (
                                                           <img src={calendarIcon}/>
                                                       ),
                                                   }}
                                        />
                                        <Box className={desktopClasses.separator}>
                                            <div></div>
                                        </Box>
                                        <TextField {...endProps} className={desktopClasses.input}
                                                   placeholder={"Data do"}
                                                   InputProps={{
                                                       endAdornment: (
                                                           <img src={calendarIcon}/>
                                                       ),
                                                   }}
                                        />
                                    </>
                                )}
                            />
                        )
                    }

                </LocalizationProvider>
            </div>
            {((formik.errors.absenceFinishDate && formik.touched.absenceFinishDate)
                || (formik.errors.absenceStartDate && formik.touched.absenceStartDate))
                ? <ErrorAlert>{formik.errors.absenceFinishDate ?? formik.errors.absenceStartDate}</ErrorAlert>
                : null
            }
        </div>

    )
}
export default Datepicker