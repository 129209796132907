import ActionMadeBy from "../../../../../Shared/Partials/ActionMadeBy/ActionMadeBy";
import DeclinedReason from "../../../../../Shared/Partials/DeclinedReason/DeclinedReason";
import EmployeeData from "../../../../../Shared/Partials/EmployeeData/EmployeeData";
import HolidayAbsenceType from "../../../../../Shared/Partials/HolidayAbsenceType/HolidayAbsenceType";
import AbsenceDateRange from "../../../../../Shared/Partials/AbsenceDateRange/AbsenceDateRange";
import Description from "../../../../../Shared/Partials/Description/Description";
import EmployeeProjects from "../../../../../Shared/Partials/EmployeeProjects/EmployeeProjects";
import ReplacementPersonView from "../../../../../Shared/Partials/ReplacementPersonView/ReplacementPersonView";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import RootState from "../../../../../Types/states/RootState";
import DesktopModalHeader from "../../../ModalComponents/ModalHeader/DesktopModalHeader";
import ProposalStatus from "../../../../../Shared/ProposalStatus/ProposalStatus";
import UseModal from "../../../../../Services/CustomHooks/useModal";
import {ModalHeadersEnum} from "../../../../../Enums/ModalHeadersEnum";
import {DesktopModalHeaderHelper} from "../../../ModalComponents/ModalHeader/DesktopModalHeaderHelper";
import styles from "./ActionsHistoryReviewProposalModal.module.scss"
import AbsenceType from "../../../../../Shared/Partials/AbsenceType/AbsenceType";
import {Button} from "@devapo/devapo-ui";


const ActionsHistoryReviewProposalModal = () => {

    const {currentProposal} = useSelector((state: RootState) => state.modalPopup)
    const {backToPrevModal} = UseModal();
    const [header, setHeader] = useState<ModalHeadersEnum | undefined>()

    useEffect(() => {
        DesktopModalHeaderHelper.getModalProposalHeaderEnum(setHeader, currentProposal)
    }, [currentProposal])

    return (
        <>
            <div className={styles.modal}>
                <DesktopModalHeader
                    header={header}
                    startDate={currentProposal.absenceStartDate}
                    endDate={currentProposal.absenceFinishDate}
                    absenceType={currentProposal.absenceType}
                >
                    <ProposalStatus proposalStatus={currentProposal.proposalStatus}/>
                </DesktopModalHeader>

                <form>
                    <ActionMadeBy
                        proposalStatus={currentProposal.proposalStatus}
                        madeBy={currentProposal.approver}
                        actionDate={currentProposal.decisionDate}
                    />
                    <DeclinedReason proposalStatus={currentProposal.proposalStatus}
                                    declinedReason={currentProposal.declinedReason}/>
                    <hr/>
                    <EmployeeData employee={currentProposal.employee}/>
                    <AbsenceType type={currentProposal.absenceType}/>
                    <HolidayAbsenceType type={currentProposal.holidayType} absence={currentProposal.absenceType}/>
                    <AbsenceDateRange
                        startDate={currentProposal.absenceStartDate}
                        endDate={currentProposal.absenceFinishDate}
                    />
                    <Description description={currentProposal.description}/>
                    <EmployeeProjects proposal={currentProposal}/>
                    <ReplacementPersonView replacement={currentProposal.replacement}/>
                </form>

                <div className={styles.footer}>
                    <Button
                        text={"Zamknij"}
                        onClick={() => backToPrevModal()}
                        type={"button"}
                        color={"success"}
                        width={150}
                    />
                </div>
            </div>
        </>
    )
}

export default ActionsHistoryReviewProposalModal