import React, {
    ChangeEvent,
    DetailedHTMLProps,
    Dispatch, EventHandler, FC,
    InputHTMLAttributes, MouseEventHandler,
    SetStateAction,
    useEffect,
    useState
} from "react";
import Proposal from "../../../Types/data/Proposal";
import "./ActionsHistoryTableFilterButtons.scss"
import ProposalStatusEnum from "../../../Enums/ProposalStatusEnum";
import {ProposalFilteringOptionsEnum} from "../../../Enums/ProposalFilteringOptionsEnum";

interface ActionsHistoryTableFilterButtonsProps{
    setProposal : Dispatch<SetStateAction<Proposal[]>>,
    proposalList ?: Proposal[]
}

const ActionsHistoryTableFilterButtons : FC<ActionsHistoryTableFilterButtonsProps> = (props) => {

    const {setProposal, proposalList} = props;

    const [status, setStatus] = useState(ProposalFilteringOptionsEnum.ALL);

    useEffect(() => {
        switch (status) {
            case ProposalFilteringOptionsEnum.ALL :
                setProposal(proposalList || []);
                break;
            case ProposalFilteringOptionsEnum.ACCEPTED :
                setProposal(proposalList ?
                    proposalList.filter(proposal => proposal.proposalStatus === ProposalStatusEnum.ACCEPTED)
                    : []
                );
                break;
            case ProposalFilteringOptionsEnum.DECLINED :
                setProposal(proposalList ?
                    proposalList.filter(proposal => proposal.proposalStatus === ProposalStatusEnum.DECLINED)
                    : []
                );
                break;
        }
    }, [status])

    const handleClick = (e : React.MouseEvent<HTMLInputElement> ) => {
        const {currentTarget} = e

        const value= currentTarget.value as ProposalFilteringOptionsEnum
        setStatus(value)
    }

    return (
        <div className="actions-filter-buttons">
            <label>
                <input type="radio" name="status" value={ProposalFilteringOptionsEnum.ALL} defaultChecked={true}
                       onClick={handleClick}/>
                Wszystkie
            </label>
            <label>
                <input type="radio" name="status" value={ProposalFilteringOptionsEnum.ACCEPTED} onClick={handleClick}/>
                Zaakceptowane
            </label>
            <label>
                <input type="radio" name="status" value={ProposalFilteringOptionsEnum.DECLINED} onClick={handleClick}/>
                Odrzucone
            </label>
        </div>
    )
}

export default ActionsHistoryTableFilterButtons