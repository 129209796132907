import { AbsenceFilteringOptionsEnum } from "../../Enums/AbsenceFilteringOptionsEnum";
import MobileContentState from "../../Types/states/MobileContentState";

const mobileContentInitialState: MobileContentState = {
    absenceTopBarTab: AbsenceFilteringOptionsEnum.ALL,
    absenceTopBarMonth: new Date().getMonth()+1,
    absenceTopBarYear: new Date().getFullYear(),
    absenceTopBarSearchValue: ""
};

export default mobileContentInitialState;
