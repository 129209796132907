import AbsenceTypeEnum from "../Enums/AbsenceTypeEnum";

const checkAbsenceTypeEnum = (type: AbsenceTypeEnum | null) => {
    switch (type){
        case AbsenceTypeEnum.HOLIDAY: return 'Urlop';
        case AbsenceTypeEnum.REMOTE: return 'Praca zdalna';
        case AbsenceTypeEnum.SICK_LEAVE: return 'L4';
    }
    return null;
};

export default checkAbsenceTypeEnum;
