import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import FilteringEngineService from "../../../../Services/FilteringEngineService";
import RootState from "../../../../Types/states/RootState";
import AllOptions from "./AllOptions/AllOptions";
import ListData from "./ListData/ListData";
import {setInputOpen} from "../../../../ReduxStore/reduxSlices/filteringEngineSlice";

const FilterDropdownMenu = ({inputRef} : {inputRef : React.RefObject<HTMLInputElement>}) => {

    const selectedOption = useSelector((state: RootState) => state.filteringEngine.selectedOption);
    const inputOpen = useSelector((state: RootState) => state.filteringEngine.inputOpen);
    const employeesFilter = useSelector((state: RootState) => state.filteringEngine.employeesFilter);
    const positionFilter = useSelector((state: RootState) => state.filteringEngine.positionFilter);
    const projectFilter = useSelector((state: RootState) => state.filteringEngine.projectFilter);

    const {checkIfListIsEmpty} = FilteringEngineService();
    const selectedFilters = useSelector((state: RootState) => state.filteringEngine.selectedFilters);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([])

    const dispatch = useDispatch()

    useEffect(() => {
        if (inputOpen) {
            window.addEventListener("click", dropdownOnBlurClose)

            return () => {
                window.removeEventListener("click", dropdownOnBlurClose)
            }
        }
    }, [inputOpen])

    useEffect(() => {
        const optionsList: string[] = selectedFilters.map(item => item.selectedOption)
        setSelectedOptions(optionsList)
    }, [selectedFilters])

    const dropdownOnBlurClose = (e) => {
        if (inputRef?.current && !inputRef.current.contains(e.target)) {
            dispatch(setInputOpen({inputOpen : false}))
        }
    }
    const filteringServiceCheckList = (option: string) => checkIfListIsEmpty(
        employeesFilter,
        positionFilter,
        projectFilter,
        option
    )

    return (
        <>
            {
                inputOpen ? (
                    <div className="dropdown-filter-box">
                        <div className="dropdown-filter-menu">
                            {
                                selectedOption === "Wszystko" ? (
                                    <AllOptions selectedOptions={selectedOptions}/>
                                ) : filteringServiceCheckList(selectedOption) ? (
                                    <ListData selectedOptions={selectedOptions} listItem={selectedOption}/>
                                ) : (
                                    <div>Brak wyników</div>
                                )
                            }
                        </div>
                    </div>
                ) : <></>
            }
        </>
    )
}

export default FilterDropdownMenu