
export enum ModalHeadersEnum {
    ABSENCE_HEADER = "Podgląd nieobecności",
    HOLIDAY_ABSENCE_HEADER= "Podgląd urlopu",
    HOLIDAY_LEISURE_ABSENCE_HEADER= "Podgląd urlopu wypoczynkowego",
    HOLIDAY_AS_REQUESTED_ABSENCE_HEADER= "Podgląd urlopu na żądanie",
    HOLIDAY_OCCASIONAL_ABSENCE_HEADER= "Podgląd urlopu okolicznościowego",
    HOLIDAY_FREE_ABSENCE_HEADER= "Podgląd urlopu bezpłatnego",
    HOLIDAY_TRAINING_ABSENCE_HEADER= "Podgląd urlopu szkoleniowego",
    REMOTE_ABSENCE_HEADER= "Podgląd pracy zdalnej",
    SICK_LEAVE_ABSENCE_HEADER= "Podgląd L4",

    DELETE_ABSENCE_HEADER = "Odwołanie nieobecności",
    DELETE_HOLIDAY_ABSENCE_HEADER= "Odwołanie urlopu",
    DELETE_HOLIDAY_LEISURE_ABSENCE_HEADER= "Odwołanie urlopu wypoczynkowego",
    DELETE_HOLIDAY_AS_REQUESTED_ABSENCE_HEADER= "Odwołanie urlopu na żądanie",
    DELETE_HOLIDAY_OCCASIONAL_ABSENCE_HEADER= "Odwołanie urlopu okolicznościowego",
    DELETE_HOLIDAY_FREE_ABSENCE_HEADER= "Odwołanie urlopu bezpłatnego",
    DELETE_HOLIDAY_TRAINING_ABSENCE_HEADER= "Odwołanie urlopu szkoleniowego",
    DELETE_REMOTE_ABSENCE_HEADER= "Odwołanie pracy zdalnej",
    DELETE_SICK_LEAVE_ABSENCE_HEADER= "Odwołanie L4",

    PROPOSAL_HEADER = "Pogląd wniosku",
    HOLIDAY_PROPOSAL_HEADER= "Pogląd wniosku o urlop",
    HOLIDAY_LEISURE_PROPOSAL_HEADER= "Pogląd wniosku o urlop wypoczynkowy",
    HOLIDAY_AS_REQUESTED_PROPOSAL_HEADER= "Pogląd wniosku o urlop na żądanie",
    HOLIDAY_OCCASIONAL_PROPOSAL_HEADER= "Pogląd wniosku o urlop okolicznościowy",
    HOLIDAY_FREE_PROPOSAL_HEADER= "Pogląd wniosku o urlop bezpłatny",
    HOLIDAY_TRAINING_PROPOSAL_HEADER= "Pogląd wniosku o urlop szkoleniowy",
    REMOTE_PROPOSAL_HEADER= "Pogląd wniosku o pracę zdalną",
    SICK_LEAVE_PROPOSAL_HEADER= "Pogląd wniosku o L4",

    CANCEL_PROPOSAL = "Odwołaj wniosek",
    CANCEL_HOLIDAY_PROPOSAL_HEADER= "Odwołaj wniosek o urlop",
    CANCEL_HOLIDAY_LEISURE_PROPOSAL_HEADER= "Odwołaj wniosek o urlop wypoczynkowy",
    CANCEL_HOLIDAY_AS_REQUESTED_PROPOSAL_HEADER= "Odwołaj wniosek o urlop na żądanie",
    CANCEL_HOLIDAY_OCCASIONAL_PROPOSAL_HEADER= "Odwołaj wniosek o urlop okolicznościowy",
    CANCEL_HOLIDAY_FREE_PROPOSAL_HEADER= "Odwołaj wniosek o urlop bezpłatny",
    CANCEL_HOLIDAY_TRAINING_PROPOSAL_HEADER= "Odwołaj wniosek o urlop szkoleniowy",
    CANCEL_REMOTE_PROPOSAL_HEADER= "Odwołaj wniosek o pracę zdalną",
    CANCEL_SICK_LEAVE_PROPOSAL_HEADER= "Odwołaj wniosek o L4",

    DECLINE_PROPOSAL_HEADER = "Odrzucenie wniosku",
    DECLINE_HOLIDAY_PROPOSAL_HEADER= "Odrzucenie wniosku o urlop",
    DECLINE_HOLIDAY_LEISURE_PROPOSAL_HEADER= "Odrzucenie wniosku o urlop wypoczynkowy",
    DECLINE_HOLIDAY_AS_REQUESTED_PROPOSAL_HEADER= "Odrzucenie wniosku o urlop na żądanie",
    DECLINE_HOLIDAY_OCCASIONAL_PROPOSAL_HEADER= "Odrzucenie wniosku o urlop okolicznościowy",
    DECLINE_HOLIDAY_FREE_PROPOSAL_HEADER= "Odrzucenie wniosku o urlop bezpłatny",
    DECLINE_HOLIDAY_TRAINING_PROPOSAL_HEADER= "Odrzucenie wniosku o urlop szkoleniowy",
    DECLINE_REMOTE_PROPOSAL_HEADER= "Odrzucenie wniosku o pracę zdalną",
    DECLINE_SICK_LEAVE_PROPOSAL_HEADER= "Odrzucenie wniosku o L4",

    ACCEPT_PROPOSAL = "Zaakceptuj wniosek",
    ACCEPT_HOLIDAY_PROPOSAL_HEADER= "Zaakceptuj wniosek o urlop",
    ACCEPT_HOLIDAY_LEISURE_PROPOSAL_HEADER= "Zaakceptuj wniosek o urlop wypoczynkowy",
    ACCEPT_HOLIDAY_AS_REQUESTED_PROPOSAL_HEADER= "Zaakceptuj wniosek o urlop na żądanie",
    ACCEPT_HOLIDAY_OCCASIONAL_PROPOSAL_HEADER= "Zaakceptuj wniosek o urlop okolicznościowy",
    ACCEPT_HOLIDAY_FREE_PROPOSAL_HEADER= "Zaakceptuj wniosek o urlop bezpłatny",
    ACCEPT_HOLIDAY_TRAINING_PROPOSAL_HEADER= "Zaakceptuj wniosek o urlop szkoleniowy",
    ACCEPT_REMOTE_PROPOSAL_HEADER= "Zaakceptuj wniosek o pracę zdalną",
    ACCEPT_SICK_LEAVE_PROPOSAL_HEADER= "Zaakceptuj wniosek o L4",


    EDIT_PROPOSAL = "Edycja wniosku"
}
