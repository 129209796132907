import { ReactElement } from "react";
import styles from "./MobileTopBar.module.scss";
import { ReactComponent as MobileLogo } from 'src/assets/images/logo-devapo-mobile.svg';

export default function MobileTopBar():ReactElement {
    return <>
        <div className={styles.mobileTopBar}>
            <MobileLogo/>
        </div>
    </>;
}
