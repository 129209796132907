import {createSlice} from "@reduxjs/toolkit";
import proposalInitialState from "../initialStates/proposalInitialState";
import loadProposals from "../thunks/loadProposals";
import acceptProposal from "../thunks/acceptProposal";
import declineProposal from "../thunks/declineProposal";
import updateProposal from "../thunks/updateProposal";
import loadCalendarSortedAndFilteredProposals from "../thunks/loadCalendarSortedAndFilteredProposals";
import FilteringService from "../../Services/FilteringService";
import Proposal from "../../Types/data/Proposal";
import deleteProposal from "../thunks/deleteProposal";

const proposalSlice = createSlice({
    name: 'proposals',
    initialState: proposalInitialState,
    reducers: {
    },
    extraReducers :  (builder ) => {

        builder.addCase( loadProposals.fulfilled, (state,action) => action.payload.proposals);

        builder.addCase( acceptProposal.fulfilled, (state) => {});

        builder.addCase( deleteProposal.fulfilled, (state) => {});

        builder.addCase( declineProposal.fulfilled, (state) => {});

        builder.addCase( updateProposal.fulfilled, (state) => {});


        builder.addCase( loadCalendarSortedAndFilteredProposals.fulfilled, (state, action) => {
            const newState = state.concat(action.payload.loadCalendarSortedAndFilteredProposals)
            return FilteringService.removeRepeatedElemsFromArray<Proposal>(newState);
        } );
    }
})

export default proposalSlice.reducer