import EmployeeAbsenceStatus from "../../../Shared/EmployeeAbsenceStatus/EmployeeAbsenceStatus";
import React, {useEffect, useState} from "react";
import './MobileProposalBlockTab.scss';
import DateFormatService from "../../../Services/DateFormatService";
import FilteringEngineService from "../../../Services/FilteringEngineService";
import Proposal from "../../../Types/data/Proposal";
import { useSelector } from "react-redux";
import RootState from "../../../Types/states/RootState";
import {CurrentModalNameEnum} from "../../../Enums/CurrentModalNameEnum";
import UseModal from "../../../Services/CustomHooks/useModal";
import noAbsencesIcon from "../../../assets/icons/no_absences-icon.svg";
import ProposalStatusEnum from "../../../Enums/ProposalStatusEnum";
import {api} from "../../../ReduxStore/api/api";
import {Pagination} from "@material-ui/core";
import Employee from "../../../Types/data/Employee";

const MobileProposalBlockTab = () => {

    const {toShortDate} = DateFormatService();
    const {getEmployeeNameByID} = FilteringEngineService();
    const {setCurrentProposal,changeModalHistory } = UseModal()
    const employees = useSelector((state:RootState) => state.employees);

    const [currentPage, setCurrentPage] = useState(1)
    const [pageData,setPageData] = useState<Proposal[]>([])

    const { data, isLoading, isFetching } = api.useGetProposalsWithParamsQuery({
        proposalStatus : [ProposalStatusEnum.IN_PROGRESS],
        employee: employees.map((employee : Employee) => employee.id),
    })


    useEffect(() => {
        if(data){
            setPageData(data.slice(0,10))
            setCurrentPage(1)
        }
    },[data])

    const changePage = (page : number) => {
        if(data){
            setCurrentPage(page)
            const start = (page * 10) - 10;
            const end = (page * 10);
            setPageData(data.slice(start,end))
        }
    }

    const handleClick = (e,page) => changePage(page)


    if(!data || data.length === 0 || isLoading || isFetching ){
        return (
            <div className="text-center mt-3">
                <img className="mt-5 mb-4" src={noAbsencesIcon} alt=""/>
                <h5 className="mt-2 no-absences">Nie ma nieobecności</h5>
            </div>
        )
    }

    return (
        <div className="employee-list">
            <div className="conclusion-list mt-4">
                {
                    pageData
                        .sort((a, b) => Object(a).proposalStatus <= Object(b).proposalStatus ? 1 : -1)
                        .map((proposal: Proposal) => (
                        <div  className="proposal-list"
                              onClick={() => {
                                  setCurrentProposal(proposal);
                                  changeModalHistory(CurrentModalNameEnum.MOBILE_REVIEW_RECEIVED_PROPOSAL)
                              }}
                        >
                            <div className="employee-record">
                                <div className="d-flex align-items-center">
                                    <EmployeeAbsenceStatus itemStatus={proposal.absenceType}/>
                                    <p className="mb-0 ml-2 employee-name">{getEmployeeNameByID(employees, proposal.employee)}</p>
                                </div>
                                <div className="d-flex">
                                    <p className="mb-0 ml-2">{toShortDate(proposal.absenceStartDate)}</p>
                                    <span>-</span>
                                    <p className="mb-0">{toShortDate(proposal.absenceFinishDate)}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }

            </div>

            <Pagination
                className={"mt-2 pb-5"}
                page={currentPage}
                count={Math.ceil(data.length / 10)}
                onChange={handleClick}
            />

        </div>

    )
}
export default MobileProposalBlockTab;