import {FilteringEngineState} from "../../Types/states/FilteringEngineState";

export const filteringEngineInitialState : FilteringEngineState = {
    selectedOption : "Wszystko",
    inputValue : "",
    backgroundInputValue : "",
    backgroundType : "",
    inputOpen : false,
    filterOptions : ["Wszystko", "Pracownicy", "Role", "Projekty"],
    employeesFilter : [],
    positionFilter : [],
    projectFilter : [],
    selectedFilters: [],
}