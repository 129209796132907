import React, {FunctionComponent} from "react";
import DateFormatService from "../../../Services/DateFormatService";
import styles from "./AbsenceDateRange.module.scss"

interface IAbsenceDateRangeProps {
    startDate: Date,
    endDate: Date
}

const AbsenceDateRange: FunctionComponent<IAbsenceDateRangeProps> = (props: IAbsenceDateRangeProps) => {
    const {toMediumDate} = DateFormatService();

    const startDate = toMediumDate(props.startDate)
    const endDate = toMediumDate(props.endDate)

    return (
        <div className={styles.date_container}>
            <p className={styles.modal_heading}>Termin urlopu</p>
            <p className={styles.date}>{startDate} - {endDate}</p>
        </div>
    )
}
export default AbsenceDateRange
