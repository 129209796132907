import "./ShowProposalHistoryStatus.scss";
import { FunctionComponent } from "react";
import ProposalStatusEnum from "../../../Enums/ProposalStatusEnum";
import { IMyProposalsTableData } from "../MyProposalsTable";
import { ReactComponent as UiAcceptedIcon } from '../../../assets/icons/history-accepted-icon.svg';
import { ReactComponent as UiInProgressIcon } from '../../../assets/icons/in-progress-icon.svg';
import { ReactComponent as UiCancelledIcon } from '../../../assets/icons/history-cancelled-icon.svg';

interface IShowProposalHistoryStatusProps {
    status?: ProposalStatusEnum;
}

const ShowProposalHistoryStatus:FunctionComponent<IShowProposalHistoryStatusProps> = (props:IShowProposalHistoryStatusProps) => {
    const status = props.status;

    return (
        <div className="show-proposal-history-status">
            {status === ProposalStatusEnum.ACCEPTED && <UiAcceptedIcon/>}
            {status === ProposalStatusEnum.IN_PROGRESS && <UiInProgressIcon/>}
            {status === ProposalStatusEnum.DECLINED && <UiCancelledIcon/>}
        </div>
    );
};

export default ShowProposalHistoryStatus;
