import inProgressIcon from "../../assets/icons/in-progress-icon.svg";
import acceptedIcon from "../../assets/icons/accepted-icon.svg";
import cancelledIcon from "../../assets/icons/cancelled-icon.svg";
import React, {FC} from "react";
import ProposalStatusEnum from "../../Enums/ProposalStatusEnum";
import styles from "./ProposalStatus.module.scss"

interface ProposalStatusProps {
    proposalStatus?: ProposalStatusEnum
    text?: boolean
}

const ProposalStatus: FC<ProposalStatusProps> = (props) => {
    const {proposalStatus, text = true} = props;

    const renderIconBasedOnStatus = (proposalStatus: string) => {
        if (proposalStatus === ProposalStatusEnum.IN_PROGRESS) {
            return (<>
                {text && <h6 className={styles.title}>Oczekuje</h6>}
                <img className={styles.image} src={inProgressIcon} alt=""/>
            </>)
        } else if (proposalStatus === ProposalStatusEnum.ACCEPTED) {
            return (<>
                {text && <h6 className={styles.title}>Zaakceptowany</h6>}
                <img className={styles.image} src={acceptedIcon} alt=""/>
            </>)
        }
        return (<>
            {text && <h6 className={styles.title}>Odrzucono</h6>}
            <img className={styles.image} src={cancelledIcon} alt=""/>
        </>);
    }
    return (
        <div className={styles.proposal_status}>
            {proposalStatus && renderIconBasedOnStatus(proposalStatus)}
        </div>
    )
}
export default ProposalStatus;