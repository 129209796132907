import {CurrentModalNameEnum} from "../../../../../Enums/CurrentModalNameEnum";
import deleteIcon from "../../../../../assets/icons/delete-red-icon.svg";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import React, {FC, useEffect} from "react";
import {FormikProps} from "formik";
import UseModal from "../../../../../Services/CustomHooks/useModal";
import {useSelector} from "react-redux";
import RootState from "../../../../../Types/states/RootState";
import ProposalStatus from "../../../../../Shared/ProposalStatus/ProposalStatus";
import MobileModalHeader from "../../../../Shared/MobileModalHeader/MobileModalHeader";
import {ModalHeadersEnum} from "../../../../../Enums/ModalHeadersEnum";
import CreateProposalBody
    from "../../../../../DesktopComponents/Modals/EmployeeModals/CreateProposal/CreateProposalModal/CreateProposalBody/CreateProposalBody";
import { CreateProposalFormik } from "src/Types/states/modal/CreateProposalFormik";
import {Button} from "@devapo/devapo-ui";
import classNames from "classnames";
import styles from "./MobileEditProposalBody.module.scss"

interface MobileEditProposalBodyProps {
    formik : FormikProps<CreateProposalFormik & { role: string }>,
    result?: unknown
}

const MobileEditProposalBody : FC<MobileEditProposalBodyProps> = (props) => {

    const {formik,result} = props;
    const {changeModalHistory} = UseModal()
    const {currentProposal} = useSelector((state : RootState) => state.modalPopup)

    useEffect(() => {
        loadCurrentProposalToFormik()
    }, [currentProposal]);

    const loadCurrentProposalToFormik = () => {
        formik.setFieldValue("employee" , currentProposal.employee)
        formik.setFieldValue("requester" , currentProposal.requester)
        formik.setFieldValue("absenceType" , currentProposal.absenceType)
        formik.setFieldValue("holidayType" , currentProposal.holidayType ? currentProposal.holidayType : null)
        formik.setFieldValue("absenceStartDate" , currentProposal.absenceStartDate)
        formik.setFieldValue("absenceFinishDate" , currentProposal.absenceFinishDate)
        formik.setFieldValue("description" , currentProposal.description ? currentProposal.description : null)
        formik.setFieldValue("replacement" , currentProposal.replacement ? currentProposal.replacement : null)
    }

    return (
        <form onSubmit={formik.handleSubmit} className={styles.modal_body} >

            <MobileModalHeader
                header={ModalHeadersEnum.EDIT_PROPOSAL}
            >
                <ProposalStatus proposalStatus={currentProposal.proposalStatus}/>
            </MobileModalHeader>

            <CreateProposalBody formik={formik} result={result} isMobile={true}  />

            <div className={styles.footer} >
                <Button
                    text={"Odwołaj"}
                    icon={<img src={deleteIcon} alt="" />}
                    onClick={() => changeModalHistory(CurrentModalNameEnum.MOBILE_CANCEL_PROPOSAL)}
                    type={"button"}
                    color={"warning"}
                    withBackground={false}
                    withBorder={true}
                />
                <Button
                    text={"Zapisz"}
                    icon={<CheckCircleOutlineOutlinedIcon className={styles.icon} />}
                    className={" " + classNames({
                        [styles.disabled_submit] : !(formik.isValid && formik.dirty)
                    })}
                    type={"submit"}
                    color={"success"}
                />
            </div>

        </form>
    )
}

export default MobileEditProposalBody