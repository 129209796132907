import React from "react";
import {useDispatch, useSelector} from "react-redux";
import RootState from "../../../../Types/states/RootState";
import SearchFilter from "../../../../Types/SearchFilter";
import {removeAllSelectedFilters} from "../../../../ReduxStore/reduxSlices/filteringEngineSlice";

const ClearTags = () => {
    const chosenOptions = useSelector((state: RootState) => state.filteringEngine.selectedFilters);
    const chosenFilterOptionList: string[] = chosenOptions.map((option: SearchFilter) => option.selectedOption);
    const dispatch = useDispatch();

    return (
        <>
            {
                chosenFilterOptionList.length > 0 && (
                    <div
                        className="clear-tags"
                        onClick={() => dispatch(removeAllSelectedFilters())}
                    >
                        <p>Wyczyść tagi</p>
                    </div>
                )
            }
        </>
    )
}

export default ClearTags