import AbsenceTypeEnum from "../../../../../../../../Enums/AbsenceTypeEnum";
import React, {FC} from "react";
import AbsenceTypeFormatterService from "../../../../../../../../Services/AbsenceTypeFormatterService";
import {FormikProps} from "formik";
import {CreateProposalFormik} from "src/Types/states/modal/CreateProposalFormik";
import styles from "./RenderAbsenceTypes.module.scss"
import classNames from "classnames";

interface RenderAbsenceTypesProps {
    formik: FormikProps<CreateProposalFormik & { role: string }>
    isMobile: boolean,
}

const RenderAbsenceTypes: FC<RenderAbsenceTypesProps> = (props) => {

    const {formik, isMobile} = props;
    const {showStatus} = AbsenceTypeFormatterService();

    return (
        <>
            {
                Object.values(AbsenceTypeEnum).map((itemStatus: AbsenceTypeEnum, index: number) => (
                    <button key={index}
                            type="button"
                            className={classNames({
                                [styles.absence_status]: !isMobile,
                                [styles.absence_status_mobile]: isMobile,
                                [styles[`absence_status_${AbsenceTypeEnum[itemStatus]}`]]: formik.values.absenceType === AbsenceTypeEnum[itemStatus],
                                [styles[`absence_status_${itemStatus}_hover`]]: formik.values.absenceType !== AbsenceTypeEnum[itemStatus]
                            })}
                            onClick={() => formik.setFieldValue('absenceType', AbsenceTypeEnum[itemStatus])}
                    >
                        <p>{showStatus(AbsenceTypeEnum[itemStatus])}</p>
                    </button>
                ))
            }
        </>
    )
}

export default RenderAbsenceTypes