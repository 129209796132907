import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";
import {configureStore} from "@reduxjs/toolkit";
import {api} from "./api/api";
import { employeeApi } from "./api/employeeApi";

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
        .concat(thunk)
        .concat(api.middleware)
        .concat(employeeApi.middleware),
    devTools: process.env.NODE_ENV !== 'production'
})

export default store
