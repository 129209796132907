import {FunctionComponent} from "react";
import FilteringEngineService from "../../../Services/FilteringEngineService";
import {useSelector} from "react-redux";
import RootState from "../../../Types/states/RootState";
import styles from "./ReplacementPersonView.module.scss"

interface IReplacementPersonViewProps {
    replacement ?: number;
}

const ReplacementPersonView: FunctionComponent<IReplacementPersonViewProps> = (props: IReplacementPersonViewProps) => {

    const {getEmployeeNameByID} = FilteringEngineService();
    const employees = useSelector((state:RootState) => state.employees);

    if(props.replacement){
        return (
            <div className={styles.replacement}>
                <p className={styles.modal_heading}>Osoba na zastępstwo:</p>
                <p className={styles.employee}>
                    {getEmployeeNameByID(employees, props.replacement)}
                </p>
            </div>
        )
    }

    return <></>

};

export default ReplacementPersonView;