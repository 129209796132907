import React, {FC} from "react";
import ProposalStatusEnum from "../../../Enums/ProposalStatusEnum";
import {useSelector} from "react-redux";
import RootState from "../../../Types/states/RootState";
import FilteringEngineService from "../../../Services/FilteringEngineService";
import DateService from "../../../Services/DateService";
import styles from "./ActionMadeBy.module.scss"

interface ActionMadeByProps {
    proposalStatus : ProposalStatusEnum,
    madeBy : number,
    actionDate: Date
}


const ActionMadeBy : FC<ActionMadeByProps> = (props) => {
    const {proposalStatus,madeBy,actionDate} = props;
    const employeeList = useSelector((state:RootState) => state.employees);
    const {getEmployeeNameByID} = FilteringEngineService()
    const actionStringifyDate = DateService.stringifyDate(new Date(actionDate))

    return (
        <>
            <div className={styles.action_made_by_container}>
                <p className={styles.modal_heading}>
                    {
                        proposalStatus === ProposalStatusEnum.ACCEPTED
                            ? "Zaakceptowane przez"
                            : proposalStatus === ProposalStatusEnum.DECLINED
                            && "Odrzucone przez"
                    }
                </p>
                <div className={styles.action_made_by}>
                    <p className="mb-0 employee-name">{getEmployeeNameByID(employeeList, madeBy)}</p>
                    <p className="data mb-0">{actionStringifyDate}</p>
                </div>
            </div>
        </>
    )
}

export default ActionMadeBy