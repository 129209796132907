import FilteringEngine from "./FilteringEngine/FilteringEngine";
import MonthChanger from "../../Shared/MonthChanger/MonthChanger";
import React from "react";
import {MonthsNameEnum} from "../../Enums/MonthsNameEnum";
import {ContentSetSelectedMonth, ContentSetSelectedYear} from "../../ReduxStore/reduxSlices/contentSlice";
import {useDispatch, useSelector} from "react-redux";
import {MonthYearDate} from "../../Types/MonthYearDate";
import RootState from "../../Types/states/RootState";


const FilteringBlock = () => {

    const selectedMonth = useSelector((state: RootState) => state.content.selectedMonth);
    const selectedYear = useSelector((state: RootState) => state.content.selectedYear);

    const dispatch = useDispatch();

    const currentDate: MonthYearDate = {
        month: selectedMonth,
        year: selectedYear
    };

    const changeToNextMonth = (): void => {
        if (currentDate.month === getMonthIndex(MonthsNameEnum.December) + 1) {
            dispatch(ContentSetSelectedMonth({selectedMonth: getMonthIndex(MonthsNameEnum.January) + 1}))
            dispatch(ContentSetSelectedYear({selectedYear: currentDate.year + 1}))
        } else {
            dispatch(ContentSetSelectedMonth({selectedMonth: currentDate.month + 1}))
        }

    }

    const getMonthIndex = (monthName: MonthsNameEnum): number => Object.values(MonthsNameEnum).indexOf(monthName);

    const changeToPrevMonth = (): void => {
        if (currentDate.month === getMonthIndex(MonthsNameEnum.January) + 1) {
            dispatch(ContentSetSelectedMonth({selectedMonth: getMonthIndex(MonthsNameEnum.December) + 1}))
            dispatch(ContentSetSelectedYear({selectedYear: currentDate.year - 1}))
        } else {
            dispatch(ContentSetSelectedMonth({selectedMonth: currentDate.month - 1}))
        }
    }

    return (
        <div className="filter-block">
            <FilteringEngine/>
            <MonthChanger currentDate={currentDate} changeToPrevMonth={changeToPrevMonth}
                          changeToNextMonth={changeToNextMonth}/>
        </div>
    )
}

export default FilteringBlock;