import FilteringService from "../../../Services/FilteringService";
import Employee from "../../../Types/data/Employee";
import AbsenceTypeEnum from "../../../Enums/AbsenceTypeEnum";
import Absence from "../../../Types/data/Absence";
import EmployeeService from "../../../Services/EmployeeService";
import Proposal from "../../../Types/data/Proposal";


export namespace calendarActions {

    const {getEmployeeName, getEmployeeById} = EmployeeService();

    enum CalendarData {
        CELL_WIDTH = 36,
        ABSENCE_PADDING = 7.5
    }

    export const firstInTableUser = (employees: Employee[], loggedInUser: string): Employee[] => {

        let employeeData: Employee | null = null;
        let newEmployeeList: Employee[] = employees.filter((employee: Employee) => {
            if (loggedInUser !== getEmployeeName(employee)) {
                return employee;
            } else {
                employeeData = employee;
            }
        })
        return employeeData ? [employeeData, ...newEmployeeList] : newEmployeeList
    }

    export const filterEmployeeListByChosenOptions = (
        chosenOptions, employeeList, chosenEmployeeIds, chosenPositionIds, chosenProjectIds, loggedInUserId
    ) => {
        if (chosenOptions.length === 0) {
            const newEmployees = employeeList.filter((employee: Employee) => {
                return employee.id !== loggedInUserId;
            });

            const user = getEmployeeById(employeeList, loggedInUserId);

            if (user) {
                newEmployees.unshift(user);
            }

            return newEmployees
        } else {
            const newEmployeeIds = FilteringService.filterEmployeeIdsByAll(
                employeeList,
                chosenEmployeeIds,
                chosenPositionIds,
                chosenProjectIds
            ).filter((employeeId: number) => {
                return employeeId !== loggedInUserId;
            });

            newEmployeeIds.unshift(loggedInUserId);

            return newEmployeeIds.map((employeeId: number) => {
                return getEmployeeById(employeeList, employeeId);
            }).filter((nullableEmployee: Employee | null) => {
                if (nullableEmployee) {
                    return true;
                } else {
                    return false;
                }
            }) as Employee[]
        }
    }

    export const getDaysMonth = (month: number, year: number): Date[] => {
        const date: Date = new Date(year, month, 0);
        const days: Date[] = [];
        for (let i = 1; i <= date.getDate(); i++) {
            days.push(new Date(year, month - 1, i));
        }

        return days;
    }

    export const isAbsenceInSelectedMonth = (start : Date,end : Date,year,month) => {
        return (
            ( ( (start.getMonth() + 1) <= month && start.getFullYear() === year) || start.getFullYear() < year )
            &&
            ( ((end.getMonth() + 1) >= month && end.getFullYear() === year) || end.getFullYear() > year )
        )
    }

    export const setAbsenceLeftPosition = (start : Date ,end : Date, selectedMonth: number, selectedYear: number): string => {

        if((start.getMonth() + 1) === selectedMonth && start.getFullYear() === selectedYear){
            return start.getDate() * CalendarData.CELL_WIDTH - CalendarData.CELL_WIDTH + CalendarData.ABSENCE_PADDING + "px"
        }

        if((end.getMonth() + 1) === selectedMonth && end.getFullYear() === selectedYear){
            return  CalendarData.ABSENCE_PADDING * -1 - CalendarData.ABSENCE_PADDING + "px"
        }

        return CalendarData.CELL_WIDTH * -1 + "px"
    }

    export const setAbsenceWidth = (start : Date ,end : Date,selectedMonth,selectedYear): string => {

        if((end.getMonth() + 1) === selectedMonth && end.getFullYear() === selectedYear && (start.getMonth() + 1) === selectedMonth && start.getFullYear() === selectedYear) {
            const diffDays = end.getDate() - start.getDate()

            return ((diffDays * CalendarData.CELL_WIDTH) - (CalendarData.ABSENCE_PADDING * 2) + CalendarData.CELL_WIDTH) + "px";
        }

        if((start.getMonth() + 1) === selectedMonth && start.getFullYear() === selectedYear){
            return (32 - start.getDate()) * CalendarData.CELL_WIDTH + "px"
        }

        if((end.getMonth() + 1) === selectedMonth && end.getFullYear() === selectedYear){
            return end.getDate() * CalendarData.CELL_WIDTH + "px"
        }

        return CalendarData.CELL_WIDTH * 32 + CalendarData.CELL_WIDTH + "px"
    }

    export const defineAbsenceBackgroundColor = (absence: Absence): string => {
        const type: AbsenceTypeEnum = absence.absenceType;
        let color: string;

        switch (type) {
            case AbsenceTypeEnum.HOLIDAY :
                color = "#F36666";
                break;
            case AbsenceTypeEnum.REMOTE :
                color = "#FFC46B";
                break;
            case AbsenceTypeEnum.SICK_LEAVE :
                color = "#2978A0";
                break;
        }

        return color;
    }

    export const defineProposalBackgroundColor = (proposal: Proposal): string => {
        const type: AbsenceTypeEnum = proposal.absenceType;
        let color: string;

        switch (type) {
            case AbsenceTypeEnum.HOLIDAY :
                color = "#F36666";
                break;
            case AbsenceTypeEnum.REMOTE :
                color = "#FFC46B";
                break;
            case AbsenceTypeEnum.SICK_LEAVE :
                color = "#2978A0";
                break;
        }

        return `repeating-linear-gradient(-90deg, ${color}, ${color} 4px, transparent 4px, transparent 8px, black 8px),
              repeating-linear-gradient(-45deg, ${color}, ${color} 4px, transparent 4px, transparent 8px, black 8px),
              repeating-linear-gradient(-90deg, ${color}, ${color} 4px, transparent 4px, transparent 8px, black 8px),
              repeating-linear-gradient(-45deg, ${color}, ${color} 4px, transparent 4px, transparent 8px, black 8px)`
    }

    export const setCurrentDayLeft = (selectedMonth,selectedYear): string => {
        const currentDay: Date = new Date();
        let left : string = "-100px";
        if(selectedMonth === currentDay.getMonth() + 1 &&  selectedYear === currentDay.getFullYear()){
            left = (currentDay.getDate() * CalendarData.CELL_WIDTH) - CalendarData.CELL_WIDTH + "px"
        }
        return left;
    }

    export const deleteGreenColumn = (): void => {
        const calendarProposals : NodeListOf<HTMLElement> = document.querySelectorAll(".calendar-proposal");
        const users : NodeListOf<HTMLElement> = document.querySelectorAll(".user-hover")
        const usersSticky : NodeListOf<HTMLElement> = document.querySelectorAll(".sticky-user-hover")
        const hoverDays : NodeListOf<HTMLElement> = document.querySelectorAll(".day-cell-hover")
        const hoverDay : NodeListOf<HTMLElement> = document.querySelectorAll(".hover-day")

        calendarProposals.forEach(proposal => proposal.classList.remove("calendar-proposal-hover"))
        users.forEach(proposal => proposal.classList.remove("user-hover"))
        usersSticky.forEach(proposal => proposal.classList.remove("sticky-user-hover"))

        hoverDays.forEach(day => {
            day.classList.remove("day-cell-hover")
        })

        hoverDay.forEach(day => {
            day.classList.remove("hover-day")
        })

    }

    export const setVerticalGreenColumn = (e): void => {
        const calendarContainer: HTMLDivElement = (document.querySelector(".calendar-box") as HTMLDivElement);
        const usersContainer: HTMLDivElement = (document.querySelector(".users-container") as HTMLDivElement);
        let cX: number = 0;

        cX = e.clientX + calendarContainer.scrollLeft;

        const verticalGreenColumn: HTMLDivElement = (document.querySelector('.calendar-greenColumn-vertical') as HTMLDivElement);

        if (calendarContainer && usersContainer && verticalGreenColumn) {
            const usersCoords: DOMRect = usersContainer.getBoundingClientRect()
            const calendarCoords: DOMRect = calendarContainer.getBoundingClientRect();
            let x: number = 0;

            x = Math.ceil((cX - calendarCoords.x - usersCoords.width ) / 36);

            const calendarRows : NodeListOf<HTMLElement> = document.querySelectorAll(".calendar-days-row")
            const daysHead : NodeListOf<HTMLElement> = document.querySelectorAll(".calendar-days-head")
            const daysFoot : NodeListOf<HTMLElement> = document.querySelectorAll(".calendar-days-foot")

            calendarRows.forEach(row => {
                const cell : HTMLDivElement = (row.children[x - 1] as HTMLDivElement);
                if(cell){
                    cell.classList.add("day-cell-hover")
                }
            })

            daysHead.forEach(days => {
                const day : HTMLDivElement = (days.children[0].children[x - 1] as HTMLDivElement);
                if(day){
                    day.classList.add("hover-day")
                }
            })

            daysFoot.forEach(days => {
                const day : HTMLDivElement = (days.children[0].children[x - 1] as HTMLDivElement);
                if(day){
                    day.classList.add("hover-day")
                }
            })

        }
    }

    export const setHorizontalGreenColumn = (index: number): void => {
        const calendarProposals : NodeListOf<HTMLElement> = document.querySelectorAll(".calendar-proposal")
        const users : NodeListOf<HTMLElement> = document.querySelectorAll(".user")

        calendarProposals[index].classList.add("calendar-proposal-hover");
        if(index === 0){
            users[index].classList.add("sticky-user-hover")
        }else {
            users[index].classList.add("user-hover")
        }
    }

    export const setAbsenceZIndex = (absence: Absence | Proposal) : number => {
        const type: AbsenceTypeEnum = absence.absenceType;

        switch (type) {
            case AbsenceTypeEnum.HOLIDAY :
                return 4
            case AbsenceTypeEnum.REMOTE :
                return 2
            case AbsenceTypeEnum.SICK_LEAVE :
                return 8
        }
    }

}