import "./EmployeeAbsenceStatus.scss"
import AbsenceTypeFormatterService from "../../Services/AbsenceTypeFormatterService";
import AbsenceTypeEnum from "../../Enums/AbsenceTypeEnum";

const EmployeeAbsenceStatus = ({itemStatus}: { itemStatus: AbsenceTypeEnum }) => {
const {defineColorOfAbsenceStatus, showStatus}  = AbsenceTypeFormatterService()

    return (
        <div className={`color-status ${defineColorOfAbsenceStatus(itemStatus)}`}>
            <p className="mb-0">{showStatus(itemStatus)}</p>
        </div>
    )

}
export default EmployeeAbsenceStatus;