import React, {FC} from "react";
import DateService from "../../../Services/DateService";

interface CalendarDaysProps{
    position : string,
    daysMonthArray : Date[]
}

const CalendarDays : FC<CalendarDaysProps> = (props) => {

    const { position, daysMonthArray } = props;

    return (
        <div className={`calendar-days calendar-days-${position}`}>
            <div className="days">
                {
                    daysMonthArray.map((day : Date, index : number) => (
                        <div
                            className={`calendar-day ${DateService.isToday(day) && `current-day-${position}`}  `}
                            key={`h-day-${index}`}>
                            {day.getDate()}
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default CalendarDays