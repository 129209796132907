import {createSlice} from "@reduxjs/toolkit";
import absenceTabInitialState from "../../initialStates/table/absenceTabInitialState";


const absenceTabSlice = createSlice({
    name : "absenceTabSlice",
    initialState : absenceTabInitialState,
    reducers : {
        selectAbsenceTabSortOrder(state,action){
            state.absenceTabSortOrder = action.payload.absenceTabSortOrder
        },
        setAbsenceTabSortBy(state,action){
            state.absenceTabSortBy = action.payload.absenceTabSortBy
        },
        setAbsenceTabDateFrom(state,action){
            state.absenceTabDateFrom = action.payload.absenceTabDateFrom
        },
        setAbsenceTabDateTo(state,action){
            state.absenceTabDateTo = action.payload.absenceTabDateTo
        },
    }
})

export const {
    selectAbsenceTabSortOrder,
    setAbsenceTabSortBy,
    setAbsenceTabDateFrom,
    setAbsenceTabDateTo
} = absenceTabSlice.actions

export default absenceTabSlice.reducer