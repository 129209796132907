import "./AbsencesFilteredTable.scss";
import Absence from '../../Types/data/Absence';
import React, {FC} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../Types/states/RootState';
import UiTable from '../Shared/Table/UiTable';
import EmployeeService from '../../Services/EmployeeService';
import SortOrder from '../../Types/SortOrder';
import checkAbsenceTypeEnum from "../../helpers/checkAbsenceTypeEnum";
import ShowAbsenceType from "./ShowAbsenceType/ShowAbsenceType";
import UseModal from "../../Services/CustomHooks/useModal";
import {CurrentModalNameEnum} from "../../Enums/CurrentModalNameEnum";
import ShowAbsencesHoverIcons from "./ShowAbsencesHoverIcons/ShowAbsencesHoverIcons";
import generateCustomDateComparator from "src/helpers/customDateComparator";
import {selectAbsenceTabSortOrder, setAbsenceTabSortBy} from "../../ReduxStore/reduxSlices/table/absenceTabSlice";
import DateService from "../../Services/DateService";

export interface IAbsencesFilteredTableData {
    employee: string|number;
    startDate: Date;
    endDate: Date;
    absenceType: string;
    description: string;
    '_absence': Absence
}

interface AbsencesFilteredTableProps {
    absences : Absence[]
}

const AbsencesFilteredTable: FC<AbsencesFilteredTableProps> = (props) => {
    const {absences} = props;
    const employees = useSelector((state:RootState) => state.employees);
    const sortBy = useSelector((state:RootState) => state.tables.absenceTabSlice.absenceTabSortBy);
    const sortOrder = useSelector((state:RootState) => state.tables.absenceTabSlice.absenceTabSortOrder);
    const dispatch = useDispatch();
    const {getEmployeeById, getEmployeeName} = EmployeeService();
    const {setCurrentAbsence,changeModalHistory}= UseModal()

    const tableData = absences.map((absence:Absence) => {
        const {employee, endDate, startDate, absenceType, description} = absence;
        const employeeById = getEmployeeById(employees, employee);
        let employeeName:string|null = null;

        if (employeeById) {
            employeeName = getEmployeeName(employeeById);
        }

        return {
            employee: employeeName ? employeeName : employee,
            startDate: startDate ,
            endDate: endDate,
            absenceType: checkAbsenceTypeEnum(absenceType),
            description,
            '_absence': absence
        };
    }) as IAbsencesFilteredTableData[];

    const getPropertyName = (property:string):string => {
        switch(property) {
            case 'employee':
                return 'Imię i nazwisko';
            case 'startDate':
                return 'Data od';
            case 'endDate':
                return 'Data do';
            case 'absenceType':
                return 'Status';
            case 'description':
                return 'Opis';
            default:
                return '';
        }
    };

    const setSort = (newSortBy:string) => {
        if (newSortBy === sortBy) {
            const newSortOrder:SortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
            dispatch(selectAbsenceTabSortOrder({absenceTabSortOrder : newSortOrder }))
        } else {
            dispatch(selectAbsenceTabSortOrder({absenceTabSortOrder : "asc" }))
            dispatch(setAbsenceTabSortBy({absenceTabSortBy : newSortBy }))
        }
    };

    const onElementClick = (element:IAbsencesFilteredTableData) => {
        setCurrentAbsence(element._absence)
        changeModalHistory(CurrentModalNameEnum.REVIEW_ABSENCE)
    };

    return (
        <>
            <UiTable<IAbsencesFilteredTableData>
                data={tableData}
                sortBy={sortBy as keyof IAbsencesFilteredTableData}
                sortOrder={sortOrder}
                getPropertyName={getPropertyName}
                setSort={setSort}
                config={{
                    customColumnWidths: {
                        description: '500px'
                    },
                    customBodyComponents: {
                        absenceType: (text:string, element:IAbsencesFilteredTableData) => <ShowAbsenceType<IAbsencesFilteredTableData> text={text} element={element}/>,
                        startDate : ( date : Date , element:IAbsencesFilteredTableData) => <span className={"span-date"} >{DateService.stringifyDate(date)}</span>,
                        endDate : ( date : Date , element:IAbsencesFilteredTableData) => <span className={"span-date"} >{DateService.stringifyDate(date)}</span>
                    },
                    customComparators: {
                        startDate: generateCustomDateComparator<IAbsencesFilteredTableData>('startDate'),
                        endDate: generateCustomDateComparator<IAbsencesFilteredTableData>('endDate')
                    },
                    onElementClick,
                    onHoverComponent: (element:IAbsencesFilteredTableData) => <ShowAbsencesHoverIcons element={element}/>,
                }}
            />
        </>
    );
};

export default AbsencesFilteredTable;