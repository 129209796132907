import React, {FC} from "react";
import AbsenceBlockTabEnum from "../../../Enums/AbsenceBlockTabEnum";
import {useSelector} from "react-redux";
import RootState from "../../../Types/states/RootState";
import styles from "./bar.module.scss";
import classNames from "classnames";

interface ActionsHistoryBarProps {
    onClick : (absenceBlockTab:AbsenceBlockTabEnum) => void
}


const ActionsHistoryBar : FC<ActionsHistoryBarProps> = (props) => {

    const{onClick} = props

    const selectedAbsenceBlockTab = useSelector((state:RootState) => state.content.selectedBlockTab);

    const handleClick = () => onClick(AbsenceBlockTabEnum.ACTIONS_HISTORY)

    return (
        <div
            className={classNames({
                [styles.bar_option] : true,
                [styles.without_number] : true,
            })}
        >
            <button
                className={classNames({
                    [styles.option] : true,
                    [styles.selected] : selectedAbsenceBlockTab === AbsenceBlockTabEnum.ACTIONS_HISTORY,
                })}
                onClick={handleClick}>
                Historia Akcji
            </button>
        </div>
    )
}

export default ActionsHistoryBar