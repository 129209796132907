import React from "react";
import {useSelector} from "react-redux";
import { useEffect } from "react";
import RootState from "../../../../Types/states/RootState";
import UseModal from "../../../../Services/CustomHooks/useModal";
import ReviewReceivedProposalModal from "./ReviewReceivedProposalModal/ReviewReceivedProposalModal";
import {CurrentModalNameEnum} from "../../../../Enums/CurrentModalNameEnum";
import {Modal} from "@devapo/devapo-ui"
import styles from "../../Modals.module.scss";


const ReviewReceivedProposal = () => {
    const {currentOpenModal, currentProposal} = useSelector((state : RootState) => state.modalPopup)
    const {closeAll} = UseModal();

    useEffect(() => {
        if(!currentOpenModal) {
            close()
        }
    }, [currentOpenModal]);


    const close = () => {
        closeAll()
    }

    return (
        <>
            <Modal
                isOpen={currentOpenModal === CurrentModalNameEnum.REVIEW_RECEIVED_PROPOSAL}
                onClose={close}
                modalClass={styles.modal}
                backdropClass={styles.backdrop}
            >
                <ReviewReceivedProposalModal
                    proposal={currentProposal}
                />
            </Modal>
        </>
    )
}
export default ReviewReceivedProposal;