
export enum CurrentModalNameEnum {
    CREATE_PROPOSAL = "CreateProposalModal",
    REVIEW_CREATED_PROPOSAL = "ReviewCreatedProposalModal",
    EDIT_PROPOSAL = "EditProposalModal",
    CANCEL_PROPOSAL = "CancelProposalModal",
    REVIEW_RECEIVED_PROPOSAL = "ReviewReceivedProposalModal",
    DECLINE_RECEIVED_PROPOSAL = "DeclineReceivedProposalModal",
    ACCEPT_RECEIVED_PROPOSAL = "AcceptReceivedProposalModal",
    REVIEW_ABSENCE = "ReviewAbsenceModal",
    DELETE_ABSENCE = "DeleteAbsenceModal",
    ACTIONS_HISTORY_REVIEW_PROPOSAL = "ActionsHistoryReviewProposal",
    MOBILE_ACTIONS_HISTORY_REVIEW_PROPOSAL = "MobileActionsHistoryReviewProposal",

    MOBILE_CREATE_PROPOSAL = "MobileCreateProposalModal",
    MOBILE_REVIEW_ABSENCE = "MobileReviewAbsenceModal",
    MOBILE_DELETE_ABSENCE = "MobileDeleteAbsenceModal",
    MOBILE_REVIEW_RECEIVED_PROPOSAL = "MobileReviewReceivedProposalModal",
    MOBILE_DECLINE_RECEIVED_PROPOSAL = "MobileDeclineReceivedProposalModal",
    MOBILE_ACCEPT_RECEIVED_PROPOSAL = "MobileAcceptReceivedProposalModal",
    MOBILE_REVIEW_CREATED_PROPOSAL = "MobileReviewCreatedProposalModal",
    MOBILE_EDIT_PROPOSAL = "MobileEditProposalModal",
    MOBILE_CANCEL_PROPOSAL = "MobileCancelProposalModal",
}