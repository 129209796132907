import DesktopModalHeader from "../../../ModalComponents/ModalHeader/DesktopModalHeader";
import {ModalHeadersEnum} from "../../../../../Enums/ModalHeadersEnum";
import EmployeeData from "../../../../../Shared/Partials/EmployeeData/EmployeeData";
import AbsenceType from "../../../../../Shared/Partials/AbsenceType/AbsenceType";
import HolidayAbsenceType from "../../../../../Shared/Partials/HolidayAbsenceType/HolidayAbsenceType";
import React, {useEffect, useState} from "react";
import UseModal from "../../../../../Services/CustomHooks/useModal";
import {useSelector} from "react-redux";
import RootState from "../../../../../Types/states/RootState";
import {DesktopModalHeaderHelper} from "../../../ModalComponents/ModalHeader/DesktopModalHeaderHelper";
import styles from "./AcceptProposalModal.module.scss"
import AbsenceDateRange from "../../../../../Shared/Partials/AbsenceDateRange/AbsenceDateRange";
import Description from "../../../../../Shared/Partials/Description/Description";
import EmployeeProjects from "../../../../../Shared/Partials/EmployeeProjects/EmployeeProjects";
import ReplacementPersonView from "../../../../../Shared/Partials/ReplacementPersonView/ReplacementPersonView";
import {Button} from "@devapo/devapo-ui";

const AcceptProposalModal = ({doAcceptProposal}: { doAcceptProposal: () => void }) => {
    const {currentProposal} = useSelector((state: RootState) => state.modalPopup)
    const {backToPrevModal} = UseModal()
    const [header, setHeader] = useState<ModalHeadersEnum | undefined>()

    useEffect(() => {
        DesktopModalHeaderHelper.getModalAcceptProposalHeaderEnum(setHeader, currentProposal)
    }, [currentProposal])


    return (
        <div className={styles.modal_block}>

            <DesktopModalHeader
                header={header}
                startDate={currentProposal.absenceStartDate}
                endDate={currentProposal.absenceFinishDate}
                absenceType={currentProposal.absenceType}
            />

            <div>
                <EmployeeData employee={currentProposal.employee}/>
                <AbsenceType type={currentProposal.absenceType}/>
                <HolidayAbsenceType type={currentProposal.holidayType} absence={currentProposal.absenceType}/>
                <AbsenceDateRange
                    startDate={currentProposal.absenceStartDate}
                    endDate={currentProposal.absenceFinishDate}
                />
                <Description description={currentProposal.description}/>
                <EmployeeProjects proposal={currentProposal}/>
                <ReplacementPersonView replacement={currentProposal.replacement}/>
            </div>

            <div className={styles.footer_buttons}>
                <Button
                    text={"Anuluj"}
                    onClick={() => backToPrevModal()}
                    type={"button"}
                    color={"normal"}
                />
                <Button
                    text={"Zaakceptuj wniosek"}
                    onClick={doAcceptProposal}
                    type={"button"}
                    color={"success"}
                />
            </div>
        </div>
    )
}

export default AcceptProposalModal