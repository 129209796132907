import {useSelector} from "react-redux";
import RootState from "../../../../Types/states/RootState";
import deleteIcon from "../../../../assets/icons/delete-red-icon.svg";
import React, {useEffect, useState} from "react";
import UseModal from "../../../../Services/CustomHooks/useModal";
import {CurrentModalNameEnum} from "../../../../Enums/CurrentModalNameEnum";
import EmployeeData from "../../../../Shared/Partials/EmployeeData/EmployeeData";
import AbsenceType from "../../../../Shared/Partials/AbsenceType/AbsenceType";
import HolidayAbsenceType from "../../../../Shared/Partials/HolidayAbsenceType/HolidayAbsenceType";
import AbsenceDateRange from "../../../../Shared/Partials/AbsenceDateRange/AbsenceDateRange";
import Description from "../../../../Shared/Partials/Description/Description";
import EmployeeProjects from "../../../../Shared/Partials/EmployeeProjects/EmployeeProjects";
import ReplacementPersonView from "../../../../Shared/Partials/ReplacementPersonView/ReplacementPersonView";
import DesktopModalHeader from "src/DesktopComponents/Modals/ModalComponents/ModalHeader/DesktopModalHeader";
import {
    DesktopModalHeaderHelper
} from "../../ModalComponents/ModalHeader/DesktopModalHeaderHelper";
import {ModalHeadersEnum} from "../../../../Enums/ModalHeadersEnum";
import styles from "./ReviewAbsenceModal.module.scss"
import {Button} from "@devapo/devapo-ui";
import {RoleEnum} from "../../../../Enums/RoleEnum";

const ReviewAbsenceModal = () => {
    const role = useSelector((state: RootState) => state.user.role);
    const {currentAbsence} = useSelector((state: RootState) => state.modalPopup)
    const {backToPrevModal, changeModalHistory} = UseModal()
    const [header, setHeader] = useState<ModalHeadersEnum | undefined>()

    useEffect(() => {
        DesktopModalHeaderHelper.getModalAbsenceHeaderEnum(setHeader, currentAbsence)
    }, [currentAbsence])


    if (currentAbsence?.id) {
        return (
            <div className={styles.modal_block}>

                <DesktopModalHeader
                    header={header}
                    startDate={currentAbsence.startDate}
                    endDate={currentAbsence.endDate}
                    absenceType={currentAbsence.absenceType}
                />

                <div>
                    <EmployeeData employee={currentAbsence.employee}/>
                    <AbsenceType type={currentAbsence.absenceType}/>
                    <HolidayAbsenceType type={currentAbsence.holidayType} absence={currentAbsence.absenceType}/>
                    <AbsenceDateRange
                        startDate={currentAbsence.startDate}
                        endDate={currentAbsence.endDate}
                    />
                    <Description description={currentAbsence.description}/>
                    <EmployeeProjects proposal={currentAbsence}/>
                    <ReplacementPersonView replacement={currentAbsence.replacement}/>
                </div>

                <div className={styles.footer}>
                    {
                        role === RoleEnum.EMPLOYER && (
                            <Button
                                text={"Odwołaj"}
                                icon={<img src={deleteIcon} alt=""/>}
                                onClick={() => changeModalHistory(CurrentModalNameEnum.DELETE_ABSENCE)}
                                type={"button"}
                                color={"warning"}
                                withBorder={false}
                                withBackground={false}
                            />
                        )
                    }
                    <Button
                        text={"Zamknij"}
                        onClick={backToPrevModal}
                        type={"button"}
                        color={"success"}
                        width={150}
                    />
                </div>

            </div>
        )
    }

    return <></>
}

export default ReviewAbsenceModal