import DataService from "../../Services/DataService";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {reduxSliceTypeEnum} from "../reduxSliceTypeEnum/reduxSliceTypeEnum";

const loadPositions = createAsyncThunk(reduxSliceTypeEnum.LOAD_POSITIONS, async () => {
    const positions = await DataService.getPositions();
    return {positions}
})

export default loadPositions;
