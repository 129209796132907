import React, {ChangeEvent, FC, useEffect, useState} from "react";
import MobileModalHeader from "../../../../Shared/MobileModalHeader/MobileModalHeader";
import {ModalHeadersEnum} from "../../../../../Enums/ModalHeadersEnum";
import {DesktopModalHeaderHelper} from "../../../../../DesktopComponents/Modals/ModalComponents/ModalHeader/DesktopModalHeaderHelper";
import {useSelector} from "react-redux";
import RootState from "../../../../../Types/states/RootState";
import UseModal from "../../../../../Services/CustomHooks/useModal";
import EmployeeData from "../../../../../Shared/Partials/EmployeeData/EmployeeData";
import AbsenceType from "../../../../../Shared/Partials/AbsenceType/AbsenceType";
import HolidayAbsenceType from "../../../../../Shared/Partials/HolidayAbsenceType/HolidayAbsenceType";
import ReplacementPersonView from "../../../../../Shared/Partials/ReplacementPersonView/ReplacementPersonView";
import {FormikProps} from "formik";
import ReasonMessage from "../../../../../Shared/Partials/ReasonMessage/ReasonMessage";
import AbsenceDateRange from "../../../../../Shared/Partials/AbsenceDateRange/AbsenceDateRange";
import Description from "../../../../../Shared/Partials/Description/Description";
import EmployeeProjects from "../../../../../Shared/Partials/EmployeeProjects/EmployeeProjects";
import {Button} from "@devapo/devapo-ui";
import styles from "./MobileDeclineProposalModal.module.scss"

interface MobileDeclineProposalModalProps {
    formik: FormikProps<{ declinedReason: string }>
}

const MobileDeclineProposalModal : FC<MobileDeclineProposalModalProps> = (props) => {
    const {formik} = props;
    const {currentProposal}  = useSelector((state : RootState) => state.modalPopup)
    const {backToPrevModal} = UseModal();
    const [header,setHeader] = useState<ModalHeadersEnum | undefined>()

    useEffect(() => {
        DesktopModalHeaderHelper.getModalDeclineProposalHeaderEnum(setHeader,currentProposal)
    },[currentProposal])

    const handleReasonChange = (e : ChangeEvent<HTMLTextAreaElement>) => {
        formik.setFieldValue("declinedReason" , e.target.value)
    }

    return (
        <form onSubmit={formik.handleSubmit} className={styles.modal_body} >

            <MobileModalHeader
                header={header}
                startDate={currentProposal.absenceStartDate}
                endDate={currentProposal.absenceFinishDate}
            />

            <div >
                <ReasonMessage
                    header={"Powód odrzucenia wniosku"}
                    value={formik.values.declinedReason}
                    onChange={handleReasonChange}
                />
                <EmployeeData employee={currentProposal.employee}/>
                <AbsenceType type={currentProposal.absenceType}/>
                <HolidayAbsenceType type={currentProposal.holidayType} absence={currentProposal.absenceType}/>
                <AbsenceDateRange
                    startDate={currentProposal.absenceStartDate}
                    endDate={currentProposal.absenceFinishDate}
                />
                <Description description={currentProposal.description}/>
                <EmployeeProjects proposal={currentProposal}/>
                <ReplacementPersonView replacement={currentProposal.replacement}/>
            </div>

            <div className={styles.footer} >

                <Button
                    text={"Anuluj"}
                    type={"button"}
                    onClick={backToPrevModal}
                    color={"normal"}
                />

                <Button
                    text={"Odrzuć"}
                    type={"submit"}
                    color={"warning"}
                    width={150}
                />

            </div>

        </form>
    )
}

export default MobileDeclineProposalModal