import React, {FC, ReactElement} from "react";
import DateFormatService from "../../../Services/DateFormatService";
import {ModalHeadersEnum} from "../../../Enums/ModalHeadersEnum";
import styles from "./MobileModalHeader.module.scss"

interface MobileModalHeaderProps {
    header?: ModalHeadersEnum,
    startDate?: Date,
    endDate?: Date,
    children?: ReactElement
}

const MobileModalHeader: FC<MobileModalHeaderProps> = (props) => {
    const {header, startDate, endDate, children} = props;
    const {toMediumDate} = DateFormatService()

    return (
        <div className={styles.header} >
            <h4>{header}</h4>
            {
                startDate && endDate && (
                    <h6>
                        {toMediumDate(startDate)}&nbsp;-&nbsp;
                        {toMediumDate(endDate)}
                    </h6>
                )
            }
            {children}
        </div>

    )
}

export default MobileModalHeader