import ReviewReceivedProposal from "./EmployerModals/ReviewReceivedProposal/ReviewReceivedProposal";
import ActionsHistoryReviewProposal from "./EmployerModals/ActionsHistoryReviewProposal/ActionsHistoryReviewProposal";
import DeclineProposal from "./EmployerModals/DeclineProposal/DeclineProposal";
import CreateProposal from "./EmployeeModals/CreateProposal/CreateProposal";
import ReviewCreatedProposal from "./EmployeeModals/ReviewCreatedProposal/ReviewCreatedProposal";
import CancelProposal from "./EmployeeModals/CancelProposal/CancelProposal";
import EditProposal from "./EmployeeModals/EditProposal/EditProposal";
import DeleteAbsence from "./EmployerModals/DeleteAbsence/DeleteAbsence";
import ReviewAbsence from "./ReviewAbsence/ReviewAbsence";
import AcceptProposal from "./EmployerModals/AcceptProposal/AcceptProposal";
import React from "react";

const Modals = () => {

    return (
        <>
            <ReviewReceivedProposal />
            <AcceptProposal />
            <DeclineProposal />

            <ActionsHistoryReviewProposal />

            <CreateProposal />

            <ReviewCreatedProposal />
            <CancelProposal />
            <EditProposal />

            <DeleteAbsence />
            <ReviewAbsence />
        </>
    )
}

export default Modals