import "./AbsenceFilteringEngine.scss"
import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import RootState from "../../../../Types/states/RootState";
import searchIcon from "../../../../assets/icons/search-icon.svg"
import AbsenceFiltersList from "./AbsenceFiltersList/AbsenceFiltersList";
import {
    addSelectedFilter, removeAllSelectedFilters, removeSelectedFilter,
    setBackgroundInputValue, setBackgroundType,
    setEmployeesFilter, setInputOpen, setInputValue,
    setPositionFilter,
    setProjectFilter, setSelectedOption
} from "../../../../ReduxStore/reduxSlices/filteringEngineSlice";
import {filterHelper} from "../../../../DesktopComponents/FilteringBlock/FilteringEngine/FilterHelper/FilterHelper";
import FilterInput from "../../../../DesktopComponents/FilteringBlock/FilteringEngine/FilterInput/FilterInput";
import FilterDropdownMenu
    from "../../../../DesktopComponents/FilteringBlock/FilteringEngine/RenderFilterDropdownMenu/RenderFilterDropdownMenu";

const AbsenceFilteringEngine = () => {
    const employees = useSelector((state: RootState) => state.employees);
    const positions = useSelector((state: RootState) => state.positions);
    const projects = useSelector((state: RootState) => state.projects);
    const positionsList = positions.map(pos => pos.name)
    const projectsList = projects.map(pro => pro.name)
    const selectedFilters = useSelector((state: RootState) => state.filteringEngine.selectedFilters);
    const filters : string[] = selectedFilters.map(f => f.selectedOption);
    const inputOpen = useSelector((state: RootState) => state.filteringEngine.inputOpen);

    const positionFilter = useSelector((state: RootState) => state.filteringEngine.positionFilter);
    const projectFilter = useSelector((state: RootState) => state.filteringEngine.projectFilter);
    const employeesFilter = useSelector((state: RootState) => state.filteringEngine.employeesFilter);

    const backgroundType = useSelector((state: RootState) => state.filteringEngine.backgroundType);
    const backgroundInputValue = useSelector((state: RootState) => state.filteringEngine.backgroundInputValue);

    const dispatch = useDispatch();

    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        clear()
        dispatch(setSelectedOption({selectedOption : 'Wszystko'}))
    },[])

    useEffect(() => {
        if(inputOpen){
            window.addEventListener("click", dropdownOnBlurClose)

            return () => {
                window.removeEventListener("click", dropdownOnBlurClose)
            }
        }
    },[inputOpen])

    const dropdownOnBlurClose = (e) => {
        if(inputRef.current && !inputRef.current.contains(e.target)){
            window.scrollTo(0, inputRef.current.offsetTop)
            dispatch(setInputOpen({inputOpen : false}));
        }
    }

    const clear = () => {
        dispatch(setInputValue({inputValue : ""}));
        dispatch(setInputOpen({inputOpen : false}));
        dispatch(setBackgroundInputValue({backgroundInputValue : ""}))
        dispatch(setBackgroundType({backgroundType : ""}))
        dispatch(setEmployeesFilter({employeesFilter : employees}))
        dispatch(setPositionFilter({positionFilter : positionsList}))
        dispatch(setProjectFilter({projectFilter : projectsList}))
    }

    const getFilter = () => filterHelper.getFilter(backgroundType, positionFilter, projectFilter, employeesFilter, backgroundInputValue)

    const applyFilter =  (): void =>{

        if (backgroundInputValue && !filters.includes(getFilter())) {
            dispatch(addSelectedFilter({
                selectedFilters: {
                    selectedOption: getFilter(),
                    categoryName: backgroundType
                }
            }))

            clear()
        }

    }

    return (
        <div className="absence-filtering-area">
            <div className="absence-filter-input-area">
                <div className="absence-filer-input">
                    <img src={searchIcon} alt="search Icon" className="input-search-icon"
                         onClick={() => dispatch(setInputOpen({inputOpen : !inputOpen})) }
                    />
                    <FilterInput inputRef={inputRef} applyFilter={applyFilter}  />
                </div>
                {
                    inputOpen && (
                        <div className="filtered-list-dropdown-menu">
                            <div className="filtered-list-dropdown">
                                <FilterDropdownMenu inputRef={inputRef} />
                            </div>
                        </div>
                    )
                }

                {
                    filters.length > 0 && (
                        <div
                            className="clear-tags"
                            onClick={() => {
                                dispatch(removeAllSelectedFilters())
                            }}
                        >
                            <p>Wyczyść</p>
                        </div>
                    )
                }

            </div>
            <AbsenceFiltersList 
                removeFilter={(item : string) => dispatch(removeSelectedFilter({selectedFilters: item}))}
            />
            
        </div>
    )
}

export default AbsenceFilteringEngine