import {FilteringEngineEnum} from "../../../../../Enums/FilteringEngineEnum";
import RenderEmployee from "./RenderEmployee/RenderEmployee";
import RenderItems from "./RenderItems/RenderItems";
import {useSelector} from "react-redux";
import RootState from "../../../../../Types/states/RootState";

const ListData = ({listItem, selectedOptions}: { listItem: string, selectedOptions: string[]}) => {

    const positionFilter = useSelector((state: RootState) => state.filteringEngine.positionFilter);
    const projectFilter = useSelector((state: RootState) => state.filteringEngine.projectFilter);

    if (listItem === FilteringEngineEnum.EMPLOYEES) {
        return <RenderEmployee selectedOptions={selectedOptions} category={FilteringEngineEnum.EMPLOYEES} />
    }

    if (listItem === FilteringEngineEnum.PROJECTS) {
        return <RenderItems listName={projectFilter} selectedOptions={selectedOptions} category={FilteringEngineEnum.PROJECTS}/>
    }

    if (listItem === FilteringEngineEnum.POSITIONS) {
        return <RenderItems listName={positionFilter} selectedOptions={selectedOptions} category={FilteringEngineEnum.POSITIONS} />
    }

    return <></>
}

export default ListData