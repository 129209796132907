import Proposal from "../../../Types/data/Proposal";
import ProposalStatusEnum from "../../../Enums/ProposalStatusEnum";
import Absence from "../../../Types/data/Absence";
import React, {FC, Suspense} from "react";
import {useSelector} from "react-redux";
import RootState from "../../../Types/states/RootState";
import CalendarDaysRow from "./CalendarDaysRow";
import {calendarActions} from "../CalendarActions/calendarActions";
import Employee from "../../../Types/data/Employee";
import DateService from "src/Services/DateService";
import { api } from "src/ReduxStore/api/api";
const CalendarProposals = React.lazy(() => import('./CalendarProposals'));
const CalendarAbsences = React.lazy(() => import('./CalendarAbsences'));

interface CalendarAbsencesRow {
    employees : Employee[],
    daysMonthArray : Date[],
}

const CalendarAbsencesRow : FC<CalendarAbsencesRow> = (props) => {

    const {employees, daysMonthArray} = props;
    const selectedMonth = useSelector((state: RootState) => state.content.selectedMonth);
    const selectedYear = useSelector((state: RootState) => state.content.selectedYear);

    const startDate = DateService.stringifyDate(new Date(selectedYear, (selectedMonth - 1), 1),true)
    const endDate = DateService.stringifyDate(new Date(selectedYear, selectedMonth, 0),true)

    const { data : ProposalList, isLoading : loadingProposal, isFetching : FetchingProposal } = api.useGetProposalsWithParamsQuery({
        employee : employees.map(employee => employee.id),
        proposalStatus : [ProposalStatusEnum.IN_PROGRESS],
        absenceStartDate : startDate,
        absenceFinishDate : endDate
    })

    const { data : AbsenceList, isLoading : loadingAbsence, isFetching : FetchingAbsence } = api.useGetAbsencesWithParamsQuery({
        employee : employees.map(employee => employee.id),
        startDate : startDate,
        endDate : endDate
    });

    return (
        <>
            {
                employees.map((employee, index: number) => {

                    const employeeProposal = ProposalList ? ProposalList.filter((Proposal : Proposal) => Proposal.employee === employee.id) : [];
                    const employeeAbsence = AbsenceList ? AbsenceList.filter((Absence : Absence) => Absence.employee === employee.id) : [];

                    

                    return (
                        <div className='calendar-proposal' key={`proposal-${index}`}
                        >
                            <div className='calendar-days-row'>
                                <CalendarDaysRow daysMonthArray={daysMonthArray} employee={employee} row={index}/>
                            </div>
                            <div className="calendar-proposal-row">
                                <Suspense fallback={<></>}>
                                    {employeeProposal && !FetchingProposal ? <CalendarProposals ProposalList={employeeProposal} row={index}/> : <></> }
                                </Suspense>
                                <Suspense fallback={<></>}>
                                    {employeeAbsence &&  !FetchingAbsence ? <CalendarAbsences AbsenceList={employeeAbsence} row={index}/> : <></>}
                                </Suspense>
                            </div>
                            <div className="current-day"
                                 style={{
                                     left: calendarActions.setCurrentDayLeft(selectedMonth, selectedYear)
                                 }}
                            />
                        </div>
                    )
                })
            }
        </>
    )
}

export default CalendarAbsencesRow