import Employee from "../../../../../../Types/data/Employee";
import EmployeeService from "../../../../../../Services/EmployeeService";
import {useDispatch, useSelector} from "react-redux";
import RootState from "../../../../../../Types/states/RootState";
import PositionService from "../../../../../../Services/PositionService.ts";
import {addSelectedFilter} from "../../../../../../ReduxStore/reduxSlices/filteringEngineSlice";
import {FilteringEngineEnum} from "../../../../../../Enums/FilteringEngineEnum";

const RenderEmployee = ({selectedOptions,category}: { selectedOptions: string[], category : FilteringEngineEnum}) => {

    const positions = useSelector((state: RootState) => state.positions);
    const employees = useSelector((state: RootState) => state.employees);
    const employeesFilter = useSelector((state: RootState) => state.filteringEngine.employeesFilter);
    const dispatch = useDispatch()

    return (
        <>
            {
                employeesFilter.map((employee: Employee, index: number) => {
                        const employeeName = EmployeeService().getEmployeeName(employee)
                        const position = PositionService.getPositionIdByUserName(employees, employeeName)

                        return (
                            <div className="d-flex justify-content-between option" key={index}
                                 onClick={() => {
                                     if(!selectedOptions.includes(employeeName)){
                                         dispatch(addSelectedFilter({
                                             selectedFilters: {
                                                 selectedOption: employeeName,
                                                 categoryName: category
                                             }
                                         }))
                                     }

                                 }}
                            >
                                <option value={employeeName} disabled={selectedOptions.includes(employeeName)}>
                                    {employeeName}
                                </option>
                                <option value={position}>
                                    {position && PositionService.getPositionById(positions, position)?.code}
                                </option>
                            </div>
                        )
                    }
                )
            }
        </>
    )
}

export default RenderEmployee