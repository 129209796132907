import classNames from "classnames";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import React, {useEffect, useRef, useState} from "react";
import ClearIcon from "@material-ui/icons/Clear";
import {useDispatch, useSelector} from "react-redux";
import RootState from "../../../../Types/states/RootState";
import SearchFilter from "../../../../Types/SearchFilter";
import {removeSelectedFilter} from "../../../../ReduxStore/reduxSlices/filteringEngineSlice";

const AppliedFilters = () => {

    const chosenOptions = useSelector((state: RootState) => state.filteringEngine.selectedFilters);
    const chosenFilterOptionList: string[] = chosenOptions.map((option: SearchFilter) => option.selectedOption);
    const dispatch = useDispatch();

    const filterBlockFiltersRef = useRef<HTMLDivElement>(null);
    const [showFilterArrows, setShowFilterArrows] = useState(false);

    useEffect(() => {
        if (chosenFilterOptionList.length >= 5) {
            setShowFilterArrows(true);
        } else {
            setShowFilterArrows(false);
        }
    }, [chosenFilterOptionList])

    const deleteFilterFromList = (filter: string): void => {
        dispatch(removeSelectedFilter({selectedFilters: filter}))
    }

    return (
        <div className={classNames({
            "mt-4": true,
            "filter-block-engine": true,
            "padding": showFilterArrows
        })}>
            {
                showFilterArrows && (
                    <div
                        className="nav-icon prev"
                        onClick={() => {
                            filterBlockFiltersRef.current && filterBlockFiltersRef.current.scrollTo({
                                left: filterBlockFiltersRef.current.scrollLeft - 100
                            });
                        }}>
                        <NavigateBeforeIcon/>
                    </div>
                )
            }

            <div className="filter-block-filters" ref={filterBlockFiltersRef}>
                <div className="chosen-filter-placeholder"/>
                {
                    chosenFilterOptionList.length > 0 && chosenFilterOptionList.map((filter: string, index: number) => (
                        <div className="chosen-filter" key={index}>
                            <p className="mb-0 ml-2 filter-title">
                                {filter}
                            </p>
                            <button className="btn p-0 clear-icon-container"
                                    onClick={() => deleteFilterFromList(filter)}>
                                <ClearIcon className="text-white"/>
                            </button>
                        </div>
                    ))
                }
            </div>

            {
                showFilterArrows && (
                    <div
                        className="nav-icon next"
                        onClick={() => {
                            filterBlockFiltersRef.current && filterBlockFiltersRef.current.scrollTo({
                                left: filterBlockFiltersRef.current.scrollLeft + 100
                            });
                        }}>
                        <NavigateNextIcon/>
                    </div>
                )
            }
        </div>
    )
}

export default AppliedFilters