import React, {ChangeEvent, FC, useEffect, useState} from "react";
import UseModal from "../../../../../Services/CustomHooks/useModal";
import {FormikProps} from "formik";
import EmployeeData from "../../../../../Shared/Partials/EmployeeData/EmployeeData";
import AbsenceType from "../../../../../Shared/Partials/AbsenceType/AbsenceType";
import HolidayAbsenceType from "../../../../../Shared/Partials/HolidayAbsenceType/HolidayAbsenceType";
import {useSelector} from "react-redux";
import RootState from "../../../../../Types/states/RootState";
import ReplacementPersonView from "../../../../../Shared/Partials/ReplacementPersonView/ReplacementPersonView";
import {ModalHeadersEnum} from "../../../../../Enums/ModalHeadersEnum";
import DesktopModalHeader from "../../../ModalComponents/ModalHeader/DesktopModalHeader";
import {DesktopModalHeaderHelper} from "../../../ModalComponents/ModalHeader/DesktopModalHeaderHelper";
import styles from "./DeclineProposalModal.module.scss"
import AbsenceDateRange from "../../../../../Shared/Partials/AbsenceDateRange/AbsenceDateRange";
import Description from "../../../../../Shared/Partials/Description/Description";
import EmployeeProjects from "../../../../../Shared/Partials/EmployeeProjects/EmployeeProjects";
import ReasonMessage from "../../../../../Shared/Partials/ReasonMessage/ReasonMessage";
import {Button} from "@devapo/devapo-ui";

interface DeclineProposalModalProp {
    formik: FormikProps<{ declinedReason: string }>
}

const DeclineProposalModal : FC<DeclineProposalModalProp> = (props) => {
    const {formik} = props;
    const {backToPrevModal} = UseModal()
    const {currentProposal} = useSelector((state: RootState) => state.modalPopup)
    const [header, setHeader] = useState<ModalHeadersEnum | undefined>()

    useEffect(() => {
        DesktopModalHeaderHelper.getModalDeclineProposalHeaderEnum(setHeader, currentProposal)
    }, [currentProposal])

    const handleReasonChange = (e : ChangeEvent<HTMLTextAreaElement>) => {
        formik.setFieldValue("declinedReason" , e.target.value)
    }

    return (
        <form className={styles.modal_block} onSubmit={formik.handleSubmit}>

            <DesktopModalHeader
                header={header}
                startDate={currentProposal.absenceStartDate}
                endDate={currentProposal.absenceFinishDate}
                absenceType={currentProposal.absenceType}
            />

            <div>
                <ReasonMessage
                    header={"Powód odrzucenia wniosku"}
                    value={formik.values.declinedReason}
                    onChange={handleReasonChange}
                />
                <EmployeeData employee={currentProposal.employee}/>
                <AbsenceType type={currentProposal.absenceType}/>
                <HolidayAbsenceType type={currentProposal.holidayType} absence={currentProposal.absenceType}/>
                <AbsenceDateRange
                    startDate={currentProposal.absenceStartDate}
                    endDate={currentProposal.absenceFinishDate}
                />
                <Description description={currentProposal.description}/>
                <EmployeeProjects proposal={currentProposal}/>
                <ReplacementPersonView replacement={currentProposal.replacement}/>
            </div>

            <div className={styles.footer}>
                <Button
                    text={"Anuluj"}
                    onClick={() => backToPrevModal()}
                    type={"button"}
                    color={"normal"}
                />
                <Button
                    text={"Odrzuć wniosek"}
                    type={"submit"}
                    color={"warning"}
                />
            </div>
        </form>
    )
}

export default DeclineProposalModal