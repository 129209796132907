import {useDispatch} from "react-redux";
import {
    setCurrentOpenProposal, clearModalHistory, undoCurrentOpenModal, setCurrentOpenModal, setHistoryBlock, setCurrentOpenAbsence
} from "../../ReduxStore/reduxSlices/ModalPopupSlice";
import Proposal from "../../Types/data/Proposal";
import {CurrentModalNameEnum} from "../../Enums/CurrentModalNameEnum";


const UseModal = () => {

    const dispatch = useDispatch();

    const changeModalHistory = (modal) => {
        dispatch(setCurrentOpenModal({currentOpenModal : modal}));
    }

    const closeAll = () => {
        dispatch(setCurrentOpenModal({currentOpenModal : null}))
        dispatch(clearModalHistory())
        dispatch(setHistoryBlock({  isHistoryBlock : false }))
    }

    const closeModal = () => {
        dispatch(setCurrentOpenModal({currentOpenModal : null}))
        dispatch(setHistoryBlock({  isHistoryBlock : false }))
    }

    const clearOpenModalHistory = () => dispatch(clearModalHistory())
    const backToPrevModal = () => {
        dispatch(undoCurrentOpenModal())
    }
    const setCurrentProposal = (currentProposal) => {
        dispatch(setCurrentOpenProposal({  currentProposal }))
    }

    const setCurrentAbsence = (currentAbsence) => {
        dispatch(setCurrentOpenAbsence({  currentAbsence }))
    }

    const historyButtonReviewProposal = (proposal : Proposal) => {
        dispatch(setHistoryBlock({  isHistoryBlock : true }))
        setCurrentProposal(proposal)
        changeModalHistory(CurrentModalNameEnum.REVIEW_CREATED_PROPOSAL)
    }

    const mobileButtonReviewProposal = (proposal : Proposal,history : boolean) => {
        dispatch(setHistoryBlock({  isHistoryBlock : history }))
        setCurrentProposal(proposal)
        changeModalHistory(CurrentModalNameEnum.MOBILE_REVIEW_CREATED_PROPOSAL)
    }

    const buttonReviewProposal = (proposal : Proposal) => {
        dispatch(setHistoryBlock({  isHistoryBlock : false }))
        setCurrentProposal(proposal)
        changeModalHistory(CurrentModalNameEnum.REVIEW_CREATED_PROPOSAL)
    }

    const buttonEditProposal = (proposal : Proposal) => {
        dispatch(setHistoryBlock({  isHistoryBlock : false }))
        setCurrentProposal(proposal)
        changeModalHistory(CurrentModalNameEnum.EDIT_PROPOSAL)
    }

    const buttonCancelProposal = (proposal : Proposal) => {
        dispatch(setHistoryBlock({  isHistoryBlock : false }))
        setCurrentProposal(proposal)
        changeModalHistory(CurrentModalNameEnum.CANCEL_PROPOSAL)
    }

    const buttonOpenDeclineReceivedProposalModal = (proposal : Proposal) => {
        dispatch(setHistoryBlock({  isHistoryBlock : false }))
        setCurrentProposal(proposal)
        changeModalHistory(CurrentModalNameEnum.DECLINE_RECEIVED_PROPOSAL)
    }

    const buttonOpenAcceptReceivedProposalModal = (proposal : Proposal) => {
        dispatch(setHistoryBlock({  isHistoryBlock : false }))
        setCurrentProposal(proposal)
        changeModalHistory(CurrentModalNameEnum.ACCEPT_RECEIVED_PROPOSAL)
    }

    const buttonOpenMobileAcceptReceivedProposalModal = (proposal : Proposal) => {
        dispatch(setHistoryBlock({  isHistoryBlock : false }))
        setCurrentProposal(proposal)
        changeModalHistory(CurrentModalNameEnum.MOBILE_ACCEPT_RECEIVED_PROPOSAL)
    }

    const buttonOpenMobileDeclineReceivedProposalModal = (proposal : Proposal) => {
        dispatch(setHistoryBlock({  isHistoryBlock : false }))
        setCurrentProposal(proposal)
        changeModalHistory(CurrentModalNameEnum.MOBILE_DECLINE_RECEIVED_PROPOSAL)
    }


    return {
        changeModalHistory,
        setCurrentAbsence,
        backToPrevModal,
        setCurrentProposal,
        closeAll,
        clearOpenModalHistory,
        historyButtonReviewProposal,
        buttonReviewProposal,
        buttonEditProposal,
        buttonCancelProposal,
        buttonOpenDeclineReceivedProposalModal,
        mobileButtonReviewProposal,
        buttonOpenAcceptReceivedProposalModal,
        buttonOpenMobileAcceptReceivedProposalModal,
        buttonOpenMobileDeclineReceivedProposalModal
    }
}

export default UseModal;