import { combineReducers } from "redux";
import contentReducer from "./../reduxSlices/contentSlice";
import mobileContentReducer from "../reduxSlices/mobileContentSlice";
import errorReducer from "./../reduxSlices/errorSlice";
import employeeReducer from "../reduxSlices/employeeSlice";
import modalReducer from "./modal/modalReducer";
import userReducer from "./../reduxSlices/userSlice"
import positionReducer from "../reduxSlices/positionSlice";
import projectReducer from "../reduxSlices/projectSlice";
import proposalReducer from "./../reduxSlices/proposalSlice";
import modalPopupReducer from "./../reduxSlices/ModalPopupSlice"
import absenceReducer from "./../reduxSlices/absenceSlice";
import filteringEngineReducer from "../reduxSlices/filteringEngineSlice"
import tableReducer from "./table/tableReducer";
import { api } from "../api/api";
import { employeeApi } from "../api/employeeApi";

const rootReducer = combineReducers({
    error: errorReducer,
    absences: absenceReducer,
    employees: employeeReducer,
    proposals: proposalReducer,
    modals: modalReducer,
    tables : tableReducer,
    content: contentReducer,
    mobileContent: mobileContentReducer,
    user: userReducer,
    projects: projectReducer,
    positions: positionReducer,
    modalPopup : modalPopupReducer,
    filteringEngine : filteringEngineReducer,
    [api.reducerPath]: api.reducer,
    [employeeApi.reducerPath]: employeeApi.reducer,
});

export default rootReducer;
