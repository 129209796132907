import React, {useEffect} from "react";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {useDispatch, useSelector} from "react-redux";
import RootState from "../../../Types/states/RootState";
import toast from "react-hot-toast";
import DateFormatService from "src/Services/DateFormatService";
import {CurrentModalNameEnum} from "../../../Enums/CurrentModalNameEnum";
import UseModal from "../../../Services/CustomHooks/useModal";
import {CreateProposalModalContentAbsenceResetState} from "../../../ReduxStore/reduxSlices/modal/createProposalSlice";
import MobileCreateProposalModal from "./MobileCreateProposalModal/MobileCreateProposalModal";
import {useFormik} from "formik";
import {withFormikDevtools} from "formik-devtools-extension";
import AbsenceTypeEnum from "../../../Enums/AbsenceTypeEnum";
import { proposalYupValidation } from "src/Shared/Yup/ProposalYupValidation";
import {CreateProposalApiType, CreateProposalFormik } from "src/Types/states/modal/CreateProposalFormik";
import { api } from "src/ReduxStore/api/api";
import {RtkQueryHelper} from "../../../helpers/rtk-query.helper";
import styles from "./../Modals.module.scss";
import proposalStyles from "./MobileCreateProposal.module.scss"
import {Button, Modal} from "@devapo/devapo-ui"

const MobileCreateProposal = () =>{

    const [createProposal, result] = api.useCreateProposalMutation()
    const createProposalModalContent = useSelector((state: RootState) => state.modals.createProposalModalContent);
    const loggedInUserID = useSelector((state:RootState) => state.user.loggedInUserID);
    const dispatch = useDispatch();
    const role = useSelector((state: RootState) => state.user.role);
    const {toShortDate} = DateFormatService();
    const {currentOpenModal} = useSelector((state : RootState) => state.modalPopup);
    const {closeAll,changeModalHistory} = UseModal();

    useEffect(() => {
        if(RtkQueryHelper.checkResultIsFulfilled(result)){
            closePopUp()
        }
    },[result])

    const formik = useFormik({
        initialValues: {
            employee: createProposalModalContent.employee,
            requester: loggedInUserID,
            absenceType: createProposalModalContent.absenceType,
            holidayType: createProposalModalContent.holidayType,
            absenceStartDate: createProposalModalContent.absenceStartDate,
            absenceFinishDate: createProposalModalContent.absenceFinishDate,
            description: createProposalModalContent.description,
            replacement: createProposalModalContent.replacement,
            role
        } as CreateProposalFormik & {role : string},
        validationSchema : proposalYupValidation,
        onSubmit: values => {
            const {role, ...newProposal } = values;
            if(result.status !== 'pending'){
                tryCreateProposal(newProposal as CreateProposalApiType)
            }
        },
    });

    useEffect(() => {
        formik.setFieldValue("employee", createProposalModalContent.employee)
        formik.setFieldValue("absenceStartDate", createProposalModalContent.absenceStartDate)
        formik.setFieldValue("absenceFinishDate", createProposalModalContent.absenceFinishDate)
    },[createProposalModalContent])

    useEffect(() => {
        if(formik.values.absenceType === AbsenceTypeEnum.HOLIDAY){
            formik.setFieldValue("replacement",null)
            formik.setFieldValue("holidayType",null)
        }
        if(formik.values.absenceType === AbsenceTypeEnum.SICK_LEAVE){
            formik.setFieldValue("holidayType",null)
        }
    },[formik.values.absenceType])

    useEffect(() => {
        process.env.NODE_ENV !== 'production' && withFormikDevtools(formik);
    },[])

    const tryCreateProposal = (newProposal : CreateProposalApiType) => {
        createProposal(newProposal);
    }

    const showSuccessToast = () => {
        toast.success(`Twoja nieobecność w dn. 
        ${formik.values.absenceStartDate && toShortDate(formik.values.absenceStartDate).props.children.join('')} -
        ${formik.values.absenceFinishDate && toShortDate(formik.values.absenceFinishDate).props.children.join('')} została dodana`);
    }

    const closePopUp = () => {
        closeAll();
        showSuccessToast();
        formik.resetForm()
        dispatch(CreateProposalModalContentAbsenceResetState())
    }

    return (
        <div>
            <Button
                icon={
                    <AddCircleOutlineIcon className={proposalStyles.add_icon} />
                }
                type={"button"}
                color={"success"}
                className={" " + proposalStyles.open_modal}
                onClick={() => changeModalHistory(CurrentModalNameEnum.MOBILE_CREATE_PROPOSAL)}
            />
            <Modal
                isOpen={currentOpenModal === CurrentModalNameEnum.MOBILE_CREATE_PROPOSAL}
                onClose={() => closeAll()}
                modalClass={styles.modal}
                backdropClass={styles.backdrop}
            >
                <MobileCreateProposalModal formik={formik} result={result} />
            </Modal>
        </div>
    )
}
export default MobileCreateProposal;