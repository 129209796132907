import {KeycloakService} from "../Layout/Auth/KeycloakService";

export default class FetchService {
    static parseParam(param:string):string {
        return param
            .replaceAll('{', '%7B')
            .replaceAll('}', '%7D')
            .replaceAll('"', '%22')
            .replaceAll('[', '%5B')
            .replaceAll(']', '%5D');
    }

    static async failableFetch(input:RequestInfo, init?:RequestInit):Promise<Response> {

        const requestInit = {
            ...init
        }

        if (requestInit?.body) {
            requestInit.body = JSON.stringify(requestInit.body);
        }

        // TODO dodać profile - https://devapo.atlassian.net/jira/software/projects/SR/issues/SR-640
        requestInit.headers = {
            'Authorization': `Bearer ${KeycloakService.token()}`,
            'content-type': 'application/json'
        }

        const proxy = "/api";
        const response = await fetch(proxy + input, requestInit);
        const status = response.status;

        if (!((status >= 200) && (status < 300))) {
            const message = (await response.json()).message as string;
            throw new Error(message);
        }

        return response;
    }

    static async fetch(...params:any[]):Promise<Response> {
        const firstlessParams = [...params];
        firstlessParams.shift();

        // TODO dodać profile - https://devapo.atlassian.net/jira/software/projects/SR/issues/SR-640
        if( KeycloakService.isTokenExpired() ){
            await KeycloakService.updateToken()
            return await this.failableFetch(`/${params[0]}`, ...firstlessParams);
        }

        return this.failableFetch(`/${params[0]}`, ...firstlessParams);
    }

    static async fetchWithParams(params:any, ...rest) {
        const firstlessRest = [...rest];
        firstlessRest.shift();
        let newParams:string = '';

        Object.keys(params).forEach((key:string) => {
            newParams += `${key}=${this.parseParam(JSON.stringify(params[key]))}&`;
        })

        // TODO dodać profile - https://devapo.atlassian.net/jira/software/projects/SR/issues/SR-640
        if( KeycloakService.isTokenExpired() ){
            await KeycloakService.updateToken()
            return await this.failableFetch(`/${rest[0]}?${newParams}`, ...firstlessRest);
        }

        return this.failableFetch(`/${rest[0]}?${newParams}`, ...firstlessRest);
    }

    static encodeParams(params : Object ) : string{
        const filters = JSON.stringify(params);
        return encodeURI(filters)
    }


}
