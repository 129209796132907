import MobileTopBar from "./MobileTopBar/MobileTopBar";
import MobileAbsenceBlock from "./MobileAbsenceBlock/MobileAbsenceBlock";
import React from "react";
import Modals from "./Modals/Modals";
import {useSelector} from "react-redux";
import RootState from "../Types/states/RootState";
import classNames from "classnames";
import styles from "./MobileComponents.module.scss"

const MobileComponents = () => {

    const {currentOpenModal} = useSelector((state : RootState) => state.modalPopup);

    return (
        <>
            <div
                className={classNames({
                    [styles.modal_open] : currentOpenModal !== null
                })}
            >
                <MobileTopBar/>
                <MobileAbsenceBlock/>
            </div>


            <Modals />
        </>
    )
}

export default MobileComponents