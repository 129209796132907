import React, {FC, FunctionComponent} from "react";
import { useDispatch, useSelector } from "react-redux";
import checkAbsenceTypeEnum from "../../helpers/checkAbsenceTypeEnum";
import Proposal from "../../Types/data/Proposal";
import SortOrder from "../../Types/SortOrder";
import RootState from "../../Types/states/RootState";
import UiTable from "../Shared/Table/UiTable";
import ShowMyProposalsHistoryHoverIcons from "./ShowMyProposalsHistoryHoverIcons/ShowMyProposalsHistoryHoverIcons";
import ShowProposalHistoryStatus from "./ShowProposalHistoryStatus/ShowProposalHistoryStatus";
import DateService from "../../Services/DateService";
import generateCustomDateComparator from "src/helpers/customDateComparator";
import UseModal from "../../Services/CustomHooks/useModal";
import {
    selectMyProposalTabHistorySortOrder,
    setMyProposalTabHistorySortBy
} from "../../ReduxStore/reduxSlices/table/myProposalTabSlice";

export interface IMyProposalsHistoryTableData {
    proposalStatus: string
    absenceStartDate: Date;
    absenceFinishDate: Date;
    absenceType: string;
    description: string;
    '_proposal': Proposal;
}

interface MyProposalsHistoryTableProps {
    data : Proposal[]
}

const MyProposalsHistoryTable: FC<MyProposalsHistoryTableProps> = (props) => {

    const {data} = props;

    const sortBy = useSelector((state:RootState) => state.tables.myProposalTabSlice.myProposalTabHistorySortBy);
    const sortOrder = useSelector((state:RootState) => state.tables.myProposalTabSlice.myProposalTabHistorySortOrder);
    const dispatch = useDispatch();
    const {historyButtonReviewProposal} = UseModal();

    const tableData = data.map((proposal:Proposal) => {
        const {proposalStatus, absenceFinishDate, absenceStartDate, absenceType, description} = proposal;

        return {
            proposalStatus,
            absenceType: checkAbsenceTypeEnum(absenceType),
            absenceStartDate: absenceStartDate,
            absenceFinishDate: absenceFinishDate,
            description,
            '_proposal': proposal
        };
    }) as IMyProposalsHistoryTableData[];

    const getPropertyName = (property:string):string => {
        switch(property) {
            case 'absenceStartDate':
                return 'Data od';
            case 'absenceFinishDate':
                return 'Data do';
            case 'absenceType':
                return 'Typ wniosku';
            case 'description':
                return 'Opis';
            case 'proposalStatus':
                return 'Status';
            default:
                return '';
        }
    };

    const setSort = (newSortBy:string) => {
        if (newSortBy === sortBy) {
            const newSortOrder:SortOrder = sortOrder === 'asc' ? 'desc' : 'asc';

            dispatch(selectMyProposalTabHistorySortOrder({myProposalTabHistorySortOrder : newSortOrder }))

        } else {

            dispatch(selectMyProposalTabHistorySortOrder({myProposalTabHistorySortOrder : "asc" }))
            dispatch(setMyProposalTabHistorySortBy({myProposalTabHistorySortBy: newSortBy }))

        }
    };

    const onElementClick = (element:IMyProposalsHistoryTableData) => {
        const proposal = element._proposal;
        historyButtonReviewProposal(proposal)
    }

    return (
        <div className="proposals-table">
            <UiTable<IMyProposalsHistoryTableData>
                data={tableData}
                sortBy={sortBy as keyof IMyProposalsHistoryTableData}
                sortOrder={sortOrder}
                getPropertyName={getPropertyName}
                setSort={setSort}
                config={{
                    customColumnWidths: {
                        'description': '500px'
                    },
                    customBodyComponents: {
                        proposalStatus : (text:string, element:IMyProposalsHistoryTableData) => <ShowProposalHistoryStatus status={element._proposal.proposalStatus}/>,
                        absenceStartDate : ( date : Date , element:IMyProposalsHistoryTableData) => <span className={"span-date"} >{DateService.stringifyDate(date)}</span>,
                        absenceFinishDate : ( date : Date , element:IMyProposalsHistoryTableData) => <span className={"span-date"} >{DateService.stringifyDate(date)}</span>
                    },
                    customComparators: {
                        absenceStartDate: generateCustomDateComparator<IMyProposalsHistoryTableData>('absenceStartDate'),
                        absenceFinishDate: generateCustomDateComparator<IMyProposalsHistoryTableData>('absenceFinishDate')
                    },
                    onElementClick,
                    onHoverComponent: (element:IMyProposalsHistoryTableData) => <ShowMyProposalsHistoryHoverIcons element={element}/>
                }}
            />
        </div>
    );
}

export default MyProposalsHistoryTable;
