import ProposalStatusEnum from "../../../Enums/ProposalStatusEnum";
import acceptedIcon from "../../../assets/icons/accepted-icon.svg";
import cancelledIcon from "../../../assets/icons/cancelled-icon.svg";
import React from "react";
import AbsenceTypeFormatterService from "../../../Services/AbsenceTypeFormatterService";
import AbsenceTypeEnum from "../../../Enums/AbsenceTypeEnum";


const ActionsHistoryProposalStatus = ({proposalStatus, absenceType} : {proposalStatus: ProposalStatusEnum | undefined, absenceType : AbsenceTypeEnum}) => (
    <div className="d-flex align-items-center ml-3">
        {
            proposalStatus === ProposalStatusEnum.ACCEPTED ?
                <img className="mt-0 accepted-image" src={acceptedIcon} alt=""/>
                : <img className="mt-0 cancel-image" src={cancelledIcon} alt=""/>
        }
        <p className="mb-0 ml-2">{AbsenceTypeFormatterService().showStatus(absenceType)}</p>
    </div>
)

export default ActionsHistoryProposalStatus