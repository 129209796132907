import {CurrentModalNameEnum} from "../../Enums/CurrentModalNameEnum";
import AddProposal from "@material-ui/icons/AddCircleOutline";
import React from "react";
import UseModal from "../../Services/CustomHooks/useModal";
import "./Header.scss"

const Header = () => {

    const {changeModalHistory} = UseModal()

    return (
        <div className="top-heading">
            <h4 className="top-heading_h4">Planer nieobecności</h4>
            <div className="flex"></div>
            <button className="add-event" onClick={() => changeModalHistory(CurrentModalNameEnum.CREATE_PROPOSAL)}>
                <AddProposal className="add-event_span"/>
                <p className="mb-0">Dodaj nieobecność</p>
            </button>
        </div>
    )
}

export default Header