import HolidayTypeEnum from "../Enums/HolidayTypeEnum";

const checkHolidayTypeEnum = (type: HolidayTypeEnum) => {
    switch (type){
        case HolidayTypeEnum.LEISURE : return "Wypoczynkowy";
        case HolidayTypeEnum.AS_REQUESTED : return "Na żądanie";
        case HolidayTypeEnum.OCCASIONAL : return "Okolicznościowy";
        case HolidayTypeEnum.FREE : return "Bezpłatny";
        case HolidayTypeEnum.TRAINING : return "Szkoleniowy";
    }
    return "";
};

export default checkHolidayTypeEnum;
