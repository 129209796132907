import React from "react";
import {useSelector} from "react-redux";
import RootState from "../../../Types/states/RootState";
import UseModal from "../../../Services/CustomHooks/useModal";
import ReviewAbsenceModal from "./ReviewAbsenceModal/ReviewAbsenceModal";
import {CurrentModalNameEnum} from "../../../Enums/CurrentModalNameEnum";
import {Modal} from "@devapo/devapo-ui"
import styles from "../Modals.module.scss";


const ReviewAbsence = () => {
    const {currentOpenModal} = useSelector((state : RootState) => state.modalPopup);
    const {closeAll} = UseModal()


    return (
        <>
            <Modal
                isOpen={currentOpenModal === CurrentModalNameEnum.REVIEW_ABSENCE}
                onClose={closeAll}
                modalClass={styles.modal}
                backdropClass={styles.backdrop}
            >
                <ReviewAbsenceModal/>
            </Modal>
        </>
    )
}
export default ReviewAbsence;