import './AbsenceBlock.scss';
import React from "react";
import AbsenceNavigationBar from "../../Shared/AbsenceNavigationBar/AbsenceNavigationBar";
import {useSelector} from "react-redux";
import RootState from "../../Types/states/RootState";
import AbsenceBlockTabEnum from "../../Enums/AbsenceBlockTabEnum";
import AbsencesTab from "./AbsenceBlockTab/AbsencesTab";
import ProposalsTab from "./AbsenceBlockTab/ProposalsTab";
import MyProposalsTab from "./AbsenceBlockTab/MyProposalsTab";
import ActionsHistoryTab from "./AbsenceBlockTab/ActionsHistoryTab";
import {RoleEnum} from "../../Enums/RoleEnum";

const AbsenceBlock = () => {

    const role = useSelector((state: RootState) => state.user.role);
    const selectedBlockTab = useSelector((state: RootState) => state.content.selectedBlockTab);

    return (
        <div className="mb-5 mt-5">
            <div className="top-bar">
                <AbsenceNavigationBar isMobile={false}/>
                <hr className="w-100"/>

            </div>

            {
                selectedBlockTab === AbsenceBlockTabEnum.ABSENCES ? (
                    <AbsencesTab/>
                ) :
                selectedBlockTab === AbsenceBlockTabEnum.PROPOSALS && role !== RoleEnum.EMPLOYEE ? (
                    <ProposalsTab/>
                ) :
                selectedBlockTab === AbsenceBlockTabEnum.MY_PROPOSALS ? (
                    <MyProposalsTab/>
                ) :
                selectedBlockTab === AbsenceBlockTabEnum.ACTIONS_HISTORY && role !== RoleEnum.EMPLOYEE && (
                    <ActionsHistoryTab/>
                )
            }

        </div>
    )
}
export default AbsenceBlock;
