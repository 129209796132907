import Proposal from "../../../../Types/data/Proposal";
import ProposalStatus from "../../../../Shared/ProposalStatus/ProposalStatus";
import React, {FC, useEffect, useState} from "react";
import DateFormatService from "../../../../Services/DateFormatService";
import {CurrentModalNameEnum} from "../../../../Enums/CurrentModalNameEnum";
import UseModal from "../../../../Services/CustomHooks/useModal";
import MobileAbsenceType from "../../../../Shared/Partials/MobileAbsenceType";
import {Pagination} from "@material-ui/core";

interface MobileActionsHistoryTableProps{
    list : Proposal[]
}

const MobileActionsHistoryTable : FC<MobileActionsHistoryTableProps> = (props) => {
    const {list} = props;
    const {toShortDate} = DateFormatService();
    const {setCurrentProposal,changeModalHistory} = UseModal()

    const [currentPage, setCurrentPage] = useState(1)
    const [pageData,setPageData] = useState<Proposal[]>([])

    useEffect(() => {
        setPageData(list.slice(0,10))
        setCurrentPage(1)
    },[list])

    const changePage = (page : number) => {
        setCurrentPage(page)
        const start = (page * 10) - 10;
        const end = (page * 10);
        setPageData(list.slice(start,end))
    }

    const handleClick = (e,page) => changePage(page)

    return (
        <div className="employee-list mb-5">
            {
                pageData && pageData.map((proposal : Proposal, index : number) => (
                    <div className="my-proposal-list" key={index} onClick={() => {
                        setCurrentProposal(proposal);
                        changeModalHistory(CurrentModalNameEnum.MOBILE_ACTIONS_HISTORY_REVIEW_PROPOSAL)
                    }}>
                        <div className="employee-record" >
                            <div className="d-flex align-items-center">
                                <ProposalStatus proposalStatus={proposal.proposalStatus} text={false} />
                                <MobileAbsenceType proposal={proposal} />
                            </div>
                            <div className="d-flex short-date">
                                {toShortDate(proposal.absenceStartDate)}
                                -
                                {toShortDate(proposal.absenceFinishDate)}
                            </div>
                        </div>
                    </div>
                ))
            }

            <Pagination
                className={"mt-2 pb-5"}
                page={currentPage}
                count={Math.ceil(list.length / 10)}
                onChange={handleClick}
            />

        </div>
    )
}

export default MobileActionsHistoryTable