import AbsenceBlockTabEnum from "../../Enums/AbsenceBlockTabEnum";
import ContentState from "../../Types/states/ContentState";

const contentInitialState:ContentState = {
    selectedMonth: new Date().getMonth() + 1,
    selectedYear: new Date().getFullYear(),
    selectedBlockTab: AbsenceBlockTabEnum.ABSENCES,
};

export default contentInitialState;
