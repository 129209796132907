import FilteringEngineService from "../../../../../Services/FilteringEngineService";
import ListData from "../ListData/ListData";
import {useSelector} from "react-redux";
import RootState from "../../../../../Types/states/RootState";

const AllOptions = ({selectedOptions}: { selectedOptions: string[] }) => {

    const employeesFilter = useSelector((state: RootState) => state.filteringEngine.employeesFilter);
    const positionFilter = useSelector((state: RootState) => state.filteringEngine.positionFilter);
    const projectFilter = useSelector((state: RootState) => state.filteringEngine.projectFilter);
    const filterOptions = useSelector((state: RootState) => state.filteringEngine.filterOptions);

    const {checkIfListIsEmpty} = FilteringEngineService();

    const filteringServiceCheckList = (option: string) => checkIfListIsEmpty(
        employeesFilter,
        positionFilter,
        projectFilter,
        option
    )

    if (filterOptions.every((option) => !filteringServiceCheckList(option))) {
        return <div>Brak wyników</div>
    }

    return (
        <>
            {
                filterOptions.map((item: string, index: number) => {
                    if (filteringServiceCheckList(item)) {
                        return (
                            <div className="separated-list mb-4" key={index}>
                                <option value={item} disabled className="disabled-option">
                                    {item}
                                </option>
                                <div className="ml-3 mr-3">
                                    <ListData selectedOptions={selectedOptions} listItem={item}/>
                                </div>
                            </div>
                        )
                    }
                })
            }
        </>
    )


}

export default AllOptions