import DataService from "../../Services/DataService";
import loadProposals from "./loadProposals";
import {createAsyncThunk} from "@reduxjs/toolkit";
import Proposal from "../../Types/data/Proposal";
import {reduxSliceTypeEnum} from "../reduxSliceTypeEnum/reduxSliceTypeEnum";
import loadAbsences from "./loadAbsences";

const createProposal = createAsyncThunk(reduxSliceTypeEnum.CREATE_PROPOSAL, async({proposal, callback} : {proposal : Proposal, callback : () => void}, {dispatch}) => {
    await DataService.postProposal(proposal);

    dispatch(loadProposals());
    dispatch(loadAbsences())
    callback()
})

export default createProposal;
