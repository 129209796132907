import AbsenceTypeEnum from "../../../Enums/AbsenceTypeEnum";
import React from "react";
import AbsenceTypeFormatterService from "../../../Services/AbsenceTypeFormatterService";
import classNames from "classnames";
import styles from "./AbsenceTypes.module.scss"

const AbsenceTypes = () => {
    const absenceFormatter = AbsenceTypeFormatterService();

    return (
        <div className={styles.absence_types}>
            {
                Object.values(AbsenceTypeEnum).map((itemStatus, index) => {

                    const absenceStatusColor = absenceFormatter.defineColorOfAbsenceStatus(AbsenceTypeEnum[itemStatus]);

                    return (
                        <div className={styles.type} key={index}>
                            <div
                                className={classNames({
                                    [styles.color]: true,
                                    [styles[`${absenceStatusColor}`]]: absenceStatusColor
                                })}
                            />
                            <p>{absenceFormatter.showStatus(AbsenceTypeEnum[itemStatus])}</p>
                        </div>
                    )
                })
            }
            <div className={styles.type}>
                <div
                    className={classNames({
                        [styles.color_dashed]: true,
                    })}
                />
                <p className="m-0">Oczekuje na akceptację</p>
            </div>
        </div>
    )
}

export default AbsenceTypes