import MobileActionsHistoryReviewProposal
    from "./EmployerModals/MobileActionsHistoryReviewProposal/MobileActionsHistoryReviewProposal";
import MobileReviewAbsence from "./MobileReviewAbsence/MobileReviewAbsence";
import MobileAcceptProposal from "./EmployerModals/MobileAcceptProposal/MobileAcceptProposal";
import {RenderMobileDeleteModal} from "./EmployerModals/MobileDeleteAbsence/MobileDeleteAbsence";
import MobileReviewReceivedProposal from "./EmployerModals/MobileReviewReceivedProposal/MobileReviewReceivedProposal";
import {RenderMobileDeclineModal} from "./EmployerModals/MobileDeclineProposal/MobileDeclineProposal";
import MobileReviewCreatedProposal from "./EmployeeModals/MobileReviewCreatedProposal/MobileReviewCreatedProposal";
import {RenderMobileCancelProposal} from "./EmployeeModals/MobileCancelProposal/MobileCancelProposal";
import RenderMobileEditModal from "./EmployeeModals/MobileEditProposal/MobileEditProposal";
import React from "react";
import MobileCreateProposal from "./MobileCreateProposal/MobileCreateProposal";

const Modals = () => {

    return (
        <>
            <MobileActionsHistoryReviewProposal />

            <MobileCreateProposal/>

            <MobileReviewAbsence/>
            <RenderMobileDeleteModal />

            <RenderMobileDeclineModal />
            <MobileAcceptProposal />
            <MobileReviewReceivedProposal />

            <MobileReviewCreatedProposal />
            <RenderMobileCancelProposal />
            <RenderMobileEditModal />
        </>
    )
}

export default Modals