import React, { useEffect, useState } from "react";
import { employeeApi } from "../../ReduxStore/api/employeeApi";
import { ReactComponent as DevapoLogoAnimate } from "../../assets/images/logo-devapo-animate.svg";
import styles from "./LoaderLayout.module.scss"

interface LoadingLayoutProps {
  children : React.ReactNode
}

const LoadingLayout = (props: LoadingLayoutProps): JSX.Element => {
  const {children} = props;
  const [pageIsLoading, setPageIsLoading] = useState(true)
  const { isFetching, isLoading} =  employeeApi.useGetAllActiveEmployeesQuery()

  useEffect(() => {
    if(!isFetching && !isLoading){
      setTimeout(() => {
        setPageIsLoading(false)
      },1000)
    }
  },[isFetching, isLoading])

  const renderLoading = (): JSX.Element | null => {
    if(pageIsLoading){
      return (
        <div className={styles.loader} >
          <DevapoLogoAnimate />
        </div>
      )
    }
    return null;
  }

  return (
    <>
      {renderLoading()}
      {children}
    </>
  )
}

export default LoadingLayout