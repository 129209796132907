import React, {ReactElement, useEffect, useState} from 'react';
import './App.css';
import {batch, useDispatch, useSelector} from 'react-redux';
import loadPositions from './ReduxStore/thunks/loadPositions';
import loadProjects from './ReduxStore/thunks/loadProjects';
import toast, {Toaster} from "react-hot-toast";
import {catchUnhandledError} from "./ReduxStore/reduxSlices/errorSlice";
import MobileComponents from "./MobileComponents/MobileComponents";
import DesktopComponents from "./DesktopComponents/DesktopComponents";
import RootState from "./Types/states/RootState";
import "@devapo/devapo-ui/dist/index.css";
import {CurrentModalNameEnum} from "./Enums/CurrentModalNameEnum";
import UseModal from "./Services/CustomHooks/useModal";
import DataService from "./Services/DataService";
import {ContentSelectAbsenceBlockTab} from "./ReduxStore/reduxSlices/contentSlice";
import AbsenceBlockTabEnum from "./Enums/AbsenceBlockTabEnum";
import proposalStatusEnum from "./Enums/ProposalStatusEnum";
import {RoleEnum} from "./Enums/RoleEnum";

const App = () : ReactElement | null => {
    const [windowSize, setWindowSize] = useState({width: 0, height: 0});
    const loggedInUserId : number = useSelector((state:RootState) => state.user.loggedInUserID);
    const role = useSelector((state:RootState) => state.user.role);
    const dispatch = useDispatch();
    const {setCurrentProposal, changeModalHistory} = UseModal()

    useEffect(() => {

        window.addEventListener('error', (event:ErrorEvent) => {
            dispatch(catchUnhandledError(event.error))
        });

        window.addEventListener("resize", handleResize);
        handleResize();

        batch(() => {
            dispatch(loadPositions());
            dispatch(loadProjects());
        })

        // TODO dodać profile - https://devapo.atlassian.net/jira/software/projects/SR/issues/SR-640
/*        if(process.env.NODE_ENV !== 'production'){ // Fake user
            batch(() => {
                dispatch(changeUserRole())
                dispatch(changeLoggedUser({user : "Testowski Tomasz"}))
                dispatch(changeLoggedIDUser({id : 1}))
                dispatch(changeUserEmail({email : "tt@devapo.pl"}))
                dispatch(changeUserFamilyName({family_name : "Testowski"}))
                dispatch(changeUserGivenName({given_name : "Tomasz"}))
            })
        }*/

        //TODO dodać router na każdy modal - https://devapo.atlassian.net/jira/software/projects/SR/issues/SR-672
        const pathSegments = window.location.hash.split("/");

        if (pathSegments.length == 3 && pathSegments[1] === "proposals") {
            goToProposalReviewModal(Number(pathSegments[2]));
        }

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }

    const goToProposalReviewModal = (proposalId: number) : void => {
        let isOpen = false;
        let access = false;

        DataService.getProposal(proposalId).then(proposal => {

            if(role !== RoleEnum.EMPLOYER){
                access = false;
                return;
            }
            access = true;

            if(proposal.proposalStatus !== proposalStatusEnum.IN_PROGRESS){
                isOpen =  false;
                return;
            }

            dispatch(ContentSelectAbsenceBlockTab({selectedBlockTab: AbsenceBlockTabEnum.PROPOSALS}))

            batch(() => {
                setCurrentProposal(proposal)
                if(window.innerWidth < 600){
                    changeModalHistory(CurrentModalNameEnum.MOBILE_REVIEW_RECEIVED_PROPOSAL)
                    isOpen = true;
                } else {
                    changeModalHistory(CurrentModalNameEnum.REVIEW_RECEIVED_PROPOSAL)
                    isOpen = true;
                }
            })

        }).then(() => {
            if(!access){
                toast.error(`Nie masz dostępu do akceptowania wniosków`);
            } else if (!isOpen){
                toast.error(`Wniosek został zaakceptowany`);
            }
        })

    }

    if(loggedInUserId <= 0){
        return null
    }

    const renderView = () => {

        if (windowSize.width < 600) {
            return <MobileComponents />;
        } else {
            return <DesktopComponents />
        }
    }

    return (
        <>
            <div><Toaster/></div>
            {renderView()}
        </>
    )
}

export default App;
