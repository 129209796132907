import optionsDropDownIcon from "../../../../assets/icons/optionsDropDownIcon.svg";
import React, { useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import RootState from "../../../../Types/states/RootState";
import {setSelectedOption} from "../../../../ReduxStore/reduxSlices/filteringEngineSlice";

const OptionsDropDown = () => {

    const selectedOption = useSelector((state: RootState) => state.filteringEngine.selectedOption);
    const filterOptions = useSelector((state: RootState) => state.filteringEngine.filterOptions);

    const [detailsOpen, setDetailsOpen] = useState<boolean>(false)
    const optionsRef = useRef<HTMLDivElement>(null)
    
    const dispatch = useDispatch()

    useEffect(() => {
        if (detailsOpen) {
            window.addEventListener("click", onOverClickCloseOptionsDropDown)
        } else {
            window.removeEventListener("click", onOverClickCloseOptionsDropDown)
        }
    }, [detailsOpen])

    const handleChange = (item: string): void => {
        setDetailsOpen(false)
        dispatch(setSelectedOption({selectedOption : item}))
    }

    const onOverClickCloseOptionsDropDown = (e) => {
        if (optionsRef.current && !optionsRef.current.contains(e.target)) {
            setDetailsOpen(false)
        }
    }

    return (
        <div className="options-dropdown" ref={optionsRef}>
            <button
                className="options-button"
                onClick={() => setDetailsOpen(!detailsOpen)}
            >
                <p>{selectedOption}</p>
                <img src={optionsDropDownIcon} alt="drop down icon"
                     className={`${detailsOpen ? "dropdown-open-icon" : ""}`}/>
            </button>
            {
                detailsOpen && (
                    <ul className="options-dropdown-list">
                        {
                            filterOptions.map((item: string, index: number) => (
                                <li key={index} value={item} onClick={() => handleChange(item)} className="list-item">
                                    <p>{item}</p>
                                </li>
                            ))
                        }
                    </ul>
                )
            }
        </div>
    )
}

export default OptionsDropDown