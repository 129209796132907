import editIcon from "../../../../../assets/icons/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/icons/delete-red-icon.svg";
import React, {useEffect, useState} from "react";
import ProposalStatusEnum from "../../../../../Enums/ProposalStatusEnum";
import {useSelector} from "react-redux";
import RootState from "../../../../../Types/states/RootState";
import UseModal from "../../../../../Services/CustomHooks/useModal";
import {CurrentModalNameEnum} from "../../../../../Enums/CurrentModalNameEnum";
import {ModalHeadersEnum} from "../../../../../Enums/ModalHeadersEnum";
import ProposalStatus from "../../../../../Shared/ProposalStatus/ProposalStatus";
import MobileModalHeader from "../../../../Shared/MobileModalHeader/MobileModalHeader";
import {DesktopModalHeaderHelper} from "../../../../../DesktopComponents/Modals/ModalComponents/ModalHeader/DesktopModalHeaderHelper";
import AbsenceType from "../../../../../Shared/Partials/AbsenceType/AbsenceType";
import HolidayAbsenceType from "../../../../../Shared/Partials/HolidayAbsenceType/HolidayAbsenceType";
import ReplacementPersonView from "../../../../../Shared/Partials/ReplacementPersonView/ReplacementPersonView";
import Description from "../../../../../Shared/Partials/Description/Description";
import AbsenceDateRange from "../../../../../Shared/Partials/AbsenceDateRange/AbsenceDateRange";
import EmployeeData from "../../../../../Shared/Partials/EmployeeData/EmployeeData";
import EmployeeProjects from "../../../../../Shared/Partials/EmployeeProjects/EmployeeProjects";
import {Button} from "@devapo/devapo-ui";
import styles from "./MobileReviewCreatedProposalBody.module.scss"
import ActionMadeBy from "../../../../../Shared/Partials/ActionMadeBy/ActionMadeBy";
import DeclinedReason from "../../../../../Shared/Partials/DeclinedReason/DeclinedReason";

const MobileReviewCreatedProposalBody = () => {

    const {currentProposal, isHistoryBlock} = useSelector((state: RootState) => state.modalPopup);
    const {backToPrevModal, changeModalHistory} = UseModal()
    const [header, setHeader] = useState<ModalHeadersEnum | undefined>()

    useEffect(() => {
        DesktopModalHeaderHelper.getModalProposalHeaderEnum(setHeader, currentProposal)
    }, [currentProposal])

    return (
        <div className={styles.modal_body} >

            <MobileModalHeader
                header={header}
            >
                <ProposalStatus proposalStatus={currentProposal.proposalStatus}/>
            </MobileModalHeader>

            {(!isHistoryBlock && currentProposal.proposalStatus === ProposalStatusEnum.IN_PROGRESS) && (
                    <Button
                        text={"Edytuj"}
                        type={"button"}
                        className={" " + styles.edit_icon}
                        icon={<img src={editIcon} alt=""/>}
                        onClick={() => changeModalHistory(CurrentModalNameEnum.MOBILE_EDIT_PROPOSAL)}
                        color={"normal"}
                        reverse={true}
                    />
            )}

            <div>
                {
                    currentProposal.proposalStatus === ProposalStatusEnum.DECLINED && (
                        <>
                            <ActionMadeBy
                                proposalStatus={currentProposal.proposalStatus}
                                madeBy={currentProposal.approver}
                                actionDate={currentProposal.decisionDate}
                            />
                            <DeclinedReason proposalStatus={currentProposal.proposalStatus} declinedReason={currentProposal.declinedReason} />
                            <hr />
                        </>
                    )
                }
                <EmployeeData employee={currentProposal.employee}/>
                <AbsenceType type={currentProposal.absenceType}/>
                <HolidayAbsenceType type={currentProposal.holidayType} absence={currentProposal.absenceType} />
                <AbsenceDateRange
                    startDate={currentProposal.absenceStartDate}
                    endDate={currentProposal.absenceFinishDate}
                />
                <Description description={currentProposal.description}/>
                <EmployeeProjects proposal={currentProposal} />
                <ReplacementPersonView replacement={currentProposal.replacement}/>
            </div>

            <div className={styles.footer} >
                {
                    !isHistoryBlock && currentProposal.proposalStatus === ProposalStatusEnum.IN_PROGRESS && (
                        <Button
                            text={"Odwołaj"}
                            icon={<DeleteIcon /> }
                            onClick={() => changeModalHistory(CurrentModalNameEnum.MOBILE_CANCEL_PROPOSAL)}
                            type={"button"}
                            color={"warning"}
                            width={150}
                            withBackground={false}
                            withBorder={true}
                        />
                    )
                }

                <Button
                    text={"Zamknij"}
                    type={"button"}
                    onClick={backToPrevModal}
                    color={"success"}
                    width={150}
                    withBackground={true}
                />
            </div>

        </div>
    )
}
export default MobileReviewCreatedProposalBody;