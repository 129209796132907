import projectInitialState from "../initialStates/projectInitialState";
import {createSlice} from "@reduxjs/toolkit";
import loadProjects from "../thunks/loadProjects";

const projectSlice = createSlice({
    name : "projects",
    initialState : projectInitialState,
    reducers : {},
    extraReducers :  (builder ) => {
        builder.addCase( loadProjects.fulfilled, (state,action) => {
            return action.payload.projects
        } );
    }
})

export default projectSlice.reducer
