import TopBar from "./TopBar/TopBar";
import Header from "./Header/Header";
import Calendar from "./Calendar/Calendar";
import AbsenceBlock from "./AbsenceBlock/AbsenceBlock";
import React from "react";
import FilteringBlock from "./FilteringBlock/FilteringBlock";
import Modals from "./Modals/Modals";
import "@devapo/devapo-ui/dist/index.css"

const DesktopComponents = () => {

    return (
        <div className="mainContainer">
            <div className="partialContainer">
                <TopBar/>
            </div>

            <hr/>

            <div className="partialContainer">
                <Header/>

                <div className="calendar-block">
                    <FilteringBlock/>
                    <Calendar/>
                </div>

                <AbsenceBlock/>

                <Modals />

            </div>
        </div>
    )
}

export default DesktopComponents