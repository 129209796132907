import AbsenceBlockTabEnum from "../../../Enums/AbsenceBlockTabEnum";
import React, {FC} from "react";
import {useSelector} from "react-redux";
import RootState from "../../../Types/states/RootState";
import classNames from "classnames";
import styles from "./bar.module.scss";

interface AbsenceBarProps{
    onClick : (absenceBlockTab:AbsenceBlockTabEnum) => void,
    isMobile : boolean
}

const AbsenceBar : FC<AbsenceBarProps> = (props) => {
    const{onClick,isMobile} = props
    const selectedAbsenceBlockTab = useSelector((state:RootState) => state.content.selectedBlockTab);
    const renderAbsenceListName = () => isMobile ? "Nieobecni" : "Lista nieobecności";

    const handleClick = () => onClick(AbsenceBlockTabEnum.ABSENCES)

    return (
        <div
            className={classNames({
                [styles.bar_option] : true,
                [styles.without_number] : true,
            })}
        >
            <button
                className={classNames({
                    [styles.option] : true,
                    [styles.selected] : selectedAbsenceBlockTab === AbsenceBlockTabEnum.ABSENCES
                })}
                onClick={handleClick}>
                {renderAbsenceListName()}
            </button>
        </div>
    )
}

export default AbsenceBar