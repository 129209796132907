
export default class DateService {
    static oneDayInMilliseconds = 24 * 60 * 60 * 1000;

    static getDateFromString(string:string, reverse?:boolean):Date {
        const splitted = string.split('-');
        const stringDate = reverse ? `${splitted[2]}-${splitted[1]}-${splitted[0]}` : `${splitted[0]}-${splitted[1]}-${splitted[2]}`;

        return new Date((new Date(stringDate)).setHours(12, 0, 0, 0));
    }

    static stringifyDate(date ?: Date, dash?:boolean):string {

        if( !(date instanceof Date) || !date){
            return "brak daty"
        }

        const newDate:any = {};

        newDate.year = date.getFullYear();
        newDate.month = (date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
        newDate.day = date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate();

        if(!dash){
            return `${newDate.year}.${newDate.month}.${newDate.day}`
        }

        return `${newDate.year}-${newDate.month}-${newDate.day}`;
    }

    static getPastDate(daysAgo:number):Date {
        return new Date(Date.now() - daysAgo * this.oneDayInMilliseconds);
    }

    static isToday(date : Date) : boolean {
        const currentDate = new Date();

        return date.getDate() === currentDate.getDate() &&
            date.getMonth() === currentDate.getMonth() &&
            date.getFullYear() === currentDate.getFullYear();
    }

    static compareDates(a: Date, b:Date):number {

        if (a > b) {
            return 1;
        } else if (a === b) {
            return 0;
        } else {
            return -1;
        }
    }
}
