import React, {FC, useEffect, useState} from "react";
import Proposal from "../../../../Types/data/Proposal";
import MobileAbsenceType from "../../../../Shared/Partials/MobileAbsenceType";
import UseModal from "../../../../Services/CustomHooks/useModal";
import DateFormatService from "../../../../Services/DateFormatService";
import DeclinedMessage from "../../../../Shared/Partials/DeclinedMessage";
import ShowProposalHistoryStatus
    from "../../../../DesktopComponents/Tables/ShowProposalHistoryStatus/ShowProposalHistoryStatus";
import noAbsencesIcon from "../../../../assets/icons/no_absences-icon.svg";
import ProposalStatus from "../../../../Shared/ProposalStatus/ProposalStatus";
import {Pagination} from "@material-ui/core";

interface MyProposalEmployeeListProps {
    list: Proposal[], isHistoryBlock : boolean
}

const MyProposalEmployeeList : FC<MyProposalEmployeeListProps> = (props) => {
    const {list, isHistoryBlock} = props;
    const {mobileButtonReviewProposal} = UseModal()
    const {toShortDate} = DateFormatService();
    const [currentPage, setCurrentPage] = useState(1)
    const [pageData,setPageData] = useState<Proposal[]>([])

    useEffect(() => {
        setPageData(list.slice(0,10))
        setCurrentPage(1)
    },[list])


    if(list.length === 0){
        return (
            <div className="text-center mt-3">
                <img className="mt-5 mb-4" src={noAbsencesIcon} alt=""/>
                <h5 className="mt-2 no-absences">Nie ma nieobecności</h5>
            </div>
        )
    }

    const changePage = (page : number) => {
        setCurrentPage(page)
        const start = (page * 10) - 10;
        const end = (page * 10);
        setPageData(list.slice(start,end))
    }

    const handleClick = (e,page) => changePage(page)

    return (
        <div className="employee-list">
            {
                pageData.map((proposal: Proposal, index: number) => (
                    <div className="my-proposal-list" key={index}
                         onClick={() => {
                             mobileButtonReviewProposal(proposal, isHistoryBlock)
                         }}
                    >
                        <div className="employee-record">
                            <div className="d-flex align-items-center">
                                {
                                    isHistoryBlock ? (
                                        <ShowProposalHistoryStatus status={proposal.proposalStatus} />
                                    ) : (
                                        <ProposalStatus proposalStatus={proposal.proposalStatus} text={false} />
                                    )
                                }
                                <MobileAbsenceType proposal={proposal} />
                            </div>
                            <div className="d-flex short-date">
                                {toShortDate(proposal.absenceStartDate)}
                                -
                                {toShortDate(proposal.absenceFinishDate)}
                            </div>
                        </div>
                        <DeclinedMessage proposalStatus={proposal.proposalStatus} declinedReason={proposal.declinedReason}  />
                    </div>
                ))
            }
            <Pagination
                className={"mt-2 pb-5"}
                page={currentPage}
                count={Math.ceil(list.length / 10)}
                onChange={handleClick}
            />
        </div>
    )
}

export default MyProposalEmployeeList