import React, {FC} from "react";
import {WeekendDaysEnum} from "../../../Enums/WeekendDaysEnum";
import {calendarActions} from "../CalendarActions/calendarActions";
import Employee from "../../../Types/data/Employee";

interface CalendarDayCellProps {
    day : Date,
    employee : Employee ,
    openCreateProposal : (employee:Employee, dateFrom: Date) => void ,
    row : number,
}

const CalendarDayCell : FC<CalendarDayCellProps> = (props) => {

    const {day,employee,openCreateProposal,row} = props;
    const isWeekendDay = (day: number) => [WeekendDaysEnum.SATURDAY, WeekendDaysEnum.SUNDAY].includes(day);

    return (
        <div className={` ${isWeekendDay(day.getDay()) ? "weekend" : "calendar-day-cell"} `}
             onMouseMove={(e) => {
                 calendarActions.deleteGreenColumn()
                 calendarActions.setVerticalGreenColumn(e)
                 calendarActions.setHorizontalGreenColumn(row)
             }}
             onMouseLeave={() =>calendarActions.deleteGreenColumn()}
             onMouseOut={() => calendarActions.deleteGreenColumn()}
             onClick={() => openCreateProposal(employee, day)}
        />
    )
}

export default CalendarDayCell