import {createSlice} from "@reduxjs/toolkit";
import actionsHistoryTabInitialState from "../../initialStates/table/actionsHistoryTabInitialState";


const actionsHistoryTabSlice = createSlice({
    name : "actionsHistoryTabSlice",
    initialState : actionsHistoryTabInitialState,
    reducers : {
        selectActionsHistoryTabSortOrder(state,action){
            state.actionsHistoryTabSortOrder = action.payload.actionsHistoryTabSortOrder
        },
        setActionsHistoryTabSortBy(state,action){
            state.actionsHistoryTabSortBy = action.payload.actionsHistoryTabSortBy
        },
        setActionsHistoryTabDateFrom(state,action){
            state.absenceTabDateFrom = action.payload.absenceTabDateFrom
        },
        setActionsHistoryTabDateTo(state,action){
            state.absenceTabDateTo = action.payload.absenceTabDateTo
        },
    }
})

export const {
    selectActionsHistoryTabSortOrder,
    setActionsHistoryTabSortBy,
    setActionsHistoryTabDateFrom,
    setActionsHistoryTabDateTo
} = actionsHistoryTabSlice.actions

export default actionsHistoryTabSlice.reducer