import {useSelector} from "react-redux";
import RootState from "../../../../../Types/states/RootState";
import UseModal from "../../../../../Services/CustomHooks/useModal";
import MobileModalHeader from "../../../../Shared/MobileModalHeader/MobileModalHeader";
import React, {useEffect, useState} from "react";
import ProposalStatus from "../../../../../Shared/ProposalStatus/ProposalStatus";
import {ModalHeadersEnum} from "../../../../../Enums/ModalHeadersEnum";
import {DesktopModalHeaderHelper} from "../../../../../DesktopComponents/Modals/ModalComponents/ModalHeader/DesktopModalHeaderHelper";
import EmployeeData from "../../../../../Shared/Partials/EmployeeData/EmployeeData";
import HolidayAbsenceType from "../../../../../Shared/Partials/HolidayAbsenceType/HolidayAbsenceType";
import ActionMadeBy from "../../../../../Shared/Partials/ActionMadeBy/ActionMadeBy";
import DeclinedReason from "../../../../../Shared/Partials/DeclinedReason/DeclinedReason";
import AbsenceDateRange from "../../../../../Shared/Partials/AbsenceDateRange/AbsenceDateRange";
import Description from "../../../../../Shared/Partials/Description/Description";
import EmployeeProjects from "../../../../../Shared/Partials/EmployeeProjects/EmployeeProjects";
import ReplacementPersonView from "../../../../../Shared/Partials/ReplacementPersonView/ReplacementPersonView";
import styles from "../../../MobileReviewAbsence/MobileReviewAbsenceModal/MobileReviewAbsenceModal.module.scss";
import {Button} from "@devapo/devapo-ui";

const MobileActionsHistoryReviewProposalModal = () => {
    const {currentProposal}  = useSelector((state : RootState) => state.modalPopup)
    const {backToPrevModal} = UseModal();
    const [header,setHeader] = useState<ModalHeadersEnum | undefined>()

    useEffect(() => {
        DesktopModalHeaderHelper.getModalProposalHeaderEnum(setHeader,currentProposal)
    },[currentProposal])

    return (
        <div>

            <MobileModalHeader
                header={header}
                startDate={currentProposal.absenceStartDate}
                endDate={currentProposal.absenceFinishDate}
            >
                <ProposalStatus proposalStatus={currentProposal.proposalStatus}/>
            </MobileModalHeader>

            <div>
                <ActionMadeBy
                    proposalStatus={currentProposal.proposalStatus}
                    madeBy={currentProposal.approver}
                    actionDate={currentProposal.decisionDate}
                />
                <DeclinedReason proposalStatus={currentProposal.proposalStatus} declinedReason={currentProposal.declinedReason} />
                <hr />
                <EmployeeData employee={currentProposal.employee}/>
                <HolidayAbsenceType type={currentProposal.holidayType} absence={currentProposal.absenceType} />
                <AbsenceDateRange
                    startDate={currentProposal.absenceStartDate}
                    endDate={currentProposal.absenceFinishDate}
                />
                <Description description={currentProposal.description}/>
                <EmployeeProjects proposal={currentProposal} />
                <ReplacementPersonView replacement={currentProposal.replacement}/>
            </div>

            <div>
                <Button
                    text={"Zamknij"}
                    type={"button"}
                    onClick={() => backToPrevModal()}
                    color={"success"}
                    width={150}
                />
            </div>


        </div>
    )
}

export default MobileActionsHistoryReviewProposalModal