import SortOrder from "../../../Types/SortOrder";

export type proposalTabState = {
    proposalTabSortOrder : SortOrder,
    proposalTabSortBy : string,
}

const proposalTabInitialState : proposalTabState = {
    proposalTabSortOrder : 'asc',
    proposalTabSortBy : 'absenceType'
}

export default proposalTabInitialState