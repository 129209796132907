import {createSlice} from "@reduxjs/toolkit";
import absenceInitialState from "../initialStates/absenceInitialState";
import loadAbsences from "../thunks/loadAbsences";
import loadSortedAndFilteredAbsences from "../thunks/loadSortedAndFilteredAbsences";
import FilteringService from "../../Services/FilteringService";
import Absence from "../../Types/data/Absence";
import cancelAbsence from "../thunks/cancelAbsence";
import loadCalendarSortedAndFilteredAbsences from "../thunks/loadCalendarSortedAndFilteredAbsences";


const absenceSlice = createSlice({
    name: 'absences',
    initialState: absenceInitialState,
    reducers: {
    },
    extraReducers :  (builder ) => {
        builder.addCase( loadAbsences.fulfilled, (state,action) => {
            return action.payload.absences
        } );

        builder.addCase( cancelAbsence.fulfilled, (state) => {});

        builder.addCase( loadSortedAndFilteredAbsences.fulfilled, (state,action) => {
            state = state.concat(action.payload.loadSortedAndFilteredAbsences)
            return FilteringService.removeRepeatedElemsFromArray<Absence>(state);
        } );

        builder.addCase( loadCalendarSortedAndFilteredAbsences.fulfilled, (state,action) => {
            state = state.concat(action.payload.loadCalendarSortedAndFilteredAbsences);
            return FilteringService.removeRepeatedElemsFromArray<Absence>(state);
        } );
    }
})

export default absenceSlice.reducer

