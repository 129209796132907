import "./ProposalsTable.scss";
import React, {FC} from "react";
import { useDispatch, useSelector } from "react-redux";
import checkAbsenceTypeEnum from "../../helpers/checkAbsenceTypeEnum";
import EmployeeService from "../../Services/EmployeeService";
import SortOrder from "../../Types/SortOrder";
import RootState from "../../Types/states/RootState";
import UiTable from "../Shared/Table/UiTable";
import ShowAbsenceType from "./ShowAbsenceType/ShowAbsenceType";
import ShowProposalsHoverIcons from "./ShowProposalsHoverIcons/ShowProposalsHoverIcons";
import {setCurrentOpenProposal} from "../../ReduxStore/reduxSlices/ModalPopupSlice";
import {CurrentModalNameEnum} from "../../Enums/CurrentModalNameEnum";
import UseModal from "../../Services/CustomHooks/useModal";
import generateCustomDateComparator from "src/helpers/customDateComparator";
import {selectProposalTabSortOrder, setProposalTabSortBy} from "../../ReduxStore/reduxSlices/table/proposalTabSlice";
import Proposal from "../../Types/data/Proposal";
import DateService from "../../Services/DateService";

export interface IProposalsTableData {
    employee: string|number;
    absenceStartDate: Date;
    absenceFinishDate: Date;
    absenceType: string;
    description: string;
    '_proposal': Proposal;
}

interface ProposalsTableProps {
    data : Proposal[]
}

const ProposalsTable: FC<ProposalsTableProps> = (props) => {
    const {data} = props;
    const employees = useSelector((state:RootState) => state.employees);
    const sortBy = useSelector((state:RootState) => state.tables.proposalTabSlice.proposalTabSortBy);
    const sortOrder = useSelector((state:RootState) => state.tables.proposalTabSlice.proposalTabSortOrder);
    const dispatch = useDispatch();
    const { changeModalHistory} = UseModal()

    const {getEmployeeById, getEmployeeName} = EmployeeService();

    const tableData = data.map((proposal:Proposal) => {
        const {employee, absenceFinishDate, absenceStartDate, absenceType, description} = proposal;

        const employeeById = getEmployeeById(employees, employee);
        let employeeName:string|null = null;

        if (employeeById) {
            employeeName = getEmployeeName(employeeById);
        }

        return {
            employee: employeeName ? employeeName : employee,
            absenceStartDate: absenceStartDate,
            absenceFinishDate: absenceFinishDate,
            absenceType: checkAbsenceTypeEnum(absenceType),
            description,
            '_proposal': proposal
        };
    }) as IProposalsTableData[];

    const getPropertyName = (property:string):string => {
        switch(property) {
            case 'employee':
                return 'Imię i nazwisko';
            case 'absenceStartDate':
                return 'Data od';
            case 'absenceFinishDate':
                return 'Data do';
            case 'absenceType':
                return 'Rodzaj';
            case 'description':
                return 'Opis';
            default:
                return '';
        }
    };

    const setSort = (newSortBy:string) => {
        if (newSortBy === sortBy) {
            const newSortOrder:SortOrder = sortOrder === 'asc' ? 'desc' : 'asc';

            dispatch(selectProposalTabSortOrder({proposalTabSortOrder : newSortOrder }))

        } else {
            dispatch(selectProposalTabSortOrder({proposalTabSortOrder : "asc"}))
            dispatch(setProposalTabSortBy({proposalTabSortBy : newSortBy }))
        }
    };

    const onElementClick = (element:IProposalsTableData) => {
        changeModalHistory(CurrentModalNameEnum.REVIEW_RECEIVED_PROPOSAL)
        dispatch(setCurrentOpenProposal({ currentProposal: element._proposal}))
    };

    return (
        <div className="proposals-table">
            <UiTable<IProposalsTableData>
                data={tableData}
                sortBy={sortBy as keyof IProposalsTableData}
                sortOrder={sortOrder}
                getPropertyName={getPropertyName}
                setSort={setSort}
                config={{
                    customColumnWidths: {
                        'description': '500px'
                    },
                    customBodyComponents: {
                        'absenceType': (text:string, element:IProposalsTableData) => <ShowAbsenceType<IProposalsTableData> text={text} element={element}/>,
                        absenceStartDate : ( date : Date , element:IProposalsTableData) => <span className={"span-date"} >{DateService.stringifyDate(date)}</span>,
                        absenceFinishDate : ( date : Date , element:IProposalsTableData) => <span className={"span-date"} >{DateService.stringifyDate(date)}</span>
                    },
                    customComparators: {
                        absenceStartDate: generateCustomDateComparator<IProposalsTableData>('absenceStartDate'),
                        absenceFinishDate: generateCustomDateComparator<IProposalsTableData>('absenceFinishDate')
                    },
                    onHoverComponent: (element:IProposalsTableData) => <ShowProposalsHoverIcons element={element}/>,
                    onElementClick
                }}
            />
        </div>
    );
}

export default ProposalsTable;
