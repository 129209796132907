import {createAsyncThunk} from "@reduxjs/toolkit";
import Absence from "../../Types/data/Absence";
import {reduxSliceTypeEnum} from "../reduxSliceTypeEnum/reduxSliceTypeEnum";

const loadCalendarSortedAndFilteredAbsences = createAsyncThunk(reduxSliceTypeEnum.LOAD_CALENDAR_SORTED_AND_FILTERED_ABSENCES, async ({absences} : { absences: Absence[] }) => {
    const loadCalendarSortedAndFilteredAbsences: Absence[] = absences;

    return {loadCalendarSortedAndFilteredAbsences}
})

export default loadCalendarSortedAndFilteredAbsences