import React, {ChangeEvent, FC, useEffect, useState} from "react";
import {FormikProps} from "formik";
import RootState from "../../../../../Types/states/RootState";
import UseModal from "../../../../../Services/CustomHooks/useModal";
import EmployeeData from "../../../../../Shared/Partials/EmployeeData/EmployeeData";
import AbsenceType from "../../../../../Shared/Partials/AbsenceType/AbsenceType";
import HolidayAbsenceType from "../../../../../Shared/Partials/HolidayAbsenceType/HolidayAbsenceType";
import ReplacementPersonView from "../../../../../Shared/Partials/ReplacementPersonView/ReplacementPersonView";
import {useSelector} from "react-redux";
import MobileModalHeader from "../../../../Shared/MobileModalHeader/MobileModalHeader";
import {ModalHeadersEnum} from "../../../../../Enums/ModalHeadersEnum";
import {
    DesktopModalHeaderHelper
} from "../../../../../DesktopComponents/Modals/ModalComponents/ModalHeader/DesktopModalHeaderHelper";
import ReasonMessage from "../../../../../Shared/Partials/ReasonMessage/ReasonMessage";
import {Button} from "@devapo/devapo-ui";
import styles from "./MobileDeclineBody.module.scss"

interface MobileDeclineBodyProps {
    formik: FormikProps<{ canceledReasonText: string }>
}

const MobileDeclineBody: FC<MobileDeclineBodyProps> = (props) => {
    const {formik} = props;
    const {currentAbsence} = useSelector((state: RootState) => state.modalPopup);
    const {backToPrevModal} = UseModal();
    const [header, setHeader] = useState<ModalHeadersEnum | undefined>()

    useEffect(() => {
        DesktopModalHeaderHelper.getModalDeleteAbsenceHeaderEnum(setHeader, currentAbsence)
    }, [currentAbsence])

    const handleReasonChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        formik.setFieldValue("canceledReasonText", e.target.value)
    }

    return (
        <form onSubmit={formik.handleSubmit} className={styles.modal_body} >

            <MobileModalHeader
                header={header}
                startDate={currentAbsence.startDate}
                endDate={currentAbsence.endDate}
            />

            <div>
                <EmployeeData employee={currentAbsence.employee}/>
                <AbsenceType type={currentAbsence.absenceType}/>
                <HolidayAbsenceType type={currentAbsence.holidayType} absence={currentAbsence.absenceType}/>
                <ReplacementPersonView replacement={currentAbsence.replacement}/>
                <ReasonMessage
                    header={"Powód odwołania nieobecności"}
                    value={formik.values.canceledReasonText}
                    onChange={handleReasonChange}
                />
            </div>

            <div className={styles.footer}>
                <Button
                    text={"Anuluj"}
                    onClick={() => backToPrevModal()}
                    type={"button"}
                    color={"normal"}
                />

                <Button
                    text={"Odwołaj"}
                    type={"submit"}
                    color={"warning"}
                    withBackground={true}
                    width={150}
                />
            </div>

        </form>

    )
}
export default MobileDeclineBody;