import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "./global.scss";
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import { Provider } from 'react-redux'
import store from './ReduxStore/store'
import "@devapo/devapo-ui/dist/index.css";
import Auth from "./Layout/Auth/Auth";
import LoadingLayout from "./Layout/LoaderLayout/LoaderLayout";

const Planner = () => {

    // TODO dodać profile - https://devapo.atlassian.net/jira/software/projects/SR/issues/SR-640
    return (
        <React.StrictMode >
            <Provider store={store}>
              <Auth>
                <LoadingLayout>
                    <App/>
                </LoadingLayout>
              </Auth>
            </Provider>
        </React.StrictMode>
    )
}


ReactDOM.render(
    <Planner />
    ,
    document.getElementById('root')
);