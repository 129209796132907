import ClearIcon from "@material-ui/icons/Clear";
import React, {useEffect, useState} from "react";
import CheckIcon from "@material-ui/icons/Check";
import Proposal from "../../../../../Types/data/Proposal";
import UseModal from "../../../../../Services/CustomHooks/useModal";
import EmployeeData from "../../../../../Shared/Partials/EmployeeData/EmployeeData";
import HolidayAbsenceType from "../../../../../Shared/Partials/HolidayAbsenceType/HolidayAbsenceType";
import AbsenceDateRange from "../../../../../Shared/Partials/AbsenceDateRange/AbsenceDateRange";
import Description from "../../../../../Shared/Partials/Description/Description";
import EmployeeProjects from "../../../../../Shared/Partials/EmployeeProjects/EmployeeProjects";
import ReplacementPersonView from "../../../../../Shared/Partials/ReplacementPersonView/ReplacementPersonView";
import {ModalHeadersEnum} from "../../../../../Enums/ModalHeadersEnum";
import DesktopModalHeader from "../../../ModalComponents/ModalHeader/DesktopModalHeader";
import {DesktopModalHeaderHelper} from "../../../ModalComponents/ModalHeader/DesktopModalHeaderHelper";
import styles from "./ReviewReceivedProposalModal.module.scss"
import AbsenceType from "../../../../../Shared/Partials/AbsenceType/AbsenceType";
import {Button} from "@devapo/devapo-ui";

const ReviewReceivedProposalModal = ({proposal} : {proposal : Proposal}) => {

    const {closeAll,buttonOpenDeclineReceivedProposalModal,buttonOpenAcceptReceivedProposalModal} = UseModal();
    const [header,setHeader] = useState<ModalHeadersEnum>()

    useEffect(() => {
        DesktopModalHeaderHelper.getModalProposalHeaderEnum(setHeader,proposal)
    },[proposal])

    return proposal?.id ? (
        <div className={styles.modal_block}>

            <DesktopModalHeader
                header={header}
                startDate={proposal.absenceStartDate}
                endDate={proposal.absenceFinishDate}
                absenceType={proposal.absenceType}
            />

            <div>
                <EmployeeData employee={proposal.employee}/>
                <AbsenceType type={proposal.absenceType}/>
                <HolidayAbsenceType type={proposal.holidayType} absence={proposal.absenceType} />
                <AbsenceDateRange
                    startDate={proposal.absenceStartDate}
                    endDate={proposal.absenceFinishDate}
                />
                <Description description={proposal.description}/>
                <EmployeeProjects proposal={proposal} />
                <ReplacementPersonView replacement={proposal.replacement}/>
            </div>

            <div className={styles.footer} >
                <Button
                    text={"Anuluj"}
                    onClick={() => closeAll()}
                    type={"button"}
                    color={"normal"}
                />
                <div className={styles.decision_buttons} >
                    <Button
                        text={"Odrzuć"}
                        icon={<ClearIcon sx={{ color: 'red' }} />}
                        onClick={() => buttonOpenDeclineReceivedProposalModal(proposal) }
                        type={"button"}
                        color={"warning"}
                        withBackground={false}
                        className={styles.cancel_button}
                    />
                    <Button
                        text={"Akceptuj"}
                        icon={<CheckIcon sx={{ color: 'white' }}  />}
                        onClick={() => buttonOpenAcceptReceivedProposalModal(proposal)}
                        type={"button"}
                        color={"success"}
                    />
                </div>
            </div>

        </div>
    ) : <></>
}

export default ReviewReceivedProposalModal;