import Proposal from "../../Types/data/Proposal";
import React, { FC } from "react";
import DateService from "../../Services/DateService";
import SortOrder from "../../Types/SortOrder";
import {useDispatch, useSelector} from "react-redux";
import EmployeeService from "../../Services/EmployeeService";
import RootState from "../../Types/states/RootState";
import UiTable from "../Shared/Table/UiTable";
import ProposalStatusEnum from "../../Enums/ProposalStatusEnum";
import reviewIcon from "../../assets/icons/review-icon.svg";
import UseModal from "../../Services/CustomHooks/useModal";
import {CurrentModalNameEnum} from "../../Enums/CurrentModalNameEnum";
import {setCurrentOpenProposal} from "../../ReduxStore/reduxSlices/ModalPopupSlice";
import noResultIcon from "../../assets/icons/no_result_icon.svg";
import Employee from "../../Types/data/Employee";
import ActionsHistoryProposalStatus from "./ActionsHistoryProposalStatus/ActionsHistoryProposalStatus";
import {
    selectActionsHistoryTabSortOrder,
    setActionsHistoryTabSortBy
} from "../../ReduxStore/reduxSlices/table/actionsHistoryTabSlice";

export interface IActionsHistoryTableData {
    action : ProposalStatusEnum;
    actionDay : Date;
    employee: string|number;
    absenceStartDate : Date,
    absenceFinishDate : Date,
    actionMadeBy: string;
    description: string;
    '_proposal': Proposal;
}


interface ActionsHistoryTableProps {
    proposalList : Proposal[];
}

const ActionsHistoryTable : FC<ActionsHistoryTableProps> = (props) => {

    const {proposalList} = props;
    const dispatch = useDispatch()
    const employees = useSelector((state:RootState) => state.employees);
    const sortBy = useSelector((state:RootState) => state.tables.actionsHistoryTabSlice.actionsHistoryTabSortBy);
    const sortOrder = useSelector((state:RootState) => state.tables.actionsHistoryTabSlice.actionsHistoryTabSortOrder);
    const {getEmployeeById, getEmployeeName} = EmployeeService();
    const {changeModalHistory} = UseModal()

    const tableData = proposalList.map((proposal:Proposal) => {
        const {employee, absenceFinishDate, absenceStartDate,declinedReason} = proposal;

        const employeeById = getEmployeeById(employees, employee);
        let employeeName:string|null = null;

        if (employeeById) {
            employeeName = getEmployeeName(employeeById);
        }

        const approver = `${proposal.approver && getEmployeeName(getEmployeeById(employees,proposal.approver) as Employee)}`

        return {
            action : proposal.proposalStatus,
            actionDay : proposal.decisionDate ,
            employee: employeeName ? employeeName : employee,
            absenceStartDate,
            absenceFinishDate,
            actionMadeBy : approver ,
            description : declinedReason || "N/d"  ,
            '_proposal': proposal
        };
    }) as IActionsHistoryTableData[];

    const getPropertyName = (property:string):string => {
        switch(property) {
            case 'employee':
                return 'Pracownik';
            case 'absenceStartDate':
                return 'Data od';
            case 'absenceFinishDate':
                return 'Data od';
            case 'actionDay':
                return 'Data akcji';
            case 'actionMadeBy':
                return 'Osoba Decyzyjna';
            case 'description':
                return 'Powód';
            case 'action':
                return 'Akcja';
            default:
                return '';
        }
    };

    const setSort = (newSortBy:string) => {
        if (newSortBy === sortBy) {
            const newSortOrder:SortOrder = sortOrder === 'asc' ? 'desc' : 'asc';

            dispatch(selectActionsHistoryTabSortOrder({actionsHistoryTabSortOrder : newSortOrder }))
        } else {
            dispatch(selectActionsHistoryTabSortOrder({actionsHistoryTabSortOrder : "asc"}))
            dispatch(setActionsHistoryTabSortBy({actionsHistoryTabSortBy : newSortBy }))
        }
    };

    const onElementClick = (element:IActionsHistoryTableData) => {
        changeModalHistory(CurrentModalNameEnum.ACTIONS_HISTORY_REVIEW_PROPOSAL)
        dispatch(setCurrentOpenProposal({ currentProposal: element._proposal}))
    };



    return (
        <>
            <div className="proposals-table">
                {
                    proposalList.length === 0 ? (
                        <div className="text-center mt-3">
                            <img className="mt-5 mb-4" src={noResultIcon} alt=""/>
                            <h5 className="mt-2" style={{color : "red"}} >Brak wyników</h5>
                        </div>
                    ) : (
                        <UiTable<IActionsHistoryTableData>
                            data={tableData}
                            sortBy={sortBy as keyof IActionsHistoryTableData}
                            sortOrder={sortOrder}
                            getPropertyName={getPropertyName}
                            setSort={setSort}
                            config={{
                                customColumnWidths: {
                                    'description': '400px'
                                },
                                customBodyComponents: {
                                    'action': (action : string, element : IActionsHistoryTableData) => (
                                        <ActionsHistoryProposalStatus proposalStatus={element['_proposal'].proposalStatus} absenceType={element["_proposal"].absenceType}   />
                                    ),
                                    absenceStartDate : ( date : Date , element:IActionsHistoryTableData) => <span className={"span-date"} >{DateService.stringifyDate(date)}</span>,
                                    absenceFinishDate : ( date : Date , element:IActionsHistoryTableData) => <span className={"span-date"} >{DateService.stringifyDate(date)}</span>,
                                    actionDay : (date : Date, element : IActionsHistoryTableData) => <span className={"span-date"} >{DateService.stringifyDate(new Date(date))}</span>
                                },

                                onElementClick,
                                onHoverComponent: (element:IActionsHistoryTableData) => (
                                    <div className="proposals-hover">
                                        <button className="review-span btn p-0 mb-0" onClick={() => onElementClick(element)}>
                                            <img src={reviewIcon} alt=""/>
                                        </button>
                                    </div>
                                )
                            }}
                        />
                    )
                }

            </div>
        </>
    )
}

export default ActionsHistoryTable