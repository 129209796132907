import IReduxError from "src/Types/IReduxError";
import ErrorState from "src/Types/states/ErrorState";

export default class ErrorService {
    static getNewErrorId(errorState:ErrorState):number {
        let newId = 0;

        errorState.forEach((error:IReduxError) => {
            if (error.id >= newId) {
                newId = error.id + 1;
            }
        })

        return newId;
    }
}
