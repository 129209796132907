import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import RootState from "../../../../../Types/states/RootState";
import UseModal from "../../../../../Services/CustomHooks/useModal";
import MobileModalHeader from "../../../../Shared/MobileModalHeader/MobileModalHeader";
import {ModalHeadersEnum} from "../../../../../Enums/ModalHeadersEnum";
import {DesktopModalHeaderHelper} from "../../../../../DesktopComponents/Modals/ModalComponents/ModalHeader/DesktopModalHeaderHelper";
import EmployeeData from "../../../../../Shared/Partials/EmployeeData/EmployeeData";
import HolidayAbsenceType from "../../../../../Shared/Partials/HolidayAbsenceType/HolidayAbsenceType";
import ReplacementPersonView from "../../../../../Shared/Partials/ReplacementPersonView/ReplacementPersonView";
import AbsenceDateRange from "../../../../../Shared/Partials/AbsenceDateRange/AbsenceDateRange";
import Description from "../../../../../Shared/Partials/Description/Description";
import EmployeeProjects from "../../../../../Shared/Partials/EmployeeProjects/EmployeeProjects";
import {Button} from "@devapo/devapo-ui";
import AbsenceType from "../../../../../Shared/Partials/AbsenceType/AbsenceType";
import styles from "./MobileReviewReceivedProposalModal.module.scss"

const MobileReviewReceivedProposalModal = () => {

    const {currentProposal}  = useSelector((state : RootState) => state.modalPopup)
    const {buttonOpenMobileDeclineReceivedProposalModal,buttonOpenMobileAcceptReceivedProposalModal} = UseModal()
    const [header,setHeader] = useState<ModalHeadersEnum>()

    useEffect(() => {
        DesktopModalHeaderHelper.getModalProposalHeaderEnum(setHeader,currentProposal)
    },[currentProposal])

    return (
        <div className={styles.modal_body} >

            <MobileModalHeader
                header={header}
                startDate={currentProposal.absenceStartDate}
                endDate={currentProposal.absenceFinishDate}
            />

            <div>
                <EmployeeData employee={currentProposal.employee}/>
                <AbsenceType type={currentProposal.absenceType}/>
                <HolidayAbsenceType type={currentProposal.holidayType} absence={currentProposal.absenceType} />
                <AbsenceDateRange
                    startDate={currentProposal.absenceStartDate}
                    endDate={currentProposal.absenceFinishDate}
                />
                <Description description={currentProposal.description}/>
                <EmployeeProjects proposal={currentProposal} />
                <ReplacementPersonView replacement={currentProposal.replacement}/>
            </div>

            <div className={styles.footer}>
                <Button
                    text={"Odrzuć"}
                    icon={<ClearIcon className={styles.icon} />}
                    onClick={() => buttonOpenMobileDeclineReceivedProposalModal(currentProposal)}
                    type={"button"}
                    color={"warning"}
                    width={150}
                    withBackground={true}
                />

                <Button
                    text={"Akceptuj"}
                    icon={<CheckIcon className={styles.icon} />}
                    type={"button"}
                    onClick={() =>buttonOpenMobileAcceptReceivedProposalModal(currentProposal)}
                    color={"success"}
                    width={150}
                    withBackground={true}
                />
            </div>


        </div>
    )
}

export default MobileReviewReceivedProposalModal