import {
    addSelectedFilter,
    setInputOpen,
    setInputValue
} from "../../../../../../ReduxStore/reduxSlices/filteringEngineSlice";
import {useDispatch} from "react-redux";
import {FilteringEngineEnum} from "../../../../../../Enums/FilteringEngineEnum";

const RenderItems = ({listName, selectedOptions,category}: { listName: string[], selectedOptions: string[], category : FilteringEngineEnum}) => {

    const dispatch = useDispatch();

    return (
        <>
            {
                listName.map((item: string, index: number) => (
                        <option key={index} value={item} className="option"
                                onClick={() => {
                                    dispatch(addSelectedFilter({
                                        selectedFilters: {
                                            selectedOption: item,
                                            categoryName: category
                                        }
                                    }))
                                    dispatch(setInputValue({inputValue : ""}))
                                    dispatch(setInputOpen({inputOpen : false}))
                                }}
                                disabled={selectedOptions.includes(item)}
                        >
                            {item}
                        </option>
                    )
                )
            }
        </>
    )
}

export default RenderItems