import React from "react";
import {useSelector} from "react-redux";
import RootState from "../../../Types/states/RootState";
import UseModal from "../../../Services/CustomHooks/useModal";
import {CurrentModalNameEnum} from "../../../Enums/CurrentModalNameEnum";
import MobileReviewAbsenceModal from "./MobileReviewAbsenceModal/MobileReviewAbsenceModal";
import styles from "../Modals.module.scss";
import {Modal} from "@devapo/devapo-ui";

const MobileReviewAbsence = () => {
    const {currentOpenModal} = useSelector((state: RootState) => state.modalPopup);
    const {closeAll} = UseModal();

    return (
        <>
            <Modal
                isOpen={currentOpenModal === CurrentModalNameEnum.MOBILE_REVIEW_ABSENCE}
                onClose={() => closeAll()}
                modalClass={styles.modal}
                backdropClass={styles.backdrop}
            >
                <MobileReviewAbsenceModal />
            </Modal>
        </>
    )
}

export default MobileReviewAbsence