import positionInitialState from "../initialStates/positionInitialState";
import {createSlice} from "@reduxjs/toolkit";
import loadPositions from "../thunks/loadPositions";

const positionSlice = createSlice({
    name : "positions",
    initialState : positionInitialState,
    reducers : {},
    extraReducers :  (builder ) => {
        builder.addCase( loadPositions.fulfilled, (state,action) => {
            return action.payload.positions
        } );
    }
})

export default positionSlice.reducer
