import "./UiTable.scss";
import { ReactElement, useEffect, useState } from "react";
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import SortOrder from "../../../Types/SortOrder";
import UiTableHead from "./UiTableHead";
import UiTableBody from "./UiTableBody";
import {Pagination, TableFooter} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";

interface IUiTableProps<T> {
    getPropertyName: any;
    data: T[];
    sortBy: keyof T;
    sortOrder: SortOrder;
    setSort: any;
    config?: IUiTableConfig<T>;
}

export interface IUiTableConfig<T> {
    customBodyComponents?: {
        [key:string]: (text: any, element:T) => ReactElement<any>;
    };
    customColumnWidths?: {
        [key:string]: string;
    };
    customComparators?: {
        [key:string]: (a:T, b:T) => number;
    }
    onHoverComponent?: (element:T) => ReactElement<any>;
    onElementClick?: (element:T) => void;
}

export default function UiTable<T>(props:IUiTableProps<T>):ReactElement<IUiTableProps<T>> {
    const {getPropertyName, data, sortBy, sortOrder, setSort, config} = props;
    const [sortedTableData, setSortedTableData] = useState(data);
    const [currentPage, setCurrentPage] = useState(1)
    const [pageData,setPageData] = useState<T[]>([])

    const getSortedTableData = ():T[] => {

        return data.sort((a,b) => {
            if (a[sortBy] > b[sortBy]) {
                return sortOrder === 'asc' ? 1 : -1;
            } else if (a[sortBy] === b[sortBy]) {
                return 0;
            } else {
                return sortOrder === 'asc' ? -1 : 1;
            }
        })

    };

    const changePage = (page : number) => {
        setCurrentPage(page)
        const start = (page * 10) - 10;
        const end = (page * 10);
        setPageData(sortedTableData.slice(start,end))
    }

    const handleClick = (e,page) => changePage(page)

    useEffect(() => {
        setSortedTableData(getSortedTableData());
    }, [sortBy, sortOrder, data]);

    useEffect(() => {
        changePage(currentPage)
    },[sortedTableData])

    return (
        <TableContainer className="ui-table-container">
            <Table>
                <UiTableHead<T>
                    order={sortOrder}
                    orderBy={sortBy}
                    setSort={setSort}
                    data={pageData}
                    getPropertyName={getPropertyName}
                    config={config}
                />
                <UiTableBody<T>
                    data={pageData}
                    config={config}
                />
                <TableFooter>
                    <TableRow>
                        <Pagination
                            page={currentPage}
                            count={Math.ceil(data.length / 10)}
                            onChange={handleClick}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}
