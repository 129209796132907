import React from "react";

const DateFormatService = () => {

    const toMediumDate = (date: Date) => <>{date.getFullYear()}.{formatDateNumber(date.getMonth() + 1)}.{formatDateNumber(date.getDate())}</>

    const toFullDate = (date: Date) => {
        const hours = date.getHours();
        const minutes = date.getMinutes();

        return (<>
            {toMediumDate(date)} godz.&nbsp;{formatDateNumber(hours)}:{formatDateNumber(minutes)}
        </>)
    }

    const formatDateNumber = (value: number): string => (value < 10) ? `0${value}` : String(value);

    const toShortDate = (date: Date) => (<>{formatDateNumber(date.getMonth() +1)}.{formatDateNumber(date.getDate())}</>);

    return {
        toMediumDate,
        toShortDate,
        toFullDate
    }

}
export default DateFormatService;