import React, {ChangeEvent, FC} from "react";
import styles from "./ReasonMessage.module.scss"

interface ReasonMessageProps{
    value ?: string,
    header ?: string,
    onChange ?: (e : ChangeEvent<HTMLTextAreaElement>) => void,
}

const ReasonMessage : FC<ReasonMessageProps> = (props) => {

    const {header,value,onChange} = props;

    return (
        <div className={styles.reason_container}>
            <fieldset className={styles.reason_fieldset} >
                <legend>{header}</legend>
                <textarea
                    value={value}
                    name="declinedReason"
                    onChange={onChange}
                    maxLength={255}
                />
            </fieldset>
        </div>
    )

}

export default ReasonMessage