import EmployeeData from "../../../../../Shared/Partials/EmployeeData/EmployeeData";
import AbsenceType from "../../../../../Shared/Partials/AbsenceType/AbsenceType";
import HolidayAbsenceType from "../../../../../Shared/Partials/HolidayAbsenceType/HolidayAbsenceType";
import ReplacementPersonView from "../../../../../Shared/Partials/ReplacementPersonView/ReplacementPersonView";
import Description from "../../../../../Shared/Partials/Description/Description";
import React, {FC, useEffect, useState} from "react";
import UseModal from "../../../../../Services/CustomHooks/useModal";
import {useSelector} from "react-redux";
import RootState from "../../../../../Types/states/RootState";
import {ModalHeadersEnum} from "../../../../../Enums/ModalHeadersEnum";
import {DesktopModalHeaderHelper} from "../../../../../DesktopComponents/Modals/ModalComponents/ModalHeader/DesktopModalHeaderHelper";
import MobileModalHeader from "../../../../Shared/MobileModalHeader/MobileModalHeader";
import AbsenceDateRange from "../../../../../Shared/Partials/AbsenceDateRange/AbsenceDateRange";
import EmployeeProjects from "../../../../../Shared/Partials/EmployeeProjects/EmployeeProjects";
import {Button} from "@devapo/devapo-ui";
import styles from "./MobileCancelProposalBody.module.scss"

interface MobileCancelProposalBodyProps {
    deleteProposal : () => void
}

const MobileCancelProposalBody : FC<MobileCancelProposalBodyProps> = (props) => {
    const {deleteProposal} = props;
    const {backToPrevModal} = UseModal();
    const {currentProposal} = useSelector((state : RootState) => state.modalPopup)
    const [header,setHeader] = useState<ModalHeadersEnum | undefined>()

    useEffect(() => {
        DesktopModalHeaderHelper.getModalCancelProposalHeaderEnum(setHeader,currentProposal)
    },[currentProposal])

    return (
        <div className={styles.modal_body} >

            <MobileModalHeader
                header={header}
                startDate={currentProposal.absenceStartDate}
                endDate={currentProposal.absenceFinishDate}
            />

            <div>
                <EmployeeData employee={currentProposal.employee}/>
                <AbsenceType type={currentProposal.absenceType}/>
                <HolidayAbsenceType type={currentProposal.holidayType} absence={currentProposal.absenceType} />
                <AbsenceDateRange
                    startDate={currentProposal.absenceStartDate}
                    endDate={currentProposal.absenceFinishDate}
                />
                <Description description={currentProposal.description}/>
                <EmployeeProjects proposal={currentProposal} />
                <ReplacementPersonView replacement={currentProposal.replacement}/>
            </div>

            <div className={styles.footer} >
                <Button
                    text={"Anuluj"}
                    type={"button"}
                    onClick={backToPrevModal}
                    color={"normal"}
                />
                <Button
                    text={"Odwołaj"}
                    type={"button"}
                    onClick={deleteProposal}
                    color={"warning"}
                    width={150}
                    withBackground={true}
                />
            </div>

        </div>
    )
}
export default MobileCancelProposalBody