import {createSlice} from "@reduxjs/toolkit";
import myProposalTabInitialState from "../../initialStates/table/myProposalTabInitialState";


const myProposalTabSlice = createSlice({
    name : "myProposalTabSlice",
    initialState : myProposalTabInitialState,
    reducers : {
        selectMyProposalTabHistorySortOrder(state,action){
            state.myProposalTabHistorySortOrder = action.payload.myProposalTabHistorySortOrder
        },
        setMyProposalTabHistorySortBy(state,action){
            state.myProposalTabHistorySortBy = action.payload.myProposalTabHistorySortBy
        },

        selectMyProposalTabSortOrder(state,action){
            state.myProposalTabSortOrder = action.payload.myProposalTabSortOrder
        },
        setMyProposalTabSortBy(state,action){
            state.myProposalTabSortBy = action.payload.myProposalTabSortBy
        },
    }
})

export const {
    selectMyProposalTabHistorySortOrder,
    setMyProposalTabHistorySortBy,
    selectMyProposalTabSortOrder,
    setMyProposalTabSortBy
} = myProposalTabSlice.actions

export default myProposalTabSlice.reducer