import React, {ChangeEvent} from "react";
import './AbsenceTopBarView.scss';
import {AbsenceFilteringOptionsEnum} from "../../../../Enums/AbsenceFilteringOptionsEnum";
import {useDispatch, useSelector} from "react-redux";
import RootState from "../../../../Types/states/RootState";
import {MobileContentSelectAbsenceTopBarTab} from "../../../../ReduxStore/reduxSlices/mobileContentSlice";
import styles from "./AbsenceTopBarView.module.scss"
import classNames from "classnames";

const AbsenceTopBarView = () => {
    const dispatch = useDispatch();

    const activeButton = useSelector((state:RootState) => {
        return state.mobileContent.absenceTopBarTab
    });

    const setActiveButton = (newValue:AbsenceFilteringOptionsEnum) => {
        dispatch(MobileContentSelectAbsenceTopBarTab({absenceTopBarTab : newValue }))
    };

    const handleClick = (event: ChangeEvent<any>) => {
        setActiveButton(event.target.value)
    };

    return (
        <div className={styles.top_bar} >
                {
                    Object.values(AbsenceFilteringOptionsEnum).map((type: string, index: number) => {
                        return (
                            <button
                                    className={classNames({
                                        [styles.absence_button] : true,
                                        [styles.active] : activeButton === type
                                    })}
                                    value={type}
                                    name={type}
                                    key={index}
                                    onClick={handleClick}
                            >
                                {type}
                            </button>
                        )
                    })
                }
        </div>
    )
}


export default AbsenceTopBarView;