import React, {ChangeEvent, useEffect} from "react";
import {batch, useDispatch, useSelector} from "react-redux";
import Position from "src/Types/data/Position";
import Project from "src/Types/data/Project";
import RootState from "../../../../Types/states/RootState";
import ClearIcon from "@material-ui/icons/Clear";
import BackgroundValue
    from "../../../Modals/EmployeeModals/CreateProposal/CreateProposalModal/CreateProposalBodyComponents/EmployeeSelectList/BackgroundValue";
import {filterHelper} from "../FilterHelper/FilterHelper";
import {
    setBackgroundInputValue, setBackgroundType,
    setEmployeesFilter,
    setInputOpen,
    setInputValue, setPositionFilter, setProjectFilter
} from "../../../../ReduxStore/reduxSlices/filteringEngineSlice";

const FilterInput = ({inputRef,applyFilter} : {inputRef :  React.RefObject<HTMLInputElement>,applyFilter: () => void }) => {

    const selectedOption = useSelector((state: RootState) => state.filteringEngine.selectedOption);
    const inputValue = useSelector((state: RootState) => state.filteringEngine.inputValue);
    const inputOpen = useSelector((state: RootState) => state.filteringEngine.inputOpen);
    const backgroundInputValue = useSelector((state: RootState) => state.filteringEngine.backgroundInputValue);

    const employees = useSelector((state: RootState) => state.employees);
    const positions = useSelector((state: RootState) => state.positions);
    const projects = useSelector((state: RootState) => state.projects);
    const positionList = positions.map((position: Position): string => position.name);
    const projectList = projects.map((project: Project): string => project.name);

    const dispatch = useDispatch()

    useEffect(() => {
        clear()
    },[])

    useEffect(() => {
        filterLists(inputValue)
    }, [inputValue])

    useEffect(() => {
        if (inputValue.length === 0) {
            dispatch(setBackgroundInputValue({backgroundInputValue : ""}))
        }
    }, [selectedOption])

    const filterLists = (value) => {

        if (value.length > 0) {
            let employeesFilteredList;
            let positionsFilteredList;
            let projectsFilteredList;

            let employeeFirstChild;
            let positionFirstChild;
            let projectFirstChild;

            switch(selectedOption){
                case "Wszystko" :
                    employeesFilteredList = filterHelper.filterListByValue(employees, value)
                    positionsFilteredList = filterHelper.filterStringListByValue(positionList, value)
                    projectsFilteredList = filterHelper.filterStringListByValue(projectList, value)

                    employeeFirstChild = filterHelper.setBackgroundValueByName(employees, value)
                    positionFirstChild = filterHelper.setBackgroundValueFromStringList(positionList, value)
                    projectFirstChild = filterHelper.setBackgroundValueFromStringList(projectList, value)

                    batch(() => {
                        dispatch(setEmployeesFilter({employeesFilter : employeesFilteredList}))
                        dispatch(setPositionFilter({positionFilter : positionsFilteredList}))
                        dispatch(setProjectFilter({projectFilter : projectsFilteredList}))
                    })  ; break;
                case "Pracownicy" :
                    employeesFilteredList = filterHelper.filterListByValue(employees, value)
                    employeeFirstChild = filterHelper.setBackgroundValueByName(employees, value)
                    dispatch(setEmployeesFilter({employeesFilter : employeesFilteredList}))  ; break;
                case "Role" :
                    positionsFilteredList = filterHelper.filterStringListByValue(positionList, value)
                    positionFirstChild = filterHelper.setBackgroundValueFromStringList(positionList, value)
                    dispatch(setPositionFilter({positionFilter : positionsFilteredList}))  ;break;
                case "Projekty" :
                    projectsFilteredList = filterHelper.filterStringListByValue(projectList, value)
                    projectFirstChild = filterHelper.setBackgroundValueFromStringList(projectList, value)
                    dispatch(setProjectFilter({projectFilter : projectsFilteredList})) ; break;
            }

            if (employeeFirstChild && (selectedOption === "Wszystko" || selectedOption === "Pracownicy")) {
                dispatch(setBackgroundInputValue({backgroundInputValue : employeeFirstChild}))
                dispatch(setBackgroundType({backgroundType : "Pracownicy"}))
                return;
            } else if (positionFirstChild && (selectedOption === "Wszystko" || selectedOption === "Role")) {
                dispatch(setBackgroundInputValue({backgroundInputValue : positionFirstChild}))
                dispatch(setBackgroundType({backgroundType : "Role"}))
                return;
            } else if (projectFirstChild && (selectedOption === "Wszystko" || selectedOption === "Projekty")) {
                dispatch(setBackgroundInputValue({backgroundInputValue : projectFirstChild}))
                dispatch(setBackgroundType({backgroundType : "Projekty"}))
                return;
            }

            dispatch(setBackgroundInputValue({backgroundInputValue : ""}))
            dispatch(setBackgroundType({backgroundType : ""}))

        } else {
            clear()
        }
    }

    const clear = () => {
        dispatch(setInputValue({inputValue : ""}))
        dispatch(setInputOpen({inputOpen : false}))
        dispatch(setBackgroundInputValue({backgroundInputValue : ""}))
        dispatch(setBackgroundType({backgroundType : ""}))
        dispatch(setEmployeesFilter({employeesFilter : employees}))
        dispatch(setPositionFilter({positionFilter : positionList}))
        dispatch(setProjectFilter({projectFilter : projectList}))
    }

    const inputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setInputOpen({inputOpen : true}))
        dispatch(setInputValue({inputValue : event.target.value}))
    }
    const inputOnClick = () => dispatch(setInputOpen({inputOpen : !inputOpen}));

    return (
        <>
            <input type="text" className="search-input border-left-0"
                   value={inputValue}
                   onClick={inputOnClick}
                   onChange={inputOnChange}
                   onKeyDown={(e) => e.key === "Enter" && applyFilter()}
                   ref={inputRef}
            />
            <BackgroundValue
                inputValue={inputValue}
                employeeBackgroundValue={backgroundInputValue}
            />

            {
                inputValue && (
                    <div className="clear-icon-container-input" onClick={() => clear()}>
                        <ClearIcon/>
                    </div>
                )
            }

        </>
    )
}


export default FilterInput;