import React, {useEffect} from "react";
import MobileDeclineBody from "./MobileDeclineBody/MobileDeclineBody";
import {useDispatch, useSelector} from "react-redux";
import UseModal from "../../../../Services/CustomHooks/useModal";
import RootState from "../../../../Types/states/RootState";
import {CurrentModalNameEnum} from "../../../../Enums/CurrentModalNameEnum";
import {useFormik} from "formik";
import {withFormikDevtools} from "formik-devtools-extension";
import {RtkQueryHelper} from "../../../../helpers/rtk-query.helper";
import {api} from "../../../../ReduxStore/api/api";
import styles from "./../../Modals.module.scss"
import {Modal} from "@devapo/devapo-ui";

export const RenderMobileDeleteModal = () => {
    const {currentOpenModal,currentAbsence} = useSelector((state : RootState) => state.modalPopup);
    const [cancelAbsence, result] = api.useCancelAbsenceMutation()
    const {closeAll} = UseModal()
    const dispatch = useDispatch()

    useEffect(() => {
        if(RtkQueryHelper.checkResultIsFulfilled(result)){
            formik.resetForm()
            closeAll();
        }
    },[result])

    const formik = useFormik({
        initialValues: {
            canceledReasonText : ""
        },
        onSubmit : values => {
            doCancelAbsence(values)
        }
    })

    useEffect(() => {
        formik.setFieldValue("canceledReasonText", "")
    },[currentAbsence])

    useEffect(() => {
        process.env.NODE_ENV !== 'production' && withFormikDevtools(formik);
    },[])

    const doCancelAbsence = (values) => {
        if (values.canceledReasonText) {
            cancelAbsence({id : currentAbsence.id, message : values.canceledReasonText});
        } else {
            dispatch(cancelAbsence({id : currentAbsence.id}));
        }
    };

    return (
        <Modal
            isOpen={currentOpenModal === CurrentModalNameEnum.MOBILE_DELETE_ABSENCE }
            onClose={() => closeAll()}
            modalClass={styles.modal}
            backdropClass={styles.backdrop}
        >
            <MobileDeclineBody formik={formik} />
        </Modal>
    )
}