import {CurrentModalNameEnum} from "../../../../Enums/CurrentModalNameEnum";
import React from "react";
import {useSelector} from "react-redux";
import RootState from "../../../../Types/states/RootState";
import UseModal from "../../../../Services/CustomHooks/useModal";
import ActionsHistoryReviewProposalModal from "./ActionsHistoryReviewProposalModal/ActionsHistoryReviewProposalModal";
import {Modal} from "@devapo/devapo-ui"
import styles from "./../../Modals.module.scss"

const ActionsHistoryReviewProposal = () => {
    const {currentOpenModal}  = useSelector((state : RootState) => state.modalPopup)
    const {closeAll} = UseModal();

    return (
        <Modal
            isOpen={currentOpenModal === CurrentModalNameEnum.ACTIONS_HISTORY_REVIEW_PROPOSAL}
            onClose={() => closeAll()}
            modalClass={styles.modal}
            backdropClass={styles.backdrop}
        >
            <ActionsHistoryReviewProposalModal />
        </Modal>
    )
}

export default ActionsHistoryReviewProposal