import React, { FC } from "react";
import {FormikProps} from "formik";
import UseModal from "../../../../Services/CustomHooks/useModal";
import CreateProposalBody
    from "../../../../DesktopComponents/Modals/EmployeeModals/CreateProposal/CreateProposalModal/CreateProposalBody/CreateProposalBody";
import { CreateProposalFormik } from "src/Types/states/modal/CreateProposalFormik";
import styles from "./MobileCreateProposalModal.module.scss"
import {Button} from "@devapo/devapo-ui";
import classNames from "classnames";

interface MobileCreateProposalModalProps {
    formik : FormikProps<CreateProposalFormik & { role: string }>,
    result ?: unknown
}

const MobileCreateProposalModal : FC<MobileCreateProposalModalProps> = (props) => {

    const {formik,result} = props;
    const {backToPrevModal} = UseModal()
    return (
        <form onSubmit={formik.handleSubmit} className={styles.form}  >

            <h4 className={styles.header}>Dodawanie nieobecności</h4>

            <CreateProposalBody formik={formik} result={result} isMobile={true} />

            <div className={styles.footer} >
                <Button
                    text={"Anuluj"}
                    onClick={() => backToPrevModal()}
                    type={"button"}
                    color={"normal"}
                />
                <Button
                    text={"Dodaj nieobecność"}
                    type={"submit"}
                    color={"success"}
                    className={" " + classNames({
                        [styles.disabled_submit] : !(formik.isValid && formik.dirty)
                    })}
                />
            </div>

        </form>
    )
}

export default MobileCreateProposalModal