import React, {FC} from "react";
import DateFormatService from "../../../../../Services/DateFormatService";
import Absence from "../../../../../Types/data/Absence";

interface DateFormatterViewProps {
    item:Absence,
    currentView:string
}

const DateFormatterView : FC<DateFormatterViewProps> = (props) =>{
    const {item, currentView} = props;
    const {toShortDate} = DateFormatService();

    const renderDateView = () => {
        switch (currentView) {
            case "Planned":
                return (
                    <div className="d-flex">
                        <p className="mb-0 ml-2">od</p>
                        <p className="mb-0 ml-2">{toShortDate(item.startDate)}</p>
                    </div>
                )
            case "Now":
                return (
                    <div className="d-flex">
                        <p className="mb-0 ml-2">do</p>
                        <p className="mb-0 ml-2">{toShortDate(item.endDate)}</p>
                    </div>
                )
            default:
                return (<></>);
        }
    }

    return (
        renderDateView()
    )
}
export default DateFormatterView;