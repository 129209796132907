import DataService from "../../Services/DataService";
import loadAbsences from "./loadAbsences";
import loadProposals from "./loadProposals";
import { batch } from "react-redux";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {reduxSliceTypeEnum} from "../reduxSliceTypeEnum/reduxSliceTypeEnum";

const declineProposal = createAsyncThunk(reduxSliceTypeEnum.DECLINE_PROPOSAL, async ({proposalId, message} : {proposalId : number, message ? : string} , {dispatch}) => {
    await DataService.declineProposal(proposalId, message);

    batch(() => {
        dispatch(loadProposals());
        dispatch(loadAbsences());
    });
})

export default declineProposal;
