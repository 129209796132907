import Proposal from "../../Types/data/Proposal";
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import FetchService from "../../Services/FetcherService";
import DateService from "src/Services/DateService";
import { CreateProposalApiType } from "src/Types/states/modal/CreateProposalFormik";
import Absence from "src/Types/data/Absence";
import {KeycloakService} from "../../Layout/Auth/KeycloakService";
import ProposalStatusEnum from "../../Enums/ProposalStatusEnum";

export const api = createApi({
    reducerPath : "api",
    baseQuery : fetchBaseQuery({
        baseUrl : `/api`,
        prepareHeaders : async (headers) => {

            // TODO dodać profile - https://devapo.atlassian.net/jira/software/projects/SR/issues/SR-640
            if( KeycloakService.isTokenExpired() ){
                await KeycloakService.updateToken().then( () => {
                    headers.set("Authorization", `Bearer ${KeycloakService.token()}`)
                    headers.set("content-type", "application/json")
                })
                return headers;
            } else {
                headers.set("Authorization", `Bearer ${KeycloakService.token()}`)
                headers.set("content-type", "application/json")

                return headers;
            }

        }
    }),
    tagTypes : ['proposal', 'absence'],
    endpoints : (builder) => ({
        getAllProposals: builder.query<Proposal[], void>({
            query: () => "proposals",
            transformResponse: (response : {content : Proposal[]}) => response.content.map(item => {
                item.absenceFinishDate = new Date(item.absenceFinishDate);
                item.absenceStartDate = new Date(item.absenceStartDate);
                item.requestDate = item.requestDate && new Date(item.requestDate);
                return item;
            }),
            providesTags : ['proposal']
        }),
        getProposalsWithParams : builder.query<Proposal[], any>({
            query: (filters) => {
                const params = FetchService.encodeParams(filters)

                return {
                    url : `proposals?filters=${params}`,
                }
            },
            transformResponse: (response : {content : Proposal[]}) => response.content.map(item => {
                item.absenceFinishDate = new Date(item.absenceFinishDate);
                item.absenceStartDate = new Date(item.absenceStartDate);
                item.requestDate = item.requestDate && new Date(item.requestDate);
                return item;
            }),
            providesTags : ['proposal']
        }),
        acceptProposal : builder.mutation<unknown, number>({
            query : (id : number) => ({
                url : `/proposals/${id}/accept`,
                method : "POST",
            }),
            invalidatesTags :  (result, error, arg) => {
                return result ? ['proposal','absence'] : [];
            }
        }),
        declineProposal : builder.mutation<unknown, {id : number, message ?: string} >({
            query : (arg: {id: number, message?: string}) => ({
                url : `/proposals/${arg.id}/decline`,
                method : "POST",
                body: arg.message
            }),
            invalidatesTags :  (result, error, arg) => {
                return result ? ['proposal'] : [];
            }
        }),
        cancelProposal : builder.mutation<unknown, number>({
            query : (id : number) => ({
                url : `/proposals/${id}/cancel`,
                method : "DELETE",
            }),
            invalidatesTags : ['proposal']
        }),
        editProposal : builder.mutation<unknown, Proposal>({
            query : (newProposal : Proposal) => ({
                url : "/proposals",
                method : "PATCH",
                body: {
                    ...newProposal,
                    replacement : newProposal.replacement ? newProposal.replacement : null,
                    absenceStartDate: DateService.stringifyDate(newProposal.absenceStartDate,true),
                    absenceFinishDate: DateService.stringifyDate(newProposal.absenceFinishDate, true),
                }
            }),
            invalidatesTags :  (result, error, arg) => {
                return result ? ['proposal'] : [];
            }
        }),
        createProposal : builder.mutation<Proposal, CreateProposalApiType>({
            query : (newProposal : CreateProposalApiType) => ({
                url : "/proposals",
                method : "POST",
                body: {
                    ...newProposal,
                    absenceStartDate: DateService.stringifyDate(newProposal.absenceStartDate,true),
                    absenceFinishDate: DateService.stringifyDate(newProposal.absenceFinishDate, true)
                }
            }),
            invalidatesTags :  (result ?: Proposal ) => {
                if(!result) {
                    return []
                }

                const proposalStatus = result.proposalStatus;

                if(proposalStatus === ProposalStatusEnum.IN_PROGRESS){
                    return ['proposal','absence']
                }
                return ['absence']
            }
        }),
        getAllAbsences: builder.query<Absence[], void>({
            query: () => "absences",
            transformResponse: (response : {content : Absence[]}) => response.content.map((item) => {
                item.startDate = new Date(item.startDate);
                item.endDate = new Date(item.endDate);
                return item;
            }),
            providesTags : ['absence']
        }),
        getAbsencesWithParams : builder.query<Absence[], any>({
            query: (filters) => {
                const params = FetchService.encodeParams(filters)

                return {
                    url : `absences?filters=${params}`,
                }
            },
            transformResponse: (response : {content : Absence[]}) => response.content.map((item) => {
                item.startDate = new Date(item.startDate);
                item.endDate = new Date(item.endDate);
                return item;
            }),
            providesTags : ['absence']
        }),
        getAbsenceByID : builder.query<Absence[], string | number>({
            query : (id) => `absences/${id}`,
            transformResponse: (response : {content : Absence[]}) => response.content.map((item) => {
                item.startDate = new Date(item.startDate);
                item.endDate = new Date(item.endDate);
                return item;
            })
        }),
        cancelAbsence : builder.mutation<void, {id : number, message ?: string}>({
            query : (arg: {id: number, message?: string}) => ({
                url : `absences/${arg.id}/cancel`,
                method : "POST",
                body: arg.message
            }),
            invalidatesTags :  (result, error, arg) => {
                return result ? ['absence'] : [];
            }
        }),
    })
})

