import Employee from "../../../../Types/data/Employee";
import EmployeeService from "../../../../Services/EmployeeService";
import {AbsenceFilteringOptionsEnum} from "../../../../Enums/AbsenceFilteringOptionsEnum";
import AbsenceTypeEnum from "../../../../Enums/AbsenceTypeEnum";
import SearchFilter from "../../../../Types/SearchFilter";
import ProjectState from "../../../../Types/states/ProjectState";
import PositionState from "../../../../Types/states/PositionState";

export namespace MobileAbsenceBlockTabHelper {

    export const getAbsenceTypeList = (options : AbsenceFilteringOptionsEnum) => {

        switch(options){
            case AbsenceFilteringOptionsEnum.ALL :
                return [];
            case AbsenceFilteringOptionsEnum.HOLIDAY :
                return [AbsenceTypeEnum.HOLIDAY];
            case AbsenceFilteringOptionsEnum.REMOTE :
                return [AbsenceTypeEnum.REMOTE];
            case AbsenceFilteringOptionsEnum.SICK_LEAVE :
                return [AbsenceTypeEnum.SICK_LEAVE];
        }

    }

    const getEmployeesIdByProjects = (employees : Employee[],projects : ProjectState , options : SearchFilter[]) => {
        const filteredPositions = options.filter(i => i.categoryName === "Projekty").map(i => projects.find(x => x.name === i.selectedOption )?.id)
        return employees.filter(x => x.activeProjects.some(i => filteredPositions.includes(i))).map(x => x.id);
    }

    const getEmployeesIdByPosition = (employees : Employee[], positions : PositionState , options : SearchFilter[]) => {
        const filteredPositions = options.filter(i => i.categoryName === "Role").map(i => positions.find(x => x.name === i.selectedOption )?.id)
        return employees.filter(x => x.positions.some(i => filteredPositions.includes(i))).map(x => x.id);
    }

    export const getEmployees = (employees : Employee[] , positions : PositionState ,projects : ProjectState, options : SearchFilter[]) => {
        const employeesIdByName =  options.filter(i => i.categoryName === "Pracownicy")
            .map(i => employees.find(x => EmployeeService().getEmployeeName(x) === i.selectedOption )?.id)
        const employeesIdByPosition = getEmployeesIdByPosition(employees, positions, options)
        const employeesIdByProjects = getEmployeesIdByProjects(employees,projects,options)
        const employeesSet = [...new Set([...employeesIdByName,...employeesIdByPosition,...employeesIdByProjects])]
        return employeesSet.length > 0 ? [...employeesSet] : undefined
    }

}