import "./ShowMyProposalsHistoryHoverIcons.scss";
import React, { FunctionComponent } from "react";
import reviewIcon from "../../../assets/icons/review-icon.svg";
import UseModal from "../../../Services/CustomHooks/useModal";
import {IMyProposalsHistoryTableData} from "../MyProposalsHistoryTable";

interface IShowMyProposalsHistoryHoverIconsProps {
    element: IMyProposalsHistoryTableData;
}

const ShowMyProposalsHistoryHoverIcons:FunctionComponent<IShowMyProposalsHistoryHoverIconsProps> = (
    props:IShowMyProposalsHistoryHoverIconsProps
) => {
    const proposal = props.element._proposal;
    const {historyButtonReviewProposal} = UseModal();

    return (
        <div className="proposals-hover">
            <button className="review-span btn p-0 mt-1" onClick={ (e) => {
                e.stopPropagation();
                historyButtonReviewProposal(proposal)
            } }>
                <img src={reviewIcon} alt=""/>
            </button>
        </div>
    );
};

export default ShowMyProposalsHistoryHoverIcons;
