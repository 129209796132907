import AbsenceStatus from "../CreateProposalBodyComponents/AbsenceStatus/AbsenceStatus";
import Datepicker from "../../../../../../Shared/Datepicker/Datepicker";
import React, {ChangeEvent, FC, useEffect} from "react";
import {useSelector} from "react-redux";
import RootState from "../../../../../../Types/states/RootState";
import AbsenceTypeEnum from "../../../../../../Enums/AbsenceTypeEnum";
import {Alert} from "@material-ui/lab";
import {FormikProps} from "formik";
import ErrorAlert from "../CreateProposalBodyComponents/ErrorMessage/ErrorAlert";
import EmployeeService from "../../../../../../Services/EmployeeService";
import {CreateProposalFormik} from "src/Types/states/modal/CreateProposalFormik";
import styles from "./CreateProposalBody.module.scss"
import {FilterEngine} from "@devapo/devapo-ui";
import "@devapo/devapo-ui/dist/index.css";
import {RoleEnum} from "../../../../../../Enums/RoleEnum";

interface CreateProposalBodyProps {
    formik: FormikProps<CreateProposalFormik & { role: string }>,
    result?: unknown
    isMobile ?: boolean,
}

const CreateProposalBody: FC<CreateProposalBodyProps> = (props) => {

    const {formik, result, isMobile = false} = props;
    const employeeList = useSelector((state: RootState) => state.employees);
    const role = useSelector((state: RootState) => state.user.role);
    const loggedInUserID = useSelector((state: RootState) => state.user.loggedInUserID);
    const isAdmin = role === RoleEnum.EMPLOYER

    let errorDescription: string | null = null;

    useEffect(() => {
        role === RoleEnum.EMPLOYEE && !formik.values.employee && formik.setFieldValue(RoleEnum.EMPLOYEE, loggedInUserID)
    }, [])

    if (result instanceof Object && Object(result)?.error?.data.message === '') {
        errorDescription = 'Wystąpił nieznany błąd';
    } else if (result instanceof Object && Object(result)?.error?.data.message) {
        errorDescription = Object(result).error.data.message;
    }

    const selectedEmployee = () => {
        const employee = formik.values.employee ? EmployeeService().getEmployeeById(employeeList, formik.values.employee) : undefined;

        if (employee) {
            return EmployeeService().getEmployeeName(employee)
        }
    }

    const handleSelectEmployee = (item ?: string) => {
        const employee = item ? EmployeeService().getEmployeeByName(employeeList,item) : undefined;

        if(employee){
            formik.setFieldValue("employee", employee.id)
        } else {
            formik.setFieldValue("employee", undefined)
        }
    }

    const selectedReplacement = () => {
        const employee = formik.values.replacement ? EmployeeService().getEmployeeById(employeeList, formik.values.replacement) : undefined;

        if (employee) {
            return EmployeeService().getEmployeeName(employee)
        }

    }

    const handleSelectReplacement = (item ?: string) => {
        const employee = item ? EmployeeService().getEmployeeByName(employeeList,item) : undefined;

        if(employee){
            formik.setFieldValue("replacement", employee.id)
        } else {
            formik.setFieldValue("replacement", undefined)
        }
    }

    const getDisabledList = () => {
        const employee = selectedEmployee();
        if(employee){
            return [employee]
        }
        return;
    }

    return (
        <div className={styles.create_proposal_body}>
            {errorDescription && <Alert severity="error" className={styles.error}>{errorDescription}</Alert>}

            {
                role !== RoleEnum.EMPLOYEE && (
                    <div className={styles.select_employee}>
                        <FilterEngine
                            header="Pracownik"
                            list={employeeList.map(i => EmployeeService().getEmployeeName(i))}
                            selectedItem={selectedEmployee()}
                            onSelect={handleSelectEmployee}
                            position={"bottom"}
                            placeholder={"Wybierz pracownika"}
                            multiSelect={false}
                            editable={false}
                        />

                        {(formik.errors.employee && formik.touched.employee) ?
                            <ErrorAlert>{formik.errors.employee}</ErrorAlert> : null}
                    </div>

                )
            }

            <div className={styles.absence_status}>
                <p className={styles.section_header}>Rodzaj wniosku</p>
                <AbsenceStatus formik={formik} isMobile={isMobile} />
            </div>

            <div>
                <p className={styles.section_header}>Wybierz datę</p>
                <Datepicker formik={formik} disablePast={!isAdmin} isMobile={isMobile} />
            </div>

            <fieldset className={styles.description_fieldset}>
                <legend className={styles.section_header}>Dodaj opis (opcjonalnie)</legend>
                <textarea
                    className={styles.description}
                    value={formik.values.description || undefined}
                    maxLength={255}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => formik.setFieldValue('description', e.target.value)}
                />
            </fieldset>

            {
                formik.values.absenceType !== AbsenceTypeEnum.REMOTE && (
                    <div className={styles.select_employee}>
                        <FilterEngine
                            header="Zastępca"
                            list={employeeList.map(i => EmployeeService().getEmployeeName(i))}
                            selectedItem={selectedReplacement()}
                            onSelect={handleSelectReplacement}
                            position={"top"}
                            disabledItems={getDisabledList()}
                            placeholder={"Wybierz pracownika"}
                            multiSelect={false}
                            editable={false}
                        />
                        {(formik.errors.replacement && formik.touched.replacement) ?
                            <ErrorAlert>{formik.errors.replacement}</ErrorAlert> : null}
                    </div>
                )
            }

        </div>
    )
}

export default CreateProposalBody;
