import Employee from "../../../Types/data/Employee";
import React, {FC} from "react";
import EmployeeService from "../../../Services/EmployeeService";

interface CalendarUsers {
    employees : Employee[]
}

const CalendarUsers : FC<CalendarUsers> = (props) => {

    const {employees} = props;
    const {getEmployeeName} = EmployeeService();

    return (
        <div className="users-container">
            <div className="empty-head empty"/>
            {
                employees.map((employee :Employee, index : number) => (
                    <div className={` ${index === 0 ? "sticky-user user" : "user"} `} key={`user-${index}`} >
                        {
                            getEmployeeName(employee,true)
                        }
                    </div>
                ))
            }
            <div className="empty-foot empty"/>
        </div>
    )
}

export default CalendarUsers