import removePolishchars from "src/helpers/removePolishChars";
import Employee from "../Types/data/Employee";
import {RoleEnum} from "../Enums/RoleEnum";

const EmployeeService = () => {
    const now = new Date();

    const getEmployeeById = (employees:Employee[], id: number):Employee|null => {
        const foundEmployee = employees.find((employee:Employee) => employee.id === id);

        return foundEmployee ? foundEmployee : null;
    };

    const getEmployeeName = (employee:Employee, reverse = false):string => {
        return [employee.lastName.trim(), employee.firstName.trim()].join(" ");
    };

    const getEmployeeNameList = (employees:Employee[]) => {
        return employees.map((employee:Employee) => {
            return getEmployeeName(employee);
        });
    };

    const getEmployeeByName = (employees:Employee[], fullName:string):Employee|null => {
        let found = employees.find((employee:Employee) => {
            return removePolishchars(getEmployeeName(employee)).trim().replace('  ', ' ') === removePolishchars(fullName).trim();
        });

        if (!found) {
            found = employees.find((employee:Employee) => {
                return removePolishchars(getEmployeeName(employee)).trim().replace('  ', ' ') === removePolishchars(fullName.split(' ')[1] + ' ' + fullName.split(' ')[0]).trim();
            });
        }

        return found ? found : null;
    }

    const getEmployeeByEmail = (employees:Employee[], email:string):Employee|null => {
        let found = employees.find((employee:Employee) => {
            return email === employee.email;
        });

        return found ?? null;
    };

    const getRole = (): any => {
        return RoleEnum.EMPLOYER
    };

    return {
        getEmployeeNameList,
        getRole,
        getEmployeeById,
        getEmployeeName,
        getEmployeeByName,
        getEmployeeByEmail
    }
}

export default EmployeeService;