import React, {useEffect, useState} from "react";
import UseModal from "../../../../../Services/CustomHooks/useModal";
import AbsenceType from "../../../../../Shared/Partials/AbsenceType/AbsenceType";
import EmployeeData from "../../../../../Shared/Partials/EmployeeData/EmployeeData";
import ReplacementPersonView from "../../../../../Shared/Partials/ReplacementPersonView/ReplacementPersonView";
import HolidayAbsenceType from "../../../../../Shared/Partials/HolidayAbsenceType/HolidayAbsenceType";
import Description from "../../../../../Shared/Partials/Description/Description";
import {useSelector} from "react-redux";
import RootState from "../../../../../Types/states/RootState";
import {ModalHeadersEnum} from "../../../../../Enums/ModalHeadersEnum";
import DesktopModalHeader from "../../../ModalComponents/ModalHeader/DesktopModalHeader";
import {DesktopModalHeaderHelper} from "../../../ModalComponents/ModalHeader/DesktopModalHeaderHelper";
import AbsenceDateRange from "../../../../../Shared/Partials/AbsenceDateRange/AbsenceDateRange";
import EmployeeProjects from "../../../../../Shared/Partials/EmployeeProjects/EmployeeProjects";
import styles from "./CancelProposalModal.module.scss"
import {Button} from "@devapo/devapo-ui";

const CancelProposalModal = ({doDeclineProposal}: { doDeclineProposal: () => void }) => {
    const {backToPrevModal} = UseModal()
    const {currentProposal} = useSelector((state : RootState) => state.modalPopup)
    const [header,setHeader] = useState<ModalHeadersEnum | undefined>()

    useEffect(() => {
        DesktopModalHeaderHelper.getModalCancelProposalHeaderEnum(setHeader,currentProposal)
    },[currentProposal])

    return (
        <form className={styles.modal_block} >

            <DesktopModalHeader
                header={header}
                startDate={currentProposal.absenceStartDate}
                endDate={currentProposal.absenceFinishDate}
                absenceType={currentProposal.absenceType}
            />

            <div>
                <EmployeeData employee={currentProposal.employee}/>
                <AbsenceType type={currentProposal.absenceType}/>
                <HolidayAbsenceType type={currentProposal.holidayType} absence={currentProposal.absenceType} />
                <AbsenceDateRange
                    startDate={currentProposal.absenceStartDate}
                    endDate={currentProposal.absenceFinishDate}
                />
                <Description description={currentProposal.description}/>
                <EmployeeProjects proposal={currentProposal} />
                <ReplacementPersonView replacement={currentProposal.replacement}/>
            </div>

            <div className={styles.footer} >
                <Button
                    text={"Anuluj"}
                    onClick={backToPrevModal}
                    type={"button"}
                    color={"normal"}
                />
                <Button
                    text={"Odwołaj nieobecność"}
                    onClick={doDeclineProposal}
                    type={"button"}
                    color={"warning"}
                />
            </div>

        </form>
    )
}

export default CancelProposalModal