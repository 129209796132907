import {createSlice} from "@reduxjs/toolkit";
import {filteringEngineInitialState} from "../initialStates/filteringEngineInitialState";
import SearchFilter from "../../Types/SearchFilter";

const filteringEngineSlice = createSlice({
    name: "filteringEngineSlice",
    initialState: filteringEngineInitialState,
    reducers: {
        setSelectedOption(state, action) {
            state.selectedOption = action.payload.selectedOption
        },
        setInputValue(state, action) {
            state.inputValue = action.payload.inputValue
        },
        setInputOpen(state, action) {
            state.inputOpen = action.payload.inputOpen
        },
        setBackgroundInputValue(state, action) {
            state.backgroundInputValue = action.payload.backgroundInputValue
        },
        setBackgroundType(state, action) {
            state.backgroundType = action.payload.backgroundType
        },
        setEmployeesFilter(state, action) {
            state.employeesFilter = action.payload.employeesFilter
        },
        setPositionFilter(state, action) {
            state.positionFilter = action.payload.positionFilter
        },
        setProjectFilter(state, action) {
            state.projectFilter = action.payload.projectFilter
        },
        addSelectedFilter(state,action){
            state.selectedFilters = state.selectedFilters.concat(action.payload.selectedFilters)
        },
        removeSelectedFilter(state,action){
            state.selectedFilters = state.selectedFilters.filter((filter:SearchFilter) => {
                return filter.selectedOption !== action.payload.selectedFilters;
            });
        },
        removeAllSelectedFilters(state) {
            state.selectedFilters = [];
        },
    }
})

export const {
    setSelectedOption,
    setInputValue,
    setInputOpen,
    setBackgroundInputValue,
    setBackgroundType,
    setEmployeesFilter,
    setPositionFilter,
    setProjectFilter,
    addSelectedFilter,
    removeSelectedFilter,
    removeAllSelectedFilters
} = filteringEngineSlice.actions

export default filteringEngineSlice.reducer