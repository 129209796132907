import DateService from "src/Services/DateService";

export default function generateCustomDateComparator<T>(key: keyof T) {
    return (a:T, b:T) => {
        return DateService.compareDates(
            DateService.getDateFromString((a[key] as unknown as string).replaceAll('.', '-'), true),
            DateService.getDateFromString((b[key] as unknown as string).replaceAll('.', '-'), true)
        );
    };
}
