import DataService from "../../Services/DataService";
import loadAbsences from "./loadAbsences";
import loadProposals from "./loadProposals";
import { batch } from "react-redux";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {reduxSliceTypeEnum} from "../reduxSliceTypeEnum/reduxSliceTypeEnum";

const acceptProposal = createAsyncThunk(reduxSliceTypeEnum.ACCEPT_PROPOSAL, async (proposalId : number, {dispatch}) => {
    await DataService.acceptProposal(proposalId);

    batch(() => {
        dispatch(loadProposals());
        dispatch(loadAbsences());
    });
})

export default acceptProposal;
