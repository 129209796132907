import {useSelector} from "react-redux";
import RootState from "../../../../Types/states/RootState";
import React, {useEffect} from "react";
import UseModal from "../../../../Services/CustomHooks/useModal";
import FilteringEngineService from "../../../../Services/FilteringEngineService";
import DateFormatService from "../../../../Services/DateFormatService";
import toast from "react-hot-toast";
import {CurrentModalNameEnum} from "../../../../Enums/CurrentModalNameEnum";
import AcceptProposalModal from "./AcceptProposalModal/AcceptProposalModal";
import {api} from "../../../../ReduxStore/api/api";
import {RtkQueryHelper} from "../../../../helpers/rtk-query.helper";
import styles from "../../Modals.module.scss";
import {Modal} from "@devapo/devapo-ui"

const AcceptProposal = () => {
    const {currentOpenModal,currentProposal} = useSelector((state : RootState) => state.modalPopup)
    const [acceptProposal, result] = api.useAcceptProposalMutation()
    const employees = useSelector((state:RootState) => state.employees);
    const {closeAll} = UseModal()
    const {getEmployeeNameByID} = FilteringEngineService();
    const {toShortDate} = DateFormatService();

    const showSuccessToast = () => {
        toast.success(`Nieobecność dla ${getEmployeeNameByID(employees, currentProposal.employee)} w dn. 
        ${toShortDate(currentProposal.absenceStartDate).props.children.join('')} -
        ${toShortDate(currentProposal.absenceFinishDate).props.children.join('')} została zaakceptowana`);
    }

    useEffect(() => {
        if(RtkQueryHelper.checkResultIsFulfilled(result)){
            closeAll();
            showSuccessToast()
        }
    },[result])

    const doAcceptProposal = () => {
        acceptProposal(currentProposal.id)
    }

    return (
        <Modal
            isOpen={currentOpenModal === CurrentModalNameEnum.ACCEPT_RECEIVED_PROPOSAL}
            onClose={() => closeAll()}
            modalClass={styles.modal}
            backdropClass={styles.backdrop}
        >
            <AcceptProposalModal  doAcceptProposal={doAcceptProposal} />
        </Modal>
    )
}

export default AcceptProposal
