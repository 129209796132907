import {createSlice} from "@reduxjs/toolkit";
import employeeInitialState from "../initialStates/employeeInitialState";
import { employeeApi } from "../api/employeeApi";

const employeesSlice = createSlice({
    name: 'employees',
    initialState: employeeInitialState,
    reducers: {},
    extraReducers :  (builder ) => {
        builder.addMatcher( employeeApi.endpoints.getAllActiveEmployees.matchFulfilled , (state, {payload}) => {
            return payload.content
        } );
    }
})


export default employeesSlice.reducer
