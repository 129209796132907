import Proposal from "../../Types/data/Proposal";
import AbsenceTypeEnum from "../../Enums/AbsenceTypeEnum";
import checkHolidayTypeEnum from "../../helpers/checkHolidayTypeEnum";
import AbsenceTypeFormatterService from "../../Services/AbsenceTypeFormatterService";


const MobileAbsenceType = ({proposal} : {proposal : Proposal}) => {
    const {showStatus} = AbsenceTypeFormatterService();

    if (proposal.absenceType === AbsenceTypeEnum.HOLIDAY) {
        return (
            <div className="pl-2">
                <p className="mb-0 pt-1 absence-type">{showStatus(proposal.absenceType)} {proposal.holidayType && checkHolidayTypeEnum(proposal.holidayType)}</p>
            </div>
        )
    }
    return (
        <div className="pl-2">
            <p className="mb-0 pt-1 absence-type">{showStatus(proposal.absenceType)}</p>
        </div>
    )
}

export default MobileAbsenceType