import SortOrder from "../../../Types/SortOrder";

const now = new Date();

export type absenceTabState = {
    absenceTabSortOrder : SortOrder,
    absenceTabSortBy : string,
    absenceTabDateFrom : Date,
    absenceTabDateTo : Date
}

const absenceTabInitialState : absenceTabState = {
    absenceTabSortOrder : 'asc',
    absenceTabSortBy : 'startDate',
    absenceTabDateFrom : new Date(),
    absenceTabDateTo : new Date( now.getFullYear(), now.getMonth() + 1, 0)
}

export default absenceTabInitialState