import DataService from "../../Services/DataService";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {reduxSliceTypeEnum} from "../reduxSliceTypeEnum/reduxSliceTypeEnum";

const loadAbsences = createAsyncThunk(reduxSliceTypeEnum.LOAD_ABSENCE, async () => {
    const absences = await DataService.getAbsences();

    return {absences}
})



export default loadAbsences;
