import Position from "src/Types/data/Position";
import Employee from "../Types/data/Employee";

export default class PositionService {
    static getPositionIdByName(positions:Position[], name:string):number|null {
        const found = positions.find((position:Position) => {
            return position.name === name;
        });

        return found?.id ?? null;
    }

    static getPositionById(positions:Position[], id:number):Position|null {
        return positions.find((position:Position) => position.id === id) ?? null;
    }

    static getPositionIdByUserName(employees : Employee[] ,name : string) : number | undefined {
        const employee = employees.find((employee : Employee) => [employee.firstName, employee.lastName].join(" ").toLowerCase() === name.toLowerCase());
        if(employee){
            return employee.positions[0];
        }

        return undefined;
    }
}
