import {createSlice} from "@reduxjs/toolkit";
import modalPopupInitialState from "../initialStates/ModalPopupInitialState";
import {CurrentModalNameEnum} from "../../Enums/CurrentModalNameEnum";

const modalPopupSlice = createSlice({
    name: 'modalPopup',
    initialState: modalPopupInitialState,
    reducers: {
        setCurrentOpenModal(state, action){
            if(state.currentOpenModal !== action.payload.currentOpenModal){
                state.modalHistory.push(state.currentOpenModal as CurrentModalNameEnum)
                state.currentOpenModal = action.payload.currentOpenModal;
            }else {
                state.currentOpenModal = action.payload.currentOpenModal;
            }
        },
        undoCurrentOpenModal(state){
            const undoModal = state.modalHistory.pop();
            state.currentOpenModal = undoModal as CurrentModalNameEnum;
        },
        clearModalHistory(state){
            state.currentOpenModal = null;
            state.modalHistory = []
        },
        setCurrentOpenProposal(state,action){
            state.currentProposal = action.payload.currentProposal;
        },
        setCurrentOpenAbsence(state,action){
            state.currentAbsence = action.payload.currentAbsence;
        },
        setHistoryBlock(state,action){
            state.isHistoryBlock = action.payload.isHistoryBlock
        }
    },
})

export const {
    undoCurrentOpenModal,
    clearModalHistory,
    setCurrentOpenProposal,
    setCurrentOpenModal,
    setHistoryBlock,
    setCurrentOpenAbsence
} = modalPopupSlice.actions
export default modalPopupSlice.reducer