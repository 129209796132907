import React, {Dispatch, FC, SetStateAction} from "react";
import {ProposalFilteringOptionsEnum} from "../../../../Enums/ProposalFilteringOptionsEnum";
import classNames from "classnames";
import styles from "./MobileActionsHistoryTopBar.module.scss"

interface MobileActionsHistoryTopBarProps {
    setStatus : Dispatch<SetStateAction<ProposalFilteringOptionsEnum>>
    status : ProposalFilteringOptionsEnum
}

const MobileActionsHistoryTopBar : FC<MobileActionsHistoryTopBarProps> = (props) => {

    const {setStatus,status} = props;

    const handleClick = (e : React.MouseEvent<HTMLButtonElement> ) => {
        const {currentTarget} = e

        const value= currentTarget.value as ProposalFilteringOptionsEnum
        setStatus(value)
    }

    return (
        <div className={styles.top_bar} >
                    <button
                            className={classNames({
                                [styles.proposal_button] : true,
                                [styles.active] : status === ProposalFilteringOptionsEnum.ALL
                            })}
                            value={ProposalFilteringOptionsEnum.ALL}
                            onClick={handleClick}
                    >
                        {ProposalFilteringOptionsEnum.ALL}
                    </button>
                    <button
                            className={classNames({
                                [styles.proposal_button] : true,
                                [styles.active] : status === ProposalFilteringOptionsEnum.ACCEPTED
                            })}
                            value={ProposalFilteringOptionsEnum.ACCEPTED}
                            onClick={handleClick}
                    >
                        {ProposalFilteringOptionsEnum.ACCEPTED}
                    </button>
                    <button
                            className={classNames({
                                [styles.proposal_button] : true,
                                [styles.active] : status === ProposalFilteringOptionsEnum.DECLINED
                            })}
                            value={ProposalFilteringOptionsEnum.DECLINED}
                            onClick={handleClick}
                    >
                        {ProposalFilteringOptionsEnum.DECLINED}
                    </button>
        </div>
    )
}

export default MobileActionsHistoryTopBar