import { batch, useDispatch } from "react-redux";
import { employeeApi } from "../../ReduxStore/api/employeeApi";
import React, { FC, useEffect, useState } from "react";
import { KeycloakService } from "./KeycloakService";
import { EmailService } from "../../Services/EmailService";
import {
  setLoggedIDUser,
  setLoggedUser,
  setUserEmail,
  setUserFamilyName,
  setUserGivenName, setUserRole
} from "../../ReduxStore/reduxSlices/userSlice";
import { RoleEnum } from "../../Enums/RoleEnum";

interface UserProfileProps{
  children?: React.ReactNode;
}

const UserProfile: FC<UserProfileProps> = (props) => {

  const {children} = props;
  const [userValid, setUserValid] = useState(false)
  const dispatch = useDispatch();
  const loggedUserEmail = KeycloakService.email()

  const {data} = employeeApi.useGetEmployeesByParamsQuery({
    email: loggedUserEmail
  })

  useEffect(() => {
    if(KeycloakService.authenticated() && data?.content){
      loadAuthUserProfile()
    }
  },[data])

  const loadAuthUserProfile = () => {
    data && KeycloakService.loadUserProfile().then(profile => {
      const {firstName, lastName, email} = profile
      const loggedUser = data.content[0]
      let employeeEmail : string;

      if(process.env.NODE_ENV !== 'production'){
        employeeEmail = EmailService.remove1BeforeAt(loggedUser.email)
      } else {
        employeeEmail = loggedUser.email;
      }

      if(!EmailService.compare(employeeEmail, email)){
        KeycloakService.loginAgain()
        return;
      }

      batch(() => {
        dispatch(setLoggedUser({user : lastName + " " + firstName}))
        dispatch(setLoggedIDUser({id : loggedUser.id}))
        dispatch(setUserEmail({email : employeeEmail}))
        dispatch(setUserFamilyName({family_name : lastName}))
        dispatch(setUserGivenName({given_name : firstName}))

        if(KeycloakService.hasRealmRole(RoleEnum.ADMIN) || KeycloakService.hasRealmRole(RoleEnum.EMPLOYER) ){
          dispatch(setUserRole({role : RoleEnum.EMPLOYER }))
        } else {
          dispatch(setUserRole({role : RoleEnum.EMPLOYEE }))
        }

        setUserValid(true)
      })
    }).catch(() => KeycloakService.loginAgain())
  }

  if(!userValid){
    return null
  }

  return (
    <>
      {children}
    </>
  )
}

export default UserProfile