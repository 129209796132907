export enum MonthsNameEnum {
    January="Styczeń",
    February="Luty",
    March="Marzec",
    April="Kwiecień",
    May="Maj",
    June="Czerwiec",
    July="Lipiec",
    August="Sierpień",
    September="Wrzesień",
    October="Październik",
    November="Listopad",
    December="Grudzień"
}
