import {FormikProps} from "formik";
import AbsenceTypeEnum from "../../../../../../../../Enums/AbsenceTypeEnum";
import HolidayTypeEnum from "../../../../../../../../Enums/HolidayTypeEnum";
import checkHolidayTypeEnum from "../../../../../../../../helpers/checkHolidayTypeEnum";
import ErrorAlert from "../../ErrorMessage/ErrorAlert";
import React, { FC } from "react";
import { CreateProposalFormik } from "src/Types/states/modal/CreateProposalFormik";
import styles from "./RenderHolidayTypes.module.scss"
import classNames from "classnames";

interface RenderVacationTypesProps {
    formik : FormikProps<CreateProposalFormik & { role: string }>,
    isMobile : boolean
}

const RenderHolidayTypes : FC<RenderVacationTypesProps> = (props) => {

    const {formik,isMobile} = props;

    if (formik.values.absenceType !== AbsenceTypeEnum.HOLIDAY) {
        return <></>
    }

    return (
        <div className={styles.holiday_type} >
            <p className={styles.section_header} >Typ</p>
            <div className={classNames({
                [styles.holiday_types] : !isMobile,
                [styles.holiday_types_mobile] : isMobile
            })} >
                {
                    Object.values(HolidayTypeEnum).map((type: HolidayTypeEnum, index: number) => (
                        <button
                            type="button"
                            key={index}
                            className={classNames({
                                [styles.type] : true,
                                [styles.type_selected] : formik.values.holidayType === HolidayTypeEnum[type],
                                [styles.type_hover] : formik.values.holidayType !== HolidayTypeEnum[type],
                            })}
                            onClick={() => formik.setFieldValue('holidayType', type)}
                        >
                            <p>
                                {checkHolidayTypeEnum(type)}
                            </p>
                        </button>
                    ))
                }
            </div>
            <div className={styles.holiday_error} >
                {(formik.errors.holidayType && formik.touched.holidayType) ?
                    <ErrorAlert>{formik.errors.holidayType}</ErrorAlert> : null}
            </div>
        </div>
    )

}

export default RenderHolidayTypes