import "./UiTableHeadIcon.scss";
import { FunctionComponent } from 'react';
import { ReactComponent as UiTableHeadArrow } from '../../../assets/icons/arrow-icon.svg';
import SortOrder from '../../../Types/SortOrder';

interface IUiTableHeadIconProps {
    sortOrder: SortOrder;
    hidden?: boolean;
}

const UiTableHeadIcon:FunctionComponent<IUiTableHeadIconProps> = (props:IUiTableHeadIconProps) => {
    return (
        <UiTableHeadArrow className={'ui-table-head-icon' + (props.sortOrder === 'asc' ? '' : ' inverted') + (props.hidden ? ' hidden' : '')}/>
    );
};

export default UiTableHeadIcon;
