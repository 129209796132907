import ClearWhiteIcon from "../../../../../assets/icons/clear-icon-white.svg";
import React from "react";
import {useSelector} from "react-redux";
import RootState from "../../../../../Types/states/RootState";

const AbsenceFiltersList = ({removeFilter} : {removeFilter : (item : string) => void}) => {

    const selectedFilters = useSelector((state: RootState) => state.filteringEngine.selectedFilters);
    const filters : string[] = selectedFilters.map(f => f.selectedOption);

    return (
        <>
            {
                filters.length > 0 && (
                    <div className="filters-list mt-1">
                        {
                            filters.map(item => (
                                <div className="filter-item">
                                    <p>{item}</p>
                                    <img src={ClearWhiteIcon} onClick={() => removeFilter(item)}
                                         className="filter-remove-icon" alt="remove filter"/>
                                </div>
                            ))
                        }
                    </div>
                )
            }
        </>
    )
}

export default AbsenceFiltersList