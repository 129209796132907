import {useSelector} from "react-redux";
import RootState from "../../../Types/states/RootState";
import EmployeeService from "../../../Services/EmployeeService";
import Project from "../../../Types/data/Project";
import Employee from "../../../Types/data/Employee";
import Absence from "../../../Types/data/Absence";
import Proposal from "../../../Types/data/Proposal";
import {useEffect, useState} from "react";
import styles from "./EmployeeProjects.module.scss"

const EmployeeProjects = ({proposal} : {proposal : Proposal | Absence}) => {
    const employeeList = useSelector((state: RootState) => state.employees);
    const projects = useSelector((state: RootState) => state.projects);

    const [employeeProjects, setEmployeeProject] = useState<Project[] | null>([])
    const {getEmployeeById} = EmployeeService();

    useEffect(() => {
        const foundEmployee: Employee | null = getEmployeeById(employeeList, proposal.employee)
        const foundProjects: Project[] | null = foundEmployee && projects.filter(project => foundEmployee.activeProjects.includes(project.id));
        setEmployeeProject(foundProjects)
    },[proposal])

    return (
        <>
            {
                employeeProjects && (
                    <div className={styles.employee_projects}>
                        <p className={styles.modal_heading}>Bierze udział w projektach:</p>
                        <ul>
                            {
                                employeeProjects.map((project : Project, index : number) => (
                                    <li key={index} className={styles.data}>{project.name}</li>
                                ))
                            }
                        </ul>
                    </div>
                )
            }
        </>
    )
}

export default EmployeeProjects;