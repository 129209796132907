import DataService from "../../Services/DataService";
import SortOrder from "../../Types/SortOrder";
import {DateRange} from "../../Types/DateRange";
import {createAsyncThunk} from "@reduxjs/toolkit";
import Absence from "../../Types/data/Absence";
import {reduxSliceTypeEnum} from "../reduxSliceTypeEnum/reduxSliceTypeEnum";

const loadSortedAndFilteredAbsences = createAsyncThunk(reduxSliceTypeEnum.LOAD_SORTED_AND_FILTERED_ABSENCES, async ({ sortBy, sortOrder, dateFilter }:{ sortBy: string, sortOrder: SortOrder, dateFilter: DateRange }) => {
    const loadSortedAndFilteredAbsences: Absence[] = await DataService.getSortedAndFilteredAbsences(sortBy, sortOrder, dateFilter);

    return {loadSortedAndFilteredAbsences}
})


export default loadSortedAndFilteredAbsences