import {useSelector} from "react-redux";
import React, {ChangeEvent, FC, useEffect, useState} from "react";
import UseModal from "../../../../../Services/CustomHooks/useModal";
import RootState from "../../../../../Types/states/RootState";
import EmployeeData from "../../../../../Shared/Partials/EmployeeData/EmployeeData";
import AbsenceType from "../../../../../Shared/Partials/AbsenceType/AbsenceType";
import HolidayAbsenceType from "../../../../../Shared/Partials/HolidayAbsenceType/HolidayAbsenceType";
import ReplacementPersonView from "../../../../../Shared/Partials/ReplacementPersonView/ReplacementPersonView";
import DesktopModalHeader from "../../../ModalComponents/ModalHeader/DesktopModalHeader";
import {ModalHeadersEnum} from "../../../../../Enums/ModalHeadersEnum";
import {FormikProps} from "formik";
import {DesktopModalHeaderHelper} from "../../../ModalComponents/ModalHeader/DesktopModalHeaderHelper";
import styles from "./DeleteAbsenceModal.module.scss"
import ReasonMessage from "../../../../../Shared/Partials/ReasonMessage/ReasonMessage";
import {Button} from "@devapo/devapo-ui";


interface DeleteAbsenceModalProps {
    formik: FormikProps<{ canceledReasonText: string }>
}

const DeleteAbsenceModal : FC<DeleteAbsenceModalProps> = (props) => {
    const {formik} = props;
    const {currentAbsence} = useSelector((state: RootState) => state.modalPopup)
    const {backToPrevModal} = UseModal()
    const [header, setHeader] = useState<ModalHeadersEnum | undefined>()

    useEffect(() => {
        DesktopModalHeaderHelper.getModalDeleteAbsenceHeaderEnum(setHeader, currentAbsence)
    }, [currentAbsence])

    const handleReasonChange = (e : ChangeEvent<HTMLTextAreaElement>) => {
        formik.setFieldValue("canceledReasonText" , e.target.value)
    }

    return (
        <div className={styles.modal_block} >

            <DesktopModalHeader
                header={header}
                startDate={currentAbsence.startDate}
                endDate={currentAbsence.endDate}
                absenceType={currentAbsence.absenceType}
            />

            <div>
                <EmployeeData employee={currentAbsence.employee}/>
                <AbsenceType type={currentAbsence.absenceType}/>
                <HolidayAbsenceType type={currentAbsence.holidayType} absence={currentAbsence.absenceType}/>
                <ReplacementPersonView replacement={currentAbsence.replacement}/>
                <ReasonMessage
                    header={"Powód odwołania nieobecności"}
                    value={formik.values.canceledReasonText}
                    onChange={handleReasonChange}
                />
            </div>

            <div className={styles.footer} >
                <Button
                    text={"Anuluj"}
                    onClick={() => backToPrevModal()}
                    type={"button"}
                    color={"normal"}
                />
                <Button
                    text={"Odwołaj nieobecność"}
                    type={"submit"}
                    onClick={() => formik.handleSubmit()}
                    color={"warning"}
                />
            </div>

        </div>
    )
}

export default DeleteAbsenceModal