import MonthChanger from "../../../Shared/MonthChanger/MonthChanger";
import React, {useState} from "react";
import {MonthsNameEnum} from "../../../Enums/MonthsNameEnum";
import {
    MobileContentSelectAbsenceTopBarMonth,
    MobileContentSelectAbsenceTopBarYear
} from "../../../ReduxStore/reduxSlices/mobileContentSlice";
import {MonthYearDate} from "../../../Types/MonthYearDate";
import {useDispatch, useSelector} from "react-redux";
import RootState from "../../../Types/states/RootState";
import AbsenceTopBarView from "./AbsenceTopBarView/AbsenceTopBarView";
import AbsenceEmployeeList from "./AbsenceEmployeeList/AbsenceEmployeeList";
import AbsenceFilteringEngine from "./AbsenceFilteringEngine/AbsenceFilteringEngine";
import searchIcon from "../../../assets/icons/search-icon.svg"
import searchGreenIcon from "../../../assets/icons/search-icon-green.svg"
import {
    MobileAbsenceBlockTabHelper
} from "./MobileAbsenceBlockTabHelper/MobileAbsenceBlockTabHelper";
import {api} from "../../../ReduxStore/api/api";
import DateService from "../../../Services/DateService";
import Employee from "../../../Types/data/Employee";

const MobileAbsenceBlockTab = () => {

    const chosenOption = useSelector((state: RootState) => state.mobileContent.absenceTopBarTab);
    const selectedMonth = useSelector((state: RootState) => state.mobileContent.absenceTopBarMonth);
    const selectedYear = useSelector((state: RootState) => state.mobileContent.absenceTopBarYear);
    const selectedFilters = useSelector((state: RootState) => state.filteringEngine.selectedFilters);
    const employees = useSelector((state: RootState) => state.employees);
    const positions = useSelector((state: RootState) => state.positions);
    const projects = useSelector((state: RootState) => state.projects);
    const [filterOpen, setFilterOpen] = useState<boolean>(false)
    const dispatch = useDispatch();
    const currentDate = new Date()

    const {
        data: AbsenceToday,
        isLoading: AbsenceTodayLoading,
        isFetching: AbsenceTodayFetching
    } = api.useGetAbsencesWithParamsQuery({
        startDate: DateService.stringifyDate(currentDate, true),
        endDate: DateService.stringifyDate(currentDate, true),
        absenceType: MobileAbsenceBlockTabHelper.getAbsenceTypeList(chosenOption),
        employee: MobileAbsenceBlockTabHelper.getEmployees(employees, positions, projects, selectedFilters) ||  employees.map((employee : Employee) => employee.id),
    });

    const {
        data: AbsenceInMonth,
        isLoading: AbsenceInMonthLoading,
        isFetching: AbsenceInMonthFetching
    } = api.useGetAbsencesWithParamsQuery({
        startDate: DateService.stringifyDate(new Date(selectedYear, selectedMonth - 1, 1), true),
        endDate: DateService.stringifyDate(new Date(selectedYear, selectedMonth, 0), true),
        absenceType: MobileAbsenceBlockTabHelper.getAbsenceTypeList(chosenOption),
        employee: MobileAbsenceBlockTabHelper.getEmployees(employees, positions, projects, selectedFilters) || employees.map((employee : Employee) => employee.id),
    });

    const changeToNextMonth = (): void => {
        if (currentMonthAndYear.month === getMonthIndex(MonthsNameEnum.December) + 1) {
            dispatch(MobileContentSelectAbsenceTopBarMonth({absenceTopBarMonth: getMonthIndex(MonthsNameEnum.January) + 1}))
            dispatch(MobileContentSelectAbsenceTopBarYear({absenceTopBarYear: currentMonthAndYear.year + 1}))
        } else {
            dispatch(MobileContentSelectAbsenceTopBarMonth({absenceTopBarMonth: currentMonthAndYear.month + 1}))
        }
    }

    const changeToPrevMonth = (): void => {
        if (currentMonthAndYear.month === getMonthIndex(MonthsNameEnum.January) + 1) {
            dispatch(MobileContentSelectAbsenceTopBarMonth({absenceTopBarMonth: getMonthIndex(MonthsNameEnum.December) + 1}))
            dispatch(MobileContentSelectAbsenceTopBarYear({absenceTopBarYear: currentMonthAndYear.year - 1}))
        } else {
            dispatch(MobileContentSelectAbsenceTopBarMonth({absenceTopBarMonth: currentMonthAndYear.month - 1}))
        }
    }

    const currentMonthAndYear: MonthYearDate = {
        month: selectedMonth,
        year: selectedYear
    };

    const getMonthIndex = (monthName: MonthsNameEnum): number => Object.values(MonthsNameEnum).indexOf(monthName);

    return (
        <>
            <div className="show-filter-icon">
                <img src={filterOpen ? searchGreenIcon : searchIcon} onClick={() => setFilterOpen(!filterOpen)}
                     alt="show filter" className="search-icon"/>
            </div>
            {
                filterOpen && (
                    <AbsenceFilteringEngine/>
                )
            }
            <AbsenceTopBarView/>
            <div className="mt-3">
                <h6 className="ml-4 mb-3">Dzisiaj</h6>
                {
                    AbsenceToday && !AbsenceTodayLoading && !AbsenceTodayFetching && (
                        <AbsenceEmployeeList list={AbsenceToday} currentView="Now"/>
                    )
                }
            </div>
            <div className="planned-absence-list">
                <div className="planned-absence-list_div">
                    <h6 className="mt-2">Nieobecności</h6>
                    <MonthChanger currentDate={currentMonthAndYear} changeToPrevMonth={changeToPrevMonth}
                                  changeToNextMonth={changeToNextMonth}/>
                </div>

                {
                    AbsenceInMonth && !AbsenceInMonthLoading && !AbsenceInMonthFetching && (
                        <AbsenceEmployeeList list={AbsenceInMonth} currentView="Planned"/>
                    )
                }
            </div>
        </>
    )
}

export default MobileAbsenceBlockTab