import { CreateProposalFormik } from "src/Types/states/modal/CreateProposalFormik";
import AbsenceTypeEnum from "../../../Enums/AbsenceTypeEnum";
import DateService from "../../../Services/DateService";

const createProposalModalContentInitialState:CreateProposalFormik = {
    employee: null,
    requester: null,
    absenceType: null,
    holidayType: null,
    absenceStartDate: null,
    absenceFinishDate: null,
    description: null,
    replacement: null
};

export default createProposalModalContentInitialState;
