import ProposalStatusEnum from "../../../Enums/ProposalStatusEnum";
import AbsenceBlockTabEnum from "../../../Enums/AbsenceBlockTabEnum";
import React, {FC} from "react";
import {useSelector} from "react-redux";
import RootState from "../../../Types/states/RootState";
import { api } from "src/ReduxStore/api/api";
import styles from "./bar.module.scss"
import classNames from "classnames";
import Employee from "../../../Types/data/Employee";

interface InProgressBarProps {
    onClick : (absenceBlockTab:AbsenceBlockTabEnum) => void,
    isMobile : boolean
}

const InProgressProposalsBar : FC<InProgressBarProps> = (props) => {
    const{onClick,isMobile} = props
    const selectedAbsenceBlockTab = useSelector((state:RootState) => state.content.selectedBlockTab);
    const employees = useSelector((state: RootState) => state.employees);

    const { data : inProgress } = api.useGetProposalsWithParamsQuery({
      employee: employees.map((employee : Employee) => employee.id),
      proposalStatus : [ProposalStatusEnum.IN_PROGRESS]
    })

    const renderProposalListName = () => isMobile ? "Wnioski" : "Wnioski do akceptacji";

    const handleClick = () => onClick(AbsenceBlockTabEnum.PROPOSALS)

    return (
        <div className={styles.bar_option} >
            <button
                className={classNames({
                    [styles.option] : true,
                    [styles.selected] : selectedAbsenceBlockTab === AbsenceBlockTabEnum.PROPOSALS
                })}
                onClick={handleClick}>
                {renderProposalListName()}
            </button>
            <div className={styles.number} >{inProgress ? inProgress.length : 0}</div>
        </div>
    )
}

export default InProgressProposalsBar;