import {createSlice, isFulfilled, isRejected} from "@reduxjs/toolkit";
import ErrorService from "../../Services/ErrorService";
import errorInitialState from "../initialStates/errorInitialState";
import IReduxError from "../../Types/IReduxError";
import {KeycloakService} from "../../Layout/Auth/KeycloakService";

const errorSlice = createSlice({
    name: 'error',
    initialState: errorInitialState,
    reducers: {
        catchUnhandledError(state,action){
            state.push({
                error: action.payload,
                action: action.type,
                id: ErrorService.getNewErrorId(state),
                cleared: false,
                time: Date.now()
            } as IReduxError);
        }
    },
    extraReducers :  (builder ) => {
        builder.addMatcher( isRejected, (state, action) => {

            // TODO dodać profile - https://devapo.atlassian.net/jira/software/projects/SR/issues/SR-640
            if( Object(action).payload?.status === 401){
                KeycloakService.loginAgain()
            }


            state.forEach((error:IReduxError) => {
                if (error.action === action.type) {
                    error.cleared = true;
                }
            })

            state.push({
                error: action.error,
                action: action.type,
                id: ErrorService.getNewErrorId(state),
                cleared: false,
                time: Date.now()
            } as IReduxError);

            return state;
        } )
        builder.addMatcher( isFulfilled, (state, action) => {
            return state.forEach((error:IReduxError) => {
                if (error.action.replace('/rejected', '') === action.type.replace('/fulfilled', '')) {
                    error.cleared = true;
                }
            });
        } )
        builder.addDefaultCase((state) => {})
    }
})

export const {catchUnhandledError} = errorSlice.actions
export default errorSlice.reducer