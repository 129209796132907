export default function removePolishchars(str:string) {
    return str
        .replace('ą', 'a')
        .replace('ę', 'e')
        .replace('ó', 'o')
        .replace('ś', 's')
        .replace('ł', 'l')
        .replace('ż', 'z')
        .replace('ź', 'z')
        .replace('ń', 'n')
        .replace('š', 's');
}
